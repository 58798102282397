import React, { useState, useRef, useEffect } from "react";
import { Upload, Modal } from "antd";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { add, states } from "./itemsarray";
import { useDispatch, useSelector } from "react-redux";
import { increment, reset } from "../../slice/stepscounter";
import MainButton from "../Buttons/MainButton";
import InputField from "../Form/InputField";
import validationSchema from "../../helpers/validationSchema";
import SelectField from "../Form/SelectField";
import { useGetDropdownDataQuery } from "../../api/general";
import { mapToOptions } from "../../helpers";
import { isEmpty } from "lodash";
import { updateNewListing, resetNewListing } from "../../slice/listingSlice";
import useMessageAlert from "../Message";
import { appendImageUrl } from "../../helpers";

// Picker
export default function AboutPost() {
  const { newListing } = useSelector((state) => state.listings);
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState(
    newListing.rawImages?.length > 0 ? newListing.rawImages : []
  );
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const { data: dropdownData } = useGetDropdownDataQuery();
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  // const [ownershipType, setOwnershipType] = useState([]);
  const [lgas, setLgas] = useState([]);
  const formikRef = useRef(null);
  const { openMessage, contextHolder } = useMessageAlert();
  const [removeImageList] = useState([]);

  const handleCancel = () => setPreviewOpen(false);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const next = (values) => {
    if (fileList.length === 0 && newListing.imagesLocation?.length === 0) {
      openMessage("error", "Please upload at least 1 (one) image to proceed");
    } else {
      dispatch(updateNewListing({ rawImages: fileList }));
      let imagesLocation = fileList
        .filter((im) => im.status === undefined || im.status !== "done")
        .map((im) => im.originFileObj);
      if (!isEmpty(newListing?.imagesLocation)) {
        dispatch(updateNewListing({ additionalImages: imagesLocation }));
      } else {
        dispatch(updateNewListing({ imagesLocation }));
      }
      if (removeImageList.length > 0) {
        dispatch(updateNewListing({ removedImages: removeImageList }));
      }
      dispatch(updateNewListing(values));
      dispatch(increment());
    }
  };

  const handleReset = () => {
    formikRef?.current?.resetForm();
    setFileList([]);
    dispatch(resetNewListing({}));
    dispatch(reset());
  };

  const dummyRequest = ({ file, onSuccess }) => {};

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    setPreviewOpen(true);
    setPreviewImage(file.url || src);
  };

  const handleCategorySelect = (category) => {
    let subCat;

    switch (category) {
      case "Real Estate":
        subCat = dropdownData?.find(
          (subCat) => subCat.dropdownKey === "realEstateSubCategories"
        );
        setSubCategories(mapToOptions(subCat?.values));
        break;
      case "Vehicles":
        subCat = dropdownData?.find(
          (subCat) => subCat.dropdownKey === "vehicleSubCategories"
        );
        setSubCategories(mapToOptions(subCat?.values));
        break;
      case "Books":
        subCat = dropdownData?.find(
          (subCat) => subCat.dropdownKey === "booksSubCategories"
        );
        setSubCategories(mapToOptions(subCat?.values));
        break;
      case "Outdoors":
        subCat = dropdownData?.find(
          (subCat) => subCat.dropdownKey === "outdoorSubCategories"
        );
        setSubCategories(mapToOptions(subCat?.values));
        break;
      case "Electronics":
        subCat = dropdownData?.find(
          (subCat) => subCat.dropdownKey === "electronicsSubCategories"
        );
        setSubCategories(mapToOptions(subCat?.values));
        break;
      case "Fashion":
        subCat = dropdownData?.find(
          (subCat) => subCat.dropdownKey === "fashionSubCategories"
        );
        setSubCategories(mapToOptions(subCat?.values));
        break;
      case "Tools":
        subCat = dropdownData?.find(
          (subCat) => subCat.dropdownKey === "toolsSubCategories"
        );
        setSubCategories(mapToOptions(subCat?.values));
        break;
      case "Kid Items":
        subCat = dropdownData?.find(
          (subCat) => subCat.dropdownKey === "kidsSubCategories"
        );
        setSubCategories(mapToOptions(subCat?.values));
        break;
      case "Furniture":
        subCat = dropdownData?.find(
          (subCat) => subCat.dropdownKey === "furnitureSubCategories"
        );
        setSubCategories(mapToOptions(subCat?.values));
        break;

      default:
        break;
    }
  };

  const generateExistingImages = (images) => {
    const ims = [];
    images.forEach((i) => {
      const temp = {
        uid: i,
        name: i,
        status: "done",
        url: appendImageUrl(i),
      };
      ims.push(temp);
    });
    return ims;
  };

  useEffect(() => {
    if (fileList.length === 0 && newListing.imagesLocation?.length > 0) {
      const images = generateExistingImages(newListing.imagesLocation);
      setFileList(images);
    }
  }, [newListing.imagesLocation]);

  const removeCurrentImages = (image) => {
    if (image.status === "done") {
      removeImageList.push(image.name);
    }
  };

  const handleStateSelect = (state) => {
    let lga = add.find((st) => st.state === state);
    setLgas(mapToOptions(lga?.lgas));
  };

  useEffect(() => {
    if (!isEmpty(dropdownData)) {
      let cat = dropdownData?.find((item) => item.dropdownKey === "categories");
      setCategories(mapToOptions(cat?.values));
      // let ownerType = dropdownData?.find(
      //   (item) => item.dropdownKey === "ownershipTypes"
      // );
      // setOwnershipType(mapToOptions(ownerType?.values));
    }
  }, [dropdownData]);

  return (
    <div className="font-poppins">
      {contextHolder}
      <h2 className="font-semibold text-2xl text-headingBlack mb-2">
        About Post
      </h2>
      <p className="text-sm text-headingBlack mb-0">
        Details about the post, location and images
      </p>
      <Formik
        initialValues={{
          listingHeading: newListing?.listingHeading || "",
          category: newListing?.category || "",
          subCategory: newListing?.subCategory || "",
          //ownershipType: newListing?.ownershipType || "",
          state: newListing?.state || "",
          localGovernmentArea: newListing?.localGovernmentArea || "",
          address1: newListing?.address1 || "",
        }}
        onSubmit={(values) => {
          next(values);
        }}
        validationSchema={yup.object().shape(validationSchema.aboutListing)}
        innerRef={formikRef}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          setFieldValue,
          touched,
        }) => (
          <Form>
            <div className="flex mt-6 flex-col w-full">
              <InputField
                name={"listingHeading"}
                inputType={"text"}
                label={"Item Title"}
                placeholder={"Enter Item Title"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.listingHeading}
                error={errors.listingHeading}
                touched={touched.listingHeading}
                maxLength={128}
              />
              <div className="grid sm:grid-cols-2 sm:gap-4 lg:gap-y-0">
                <SelectField
                  id={"category"}
                  hasLabel
                  label={"Category"}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  onChangeComplete={handleCategorySelect}
                  options={categories}
                  value={values.category}
                  error={errors.category}
                  touched={touched.category}
                />
                <SelectField
                  id={"subCategory"}
                  hasLabel
                  label={"Sub Category"}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  options={subCategories}
                  value={values.subCategory}
                  error={errors.subCategory}
                  touched={touched.subCategory}
                />
                {/*
                <InputField
                  name={"ownershipType"}
                  inputType={"text"}
                  label={"Ownership Type"}
                  placeholder={"Enter Ownership Type"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ownershipType}
                  error={errors.ownershipType}
                  touched={touched.ownershipType}
                />
                */}
                <SelectField
                  id={"state"}
                  hasLabel
                  label={"State"}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  onChangeComplete={handleStateSelect}
                  options={mapToOptions(states)}
                  value={values.state}
                  error={errors.state}
                  touched={touched.state}
                />

                <SelectField
                  id={"localGovernmentArea"}
                  hasLabel
                  label={"LGA"}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  options={lgas}
                  value={values.localGovernmentArea}
                  error={errors.localGovernmentArea}
                  touched={touched.localGovernmentArea}
                />
                <InputField
                  name={"address1"}
                  inputType={"text"}
                  label={"Address"}
                  placeholder={"Enter Address"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address1}
                  error={errors.address1}
                  touched={touched.address1}
                  maxLength={128}
                />
              </div>

              <p className="text-primaryBlack font-poppins text-sm font-medium mt-5 mb-3">
                Upload Images
              </p>
              <Upload
                listType="picture-card"
                action={dummyRequest}
                fileList={fileList}
                onChange={(e) => onChange(e)}
                onRemove={(e) => removeCurrentImages(e)}
                onPreview={onPreview}
                accept="image/jpg,image/png,image/gif"
                maxCount={10}
                multiple={true}
                beforeUpload={() => false}
              >
                {fileList.length < 10 && "+"}
              </Upload>
              <p className="font-poppins upload-note">Maximum of 10 images</p>
            </div>
            <div className="flex flex-col-reverse sm:flex-row justify-between pb-1 pt-6 mt-6 -mx-5 md:-mx-8 lg:-mx-10 px-5 md:px-8 lg:px-10 border-t border-black/[0.1]">
              <MainButton
                title={"Cancel"}
                variant={"outline-gray"}
                appendStyles={
                  "font-poppins mt-3 sm:mt-0 hover:bg-primaryOrange/50"
                }
                onClick={handleReset}
              />
              <MainButton
                title={"Proceed to Details"}
                appendStyles={"font-poppins"}
                onClick={() => formikRef?.current?.handleSubmit()}
              />
            </div>
          </Form>
        )}
      </Formik>

      <Modal
        open={previewOpen}
        title="Preview"
        footer={null}
        onCancel={handleCancel}
        className={"preview-modal"}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
}
