import { digitRegExp, phoneRegExp } from "./index";
import * as yup from "yup";

export const REQUIRED_FIELD = "This field is required";
export const VALID_EMAIL_FIELD = "Provide a valid email address";

const validationSchema = {
  signup: {
    firstName: yup.string().required(REQUIRED_FIELD),
    lastName: yup.string().required(REQUIRED_FIELD),
    dateOfBirth: yup
      .date()
      .required(REQUIRED_FIELD)
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "Must be older than 18 years old"
      ),
    emailAddress: yup
      .string()
      .email(VALID_EMAIL_FIELD)
      .required(REQUIRED_FIELD),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Provide a valid phone number")
      .max(14, "Phone number must be less than 15 characters")
      .required(REQUIRED_FIELD),
    userPassword: yup.string().required(REQUIRED_FIELD),
  },
  login: {
    email: yup.string().email(VALID_EMAIL_FIELD).required(REQUIRED_FIELD),
    password: yup.string().required(REQUIRED_FIELD),
  },
  bookListing: {
    startDate: yup.date().required(REQUIRED_FIELD).nullable(),
    endDate: yup.date().required(REQUIRED_FIELD).nullable(),
    paymentMethod: yup.mixed().required(REQUIRED_FIELD),
  },
  promoteListing: {
    promoType: yup.mixed().required(REQUIRED_FIELD),
    paymentMethod: yup.mixed().required(REQUIRED_FIELD),
  },
  aboutListing: {
    listingHeading: yup.string().required(REQUIRED_FIELD),
    category: yup.string().required(REQUIRED_FIELD),
    subCategory: yup.string().required(REQUIRED_FIELD),
    //ownershipType: yup.string().required(REQUIRED_FIELD),
    state: yup.string().required(REQUIRED_FIELD),
    localGovernmentArea: yup.string().required(REQUIRED_FIELD),
    address1: yup.string().required(REQUIRED_FIELD),
  },
  itemDetails: {
    propertyType: yup.string(),
    condition: yup.string().required(REQUIRED_FIELD),
    features: yup.mixed(),
    furnished: yup.string(),
    numBedrooms: yup.string(),
    numBathrooms: yup.string(),
    listingDescription: yup
      .string()
      .required(REQUIRED_FIELD)
      .max(2000, "This field contains more than 2000 characters"),
    author: yup.string(),
    brand: yup.string(),
    model: yup.string(),
  },
  pricing: {
    rentAmount: yup
      .string()
      .matches(digitRegExp, "Price must be a number")
      .required(REQUIRED_FIELD),
    rentalPeriod: yup.string().required(REQUIRED_FIELD),
    priceType: yup.string().required(REQUIRED_FIELD),
    refundable: yup.string().required(REQUIRED_FIELD),
    cautionFee: yup
      .string()
      .matches(digitRegExp, "Fee must be a number")
      .nullable(),
    termsCondition: yup
      .string()
      .max(1500, "This field contains more than 1500 characters"),
  },
  contactUs: {
    fullName: yup.string().required(REQUIRED_FIELD),
    emailAddress: yup.string().required(REQUIRED_FIELD),
    phoneNumber: yup.string().required(REQUIRED_FIELD),
    description: yup
      .string()
      .max(1000, "This field contains more than 1000 characters"),
  },
  updateProfile: {
    firstName: yup.string().required(REQUIRED_FIELD),
    lastName: yup.string().required(REQUIRED_FIELD),
    dateOfBirth: yup
      .date()
      .required(REQUIRED_FIELD)
      .nullable()
      .min(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "User must be older than 18 years old"
      ),
    emailAddress: yup
      .string()
      .email(VALID_EMAIL_FIELD)
      .required(REQUIRED_FIELD),
    phoneNumber: yup
      .string()
      .matches(digitRegExp, "Provide a valid phone number")
      .max(15, "Phone number must be less than 16 characters")
      .required(REQUIRED_FIELD),
    preferredName: yup.string().required(REQUIRED_FIELD),
  },
  changePassword: {
    password: yup.string().required(REQUIRED_FIELD),
    newPassword: yup.string().required(REQUIRED_FIELD),
  },
  resetPassword: {
    password: yup.string().required(REQUIRED_FIELD),
    newPassword: yup
      .string()
      .required(REQUIRED_FIELD)
      .oneOf([yup.ref("password")], "Passwords must match"),
  },
};

export default validationSchema;
