import React, { useState } from "react";
import { SearchIconAlt } from "../../CustomIcons";
import { isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import LazyLoadImg from "../../LazyLoadImg";
import imageUrls from "../../LazyLoadImg/imageUrls";

function HeroBanner() {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const handleChange = (e) => {
    e.preventDefault();
    setQuery(e.target.value);
  };

  return (
    <div className="bg-[#FFF6EE] py-14 lg:py-20 xl:py-24 -mx-4 sm:-mx-6 md:-mx-8 xl:-mx-32 2xl:-mx-36 2xl:mx-auto px-4 sm:px-6 md:px-8 xl:px-32 2xl:px-36">
      <div className="grid md:grid-cols-2 gap-10 md:gap-4 lg:gap-5 xl:gap-8 desktop:gap-12 max-w-7xl desktop:mx-auto">
        <div className="flex flex-col justify-center">
          <h1 className="text-[#1F1A16] leading-normal font-sans font-bold text-[2.5rem] xl:text-[3rem] mb-4 lg:mb-6">
            Rentals at your fingertips!
          </h1>
          <p className="text-[#1F1A16] font-sans text-lg lg:text-xl xl:text-2xl mb-0">
            Find items you can rent at low prices!
          </p>
          <form
            className="flex items-center search-bar w-full mt-6 lg:mt-8"
            onSubmit={(e) => {
              e.preventDefault();
              if (!isEmpty(query)) {
                navigate(`/listings?category=none&keyword=${query}`);
              }
            }}
          >
            <label htmlFor="banner-search-listing" className="sr-only">
              Search
            </label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none">
                <SearchIconAlt className="w-6 h-6 text-primaryOrange" />
              </div>
              <input
                type="text"
                name="banner-search-listing"
                className="border border-[#D4CBCB]/50 placeholder:text-primaryBlack text-base font-poppins rounded-full focus:outline focus:outline-2 focus:outline-primaryOrange/50 block w-full pl-14 py-6 h-[5rem]"
                placeholder="Searching for"
                required
                style={{ boxShadow: "0px 14px 28px rgba(255, 125, 18, 0.1)" }}
                onChange={handleChange}
              />
              <Link
                to={`/listings?category=none&keyword=${query}`}
                type="submit"
                className="absolute right-3 bottom-[10px] text-white focus:ring-2 focus:outline-none font-semibold font-poppins rounded-full bg-primaryOrange text-sm px-6 py-5"
              >
                Search
              </Link>
            </div>
          </form>
        </div>
        <div className="flex">
          <LazyLoadImg
            src={imageUrls.homeHero}
            alt="vehicle, property, electronics, etc rentals at your fingertips"
            className="w-[18.5rem] h-[17.5rem] md:w-[21.5rem] md:h-[21.5rem] lg:w-[28rem] lg:h-[24rem] xl:w-[30rem] xl:h-[27.5rem] desktop:w-[33.5rem] desktop:h-[30rem] mx-auto"
          />
        </div>
      </div>
    </div>
  );
}

export default HeroBanner;
