import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { InfoCircleOutlined } from "@ant-design/icons";
import { CalendarIcon } from "../CustomIcons";

function CustomDatePicker({
  error,
  form: { setFieldValue, values },
  label,
  hasLabel,
  id,
  touched,
  format,
  isDisabled,
}) {
  // const disabledDate = (current) => {
  //   return current && current > dayjs().subtract(13, "year");
  // };
  return (
    <div className="my-3 input-field relative">
      {hasLabel && <label htmlFor={id}>{label}</label>}
      <DatePicker
        onChange={(date, dateString) => setFieldValue(id, date)}
        value={
          values?.[`${id}`] === null || values?.[`${id}`] === ""
            ? null
            : dayjs(values?.[`${id}`], "D/M/YYYY")
        }
        className={"w-full"}
        id={id}
        placeholder={" "}
        disabled={isDisabled}
        format={format}
        // disabledDate={disabledDate}
        suffixIcon={<CalendarIcon />}
      />
      {error && touched && (
        <div className={"flex items-center mt-1"}>
          <InfoCircleOutlined className="text-[10px] text-red-500" />
          <p className="text-left text-[10px] text-red-500 ml-1.5 mb-0">
            {error}
          </p>
        </div>
      )}
    </div>
  );
}

export default CustomDatePicker;
