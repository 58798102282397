import { Button } from "antd";
import React from "react";

function MainButton({
  title,
  buttonType = "primary",
  disabled,
  href,
  size,
  variant = "primary",
  appendStyles,
  onClick,
  isLoading,
  ...props
}) {
  let sizeStyles = "";
  let variantStyles = "";

  switch (size) {
    case "sm":
      sizeStyles = "rounded text-xs md:text-sm px-6 py-2.5 h-9";
      break;
    case "md":
      sizeStyles = "rounded text-sm px-14 py-3 h-12";
      break;
    case "lg":
      sizeStyles = "rounded-lg text-base px-20 py-3 h-12";
      break;
    case "no-padding":
      sizeStyles = "p-0 rounded-0";
      break;
    case "icon-md":
      sizeStyles = "rounded-lg px-6 py-2.5 h-12";
      break;
    case "xm":
      sizeStyles = "rounded text-sm px-3 py-2.5 h-9";
      break;
    default:
      sizeStyles = "rounded text-sm px-6 py-2.5 h-9";
      break;
  }

  switch (variant) {
    case "primary":
      variantStyles =
        "bg-primaryOrange rounded text-white hover:bg-primaryOrange/50 focus:bg-primaryOrange/50";
      break;
    case "primary-rounded":
      variantStyles =
        "bg-primaryOrange rounded-full text-white hover:bg-primaryOrange/50 focus:bg-primaryOrange/50";
      break;
    case "secondary":
      variantStyles =
        "bg-transparent shadow-none text-primaryBlack hover:bg-primaryOrange/50 focus:outline focus:outline-1 focus:outline-primaryOrange/50";
      break;
    case "black-rounded":
      variantStyles =
        "bg-[#1F1A16] rounded-full shadow-none text-white hover:bg-[#1F1A16]/50 focus:outline focus:outline-1 focus:outline-[#1F1A16]/50";
      break;
    case "secondary-white":
      variantStyles =
        "bg-transparent shadow-none text-white hover:text-primaryOrange focus:outline focus:outline-1 focus:outline-primaryOrange/50";
      break;
    case "outline":
      variantStyles = "bg-white border border-primaryOrange text-primaryOrange";
      break;
    case "outline-gray":
      variantStyles = "bg-white border border-[#949198] text-[#949198]";
      break;
    case "white-bg":
      variantStyles =
        "bg-white text-primaryBlack hover:bg-primaryOrange/25 focus:bg-primaryOrange/50";
      break;
    default:
      variantStyles =
        "bg-primaryOrange text-white hover:bg-primaryOrange/50 focus:bg-primaryOrange/50";
      break;
  }

  return (
    <Button
      type={buttonType}
      className={[
        "font-semibold inline-flex items-center justify-center",
        sizeStyles,
        variantStyles,
        appendStyles,
      ]}
      disabled={disabled}
      href={href}
      onClick={onClick}
      loading={isLoading}
      {...props}
    >
      {title}
    </Button>
  );
}

export default MainButton;
