import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LazyLoadImg = ({ src, alt, srcset, className }) => (
  <LazyLoadImage
    src={src}
    srcSet={srcset}
    alt={alt}
    className={className}
    width={"100%"}
    height={"100%"}
  />
);

export default LazyLoadImg;
