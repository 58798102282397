import { useDispatch } from "react-redux";
import { apiInstance } from "../api";
import { logoutChat } from "../slice/chatSlice";
import { logoutListing } from "../slice/listingSlice";
import { reset } from "../slice/stepscounter";
import { logoutUser } from "../slice/userSlice";

function useLogoutUser() {
  const dispatch = useDispatch();
  const handleLogoutUser = () => {
    dispatch(logoutUser());
    dispatch(logoutChat());
    dispatch(logoutListing());
    dispatch(reset());
    dispatch(apiInstance.util.resetApiState());
  };
  return { handleLogoutUser };
}

export default useLogoutUser;
