import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import LazyLoadImg from "../../LazyLoadImg";
import imageUrls from "../../LazyLoadImg/imageUrls";

function PopularSection() {
  const popular = [
    {
      img: imageUrls.specialDeals,
      title: "Special Deals",
      subTitle: "Something for everyone",
    },
    {
      img: imageUrls.keepBrowsing,
      title: "Keep Browsing",
      subTitle: "Explore our varieties",
    },
    {
      img: imageUrls.recommended,
      title: "Recommended",
      subTitle: "Made for you",
    },
    {
      img: imageUrls.trending,
      title: "Trending",
      subTitle: "Popular Stuff",
    },
    {
      img: imageUrls.favorites,
      title: "Favorites",
      subTitle: "Based on your likes",
    },
  ];

  const settings = {
    dots: false,
    slidesToShow: 4.4,
    scroll: 1,
    infinite: false,
    arrows: true,
    rows: 1,
    className: "nearyou popular",
    responsive: [
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 4.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 765,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const PopularCard = ({ img, subTitle, title }) => (
    <>
      <Link to={`/listings?category=${title}`}>
        <div className="rounded-xl md:rounded-2xl xl:rounded-3xl relative max-h-[11.155rem] md:max-h-[13.21rem] lg:max-h-[14.5rem] xl:max-h-[16.25rem] desktop:max-h-[17.75rem] 2xl:max-h-[19.375rem] max-w-[9.5rem] md:max-w-[11.25rem] lg:max-w-[12.25rem] xl:max-w-[13.75rem] desktop:max-w-[15.125rem] 2xl:max-w-[16.5rem] mx-1 hover:scale-105 transition-all">
          <LazyLoadImg
            src={img}
            alt={title}
            className="rounded-xl md:rounded-2xl xl:rounded-3xl w-full object-cover"
          />
          <div
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.9) -1.96%, rgba(196, 196, 196, 0) 74.25%)",
            }}
            className="py-[18px] px-[10px] md:py-[20px] md:px-[12px] lg:py-[24px] lg:px-[14px] xl:py-[28px] xl:px-[16px] 2xl:py-[32px] 2xl:px-[18px] absolute rounded-xl md:rounded-2xl xl:rounded-3xl h-full top-0 left-0 w-full"
          >
            <p className="text-white font-poppins text-[8px] md:text-[9px] lg:text-[10px] xl:text-[12px] 2xl:text-[14px] mb-2 xl:mb-3">
              {subTitle}
            </p>
            <p className="text-white font-poppins font-semibold text-[14.5px] md:text-[16px] lg:text-[18px] xl:text-[22px] 2xl:text-[24px]">
              {title}
            </p>
          </div>
        </div>
      </Link>
    </>
  );

  return (
    <div className="pb-0 pt-16">
      <div>
        <h1 className="font-poppins text-[1.375rem] md:text-[1.5rem] lg:text-[1.75rem] xl:text-[2rem] font-bold">
          Popular Categories
        </h1>
        <p className="text-base font-sans text-placeholder mb-0">
          New and affordable rental services are added every day. Browse through
          special deals and recommendations based on feedback from renters
        </p>
      </div>
      <div className="mt-5">
        {popular.length && (
          <Slider {...settings}>
            {popular.map((item, index) => (
              <PopularCard
                key={index}
                title={item.title}
                subTitle={item.subTitle}
                img={item.img}
              />
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
}

export default PopularSection;
