import React, { useCallback, useEffect, useState } from "react";
import "./layout.css";
import "antd/dist/reset.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Select, Avatar, Dropdown, Drawer, Menu } from "antd";
import SearchBar from "./Searchbar";
import {
  ChatIcon,
  NotificationIcon,
  PinDropIcon,
  NotificationUnreadIcon,
  PostListingIcon
} from "../CustomIcons";
import { states } from "../PostListing/itemsarray";
import { UserOutlined, LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import MainButton from "../Buttons/MainButton";
import BottomNav from "./BottomNav";
import LoginModal from "../Modals/LoginModal";
import SignupModal from "../Modals/SignupModal";
import ForgotPasswordModal from "../Modals/ForgotPasswordModal";
import ResetPasswordModal from "../Modals/ResetPasswordModal";
import VerifyOTPModal from "../Modals/VerifyOTPModal";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowLoginModal,
  setShowSignupModal,
  setLocation,
  setShowOTPModal,
  setShowForgotPasswordModal,
  setShowResetPasswordModal,
} from "../../slice/userSlice";
import useMessageAlert from "../Message";
import { appendImageUrl } from "../../helpers";
import useLogoutUser from "../../helpers/useLogoutUser";
import {
  useLazyGetNumNotificationsQuery,
  useLazyDefaultTokenQuery,
} from "../../api/general";
import { isEmpty } from "lodash";
import { BsChevronDown } from "react-icons/bs";
import LazyLoadImg from "../LazyLoadImg";
import imageUrls from "../LazyLoadImg/imageUrls";
import { googleLogout } from '@react-oauth/google';
import ReactGA from 'react-ga';

function getItem(label, key, className, items, type) {
  return {
    key,
    className,
    items,
    label,
    type,
  };
}

const AuthUser = ({
  profileDropdownItems,
  profile,
  dispatch,
  location,
  unread,
}) => (
  <div className="flex items-center justify-end lg:justify-between w-full">
    <div className="flex items-center">
      <PinDropIcon className="w-4 h-5" />
      <Select
        onChange={(val) => {
          dispatch(setLocation(val));
        }}
        suffixIcon={<BsChevronDown className="w-4 h-4" />}
        className="location-select"
        aria-label="location select"
        bordered={false}
        value={location}
        options={states?.map((data) => ({
          value: data + ", Nigeria",
          label: data + ", Nigeria",
        }))}
      />
    </div>
    <div className="hidden lg:flex items-center">
      <Link
        to={"/post"}
        aria-label="posting"
        className="pr-1.5 inline-flex rounded-full focus:outline focus:outline-2 focus:outline-offset-4 focus:outline-primaryOrange mx-12"
      >
        <PostListingIcon className="w-6 h-6" />
      </Link>
      <Link
        to={"/messages"}
        aria-label="messaging"
        className="p-1.5 inline-flex rounded-full focus:outline focus:outline-2 focus:outline-primaryOrange"
      >
        <ChatIcon className="w-6 h-6" />
      </Link>
      <Link
        to={"/admin/notification"}
        aria-label="notification"
        className="p-1.5 inline-flex rounded-full focus:outline focus:outline-2 focus:outline-primaryOrange mx-12"
      >
        {unread ? (
          <NotificationUnreadIcon className="w-5 h-6" />
        ) : (
          <NotificationIcon className="w-5 h-6" />
        )}
      </Link>
      <Dropdown
        menu={{ items: profileDropdownItems }}
        trigger={["hover", "click"]}
        dropdownRender={(menu) => {
          return (
            <div className="bg-white rounded-lg hover:bg-white mt-4">
              {React.cloneElement(menu, {
                className: "profile-dropdown",
              })}
            </div>
          );
        }}
      >
        <div className="flex items-center" tabIndex="0">
          <Avatar
            src={
              !isEmpty(profile?.imageLink)
                ? appendImageUrl(profile?.imageLink, "user")
                : imageUrls.defaultDp
            }
            alt="user avatar"
            className="bg-transparent flex items-center h-12 w-12 justify-center"
          />
        </div>
      </Dropdown>
    </div>
  </div>
);

const AuthUserMobile = ({ profile, handleLogout }) => {
  const authUserItems = [
    getItem(
      <Link
        to={"/post"}
        className="flex items-center text-base font-semibold focus:outline focus:outline-2 focus:outline-primaryOrange"
      >
        <PostListingIcon className="w-5 h-6 mr-5" />
        Post
      </Link>,
      "notifications",
      "w-full my-4 px-0 mx-0"
    ),
    getItem(
      <Link
        to={"/admin/notification"}
        className="flex items-center text-base font-semibold focus:outline focus:outline-2 focus:outline-primaryOrange"
      >
        <NotificationIcon className="w-5 h-6 mr-5" />
        Notifications
      </Link>,
      "notifications",
      "w-full my-4 px-0 mx-0"
    ),
    getItem(
      <Link
        to={"/messages"}
        className="flex items-center text-base font-semibold focus:outline focus:outline-2 focus:outline-primaryOrange"
      >
        <ChatIcon className="w-6 h-6 mr-5" />
        Messages
      </Link>,
      "messages",
      "w-full my-4 px-0 mx-0"
    ),
    getItem(
      <Link
        to="/"
        onClick={(e) => handleLogout(e)}
        className="inline-flex items-center text-base font-semibold focus:outline focus:outline-2 focus:outline-primaryOrange"
      >
        <LogoutOutlined className="text-2xl mr-5" />
        <span className="ml-0 mt-1">Logout</span>
      </Link>,
      "logout",
      "w-full my-4 px-0 mx-0"
    ),
  ];

  return (
    <div>
      <div className="text-center my-12">
        <Link to={"/admin/profile"}>
          <Avatar
            icon={<UserOutlined className="text-primaryBlack text-[56px]" />}
            src={appendImageUrl(profile?.imageLink, "user")}
            className="bg-transparent flex mx-auto justify-center w-14 h-14"
          />
          <p className="font-poppins text-lg font-semibold text-primaryBlack mt-4">
            {profile?.preferredName || profile?.firstName}
          </p>
        </Link>
      </div>
      <Menu
        mode="vertical"
        inlineIndent={0}
        className={"before:hidden after:hidden"}
        items={authUserItems}
      />
      <MainButton
        title={"Post"}
        buttonType={"link"}
        href={"/post"}
        size={"lg"}
        variant={"primary-rounded"}
        appendStyles={"mt-6"}
        disabled={!profile?.idVerified}
      />
    </div>
  );
};

const NoAuthUser = ({ openLogin, openSignup }) => (
  <div className="hidden lg:flex items-center ml-auto">
    <MainButton
      title={"Sign in"}
      size={"sm"}
      variant={"secondary"}
      appendStyles={"mr-4 font-medium rounded-full text-placeholder"}
      onClick={openLogin}
    />
    <MainButton
      title={"Join Now"}
      size={"sm"}
      appendStyles={"font-medium"}
      variant={"primary-rounded"}
      onClick={openSignup}
    />
  </div>
);

const NoAuthUserMobile = ({ closeDrawer, openLogin, openSignup }) => {
  const items = [
    getItem(
      <Link
        to={"/"}
        className="flex items-center text-base font-semibold focus:outline focus:outline-2 focus:outline-primaryOrange"
      >
        Home
      </Link>,
      "home",
      "w-full my-4 px-0 mx-0"
    ),
    getItem(
      <Link
        to={"/about-us"}
        className="flex items-center text-base font-semibold focus:outline focus:outline-2 focus:outline-primaryOrange"
      >
        About Us
      </Link>,
      "about us",
      "w-full my-4 px-0 mx-0"
    ),
    getItem(
      <Link
        to={"/about-us#how-it-works"}
        className="flex items-center text-base font-semibold focus:outline focus:outline-2 focus:outline-primaryOrange"
      >
        How it Works
      </Link>,
      "how it works",
      "w-full my-4 px-0 mx-0"
    ),
    getItem(
      <Link
        to={"/about-us#faq"}
        className="flex items-center text-base font-semibold focus:outline focus:outline-2 focus:outline-primaryOrange"
      >
        FAQ
      </Link>,
      "faq",
      "w-full my-4 px-0 mx-0"
    ),
  ];

  return (
    <>
      <Menu
        mode="vertical"
        inlineIndent={0}
        className={"before:hidden after:hidden"}
        items={items}
      />
      <div className="flex items-center justify-center mt-10">
        <MainButton
          title={"Sign in"}
          size={"sm"}
          variant={"secondary"}
          appendStyles={"mr-4 font-medium rounded-full text-placeholder"}
          onClick={() => {
            closeDrawer();
            openLogin();
          }}
        />

        <MainButton
          title={"Join Now"}
          size={"sm"}
          variant={"primary-rounded"}
          onClick={() => {
            closeDrawer();
            openSignup();
          }}
        />
      </div>
    </>
  );
};

export default function MainHeader() {
  const dispatch = useDispatch();
  const {
    isLoggedIn,
    showLoginModal,
    showSignupModal,
    showOTPModal,
    showForgotPasswordModal,
    showResetPasswordModal,
    location,
    profile,
  } = useSelector((state) => state.user);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { openMessage, contextHolder } = useMessageAlert();
  const { handleLogoutUser } = useLogoutUser();
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();
  const [retrieveToken] = useLazyDefaultTokenQuery();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [unreadNotification, setUnreadNotification] = useState(false);
  const [getNotification, {isLoading}] = useLazyGetNumNotificationsQuery();

  const openLogin = () => {
    dispatch(setShowLoginModal(true));
    dispatch(setShowSignupModal(false));
    dispatch(setShowForgotPasswordModal(false));
    dispatch(setShowResetPasswordModal(false));
  };

  const openSignup = () => {
    dispatch(setShowSignupModal(true));
    dispatch(setShowLoginModal(false));
    dispatch(setShowForgotPasswordModal(false));
    dispatch(setShowOTPModal(false));
  };

  const openOTP = () => {
    dispatch(setShowOTPModal(true));
    dispatch(setShowSignupModal(false));
    dispatch(setShowForgotPasswordModal(false));
  };

  const openForgotPassword = () => {
    dispatch(setShowForgotPasswordModal(true));
    dispatch(setShowOTPModal(false));
    dispatch(setShowSignupModal(false));
    dispatch(setShowLoginModal(false));
  };

  const openResetPassword = () => {
    dispatch(setShowResetPasswordModal(true));
    dispatch(setShowForgotPasswordModal(false));
    dispatch(setShowOTPModal(false));
  };

  const handleLogout = (e) => {
    e?.preventDefault();
    handleLogoutUser();
    googleLogout();
    openMessage("success", "You have successfully logged out!");
    navigate("/");
  };

  //This is used to add user activity for stats collection.
  const getToken = useCallback(async () => {
    const isToken = sessionStorage.getItem("token");
    if (isToken !== "true") {
      try {
        const res = await retrieveToken().unwrap();
        setToken(res.accessToken);
        sessionStorage.setItem("token", "true");
      } catch (error) {
        console.error("Failed to retrieve token");
      }
    }
  }, [retrieveToken]);


  const retrieveNotifications = async () => {
    return await getNotification(profile?.userId);
  }

  //This will be used to check for notifications
  useEffect(() => {
    if (isLoggedIn && profile?.userId !== undefined) {
      const numNote = retrieveNotifications();
      setUnreadNotification(numNote?.count > 0);
    }
  }, [isLoggedIn]);

  const profileDropdownItems = [
    {
      key: "1",
      label: <Link to="/admin/profile">Profile</Link>,
      icon: <UserOutlined />,
    },
    {
      key: "2",
      label: (
        <Link to="/" onClick={(e) => handleLogout(e)}>
          Logout
        </Link>
      ),
      icon: <LogoutOutlined />,
      disabled: false,
    },
  ];

  const openDrawer = () => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    getToken();
  }, [token, getToken]);

  return (
    <>
      <header className="w-full sticky top-0 bg-white z-50">
        {contextHolder}
        <div className="max-w-[120rem] px-4 sm:px-6 md:px-8 xl:px-32 2xl:px-36 2xl:mx-auto py-7 h-max grid grid-cols-3 lg:grid-cols-12 gap-1 lg:gap-4 xl:gap-6">
          <div
            className={`topLeft-header col-span-1 ${
              isLoggedIn
                ? "lg:col-span-6 2xl:col-span-5"
                : "lg:col-span-7 2xl:col-span-6"
            } flex`}
          >
            <Link
              to="/"
              className="inline-block md:mr-6 lg:mr-8"
            >
              <LazyLoadImg
                src={imageUrls.logo}
                className="h-11 w-[6.5rem]"
                alt={"yarlo logo"}
              />
            </Link>
            {isLoggedIn ? (
              <div className="hidden lg:flex w-full">
                <SearchBar />
              </div>
            ) : (
              <ul className="hidden lg:inline-flex items-center mb-0 ml-auto">
                <li
                  className={`font-sans ${
                    pathname === "/"
                      ? "text-primaryOrange font-bold rounded after:inline-flex after:absolute after:bottom-0 after:left-4 after:h-0.5 after:w-1/3 after:bg-primaryOrange"
                      : "text-placeholder font-medium"
                  } text-sm py-2.5 px-4 relative`}
                >
                  <a
                    href="/"
                    className="hover:text-primaryOrange focus:outline focus:outline-1 focus:outline-primaryOrange/50 cursor-pointer"
                  >
                    Home
                  </a>
                </li>
                <li
                  className={`font-sans ${
                    pathname === "/about-us" && hash === ""
                      ? "text-primaryOrange font-bold rounded after:inline-flex after:absolute after:bottom-0 after:left-4 after:h-0.5 after:w-1/3 after:bg-primaryOrange"
                      : "text-placeholder font-medium"
                  } text-sm py-2.5 px-4 relative`}
                >
                  <a
                    href="/about-us"
                    className="hover:text-primaryOrange focus:outline focus:outline-1 focus:outline-primaryOrange/50 cursor-pointer"
                  >
                    About Us
                  </a>
                </li>
                <li
                  className={`font-sans ${
                    pathname === "/about-us" && hash === "#how-it-works"
                      ? "text-primaryOrange font-bold rounded after:inline-flex after:absolute after:bottom-0 after:left-4 after:h-0.5 after:w-1/3 after:bg-primaryOrange"
                      : "text-placeholder font-medium"
                  } text-sm py-2.5 px-4 relative`}
                >
                  <a
                    href="/about-us#how-it-works"
                    className="hover:text-primaryOrange focus:outline focus:outline-1 focus:outline-primaryOrange/50 cursor-pointer"
                  >
                    How it Works
                  </a>
                </li>
                <li
                  className={`font-sans ${
                    pathname === "/about-us" && hash === "#faq"
                      ? "text-primaryOrange font-bold rounded after:inline-flex after:absolute after:bottom-0 after:left-4 after:h-0.5 after:w-1/3 after:bg-primaryOrange"
                      : "text-placeholder font-medium"
                  } text-sm py-2.5 px-4 relative`}
                >
                  <a
                    href="/about-us#faq"
                    className="hover:text-primaryOrange focus:outline focus:outline-1 focus:outline-primaryOrange/50 cursor-pointer"
                  >
                    FAQ
                  </a>
                </li>
              </ul>
            )}
          </div>
          <div
            className={`topRight-header col-span-2 ${
              isLoggedIn
                ? "lg:col-span-6 2xl:col-span-7"
                : "lg:col-span-5 2xl:col-span-6"
            } flex items-center`}
          >
            <div className="flex items-center justify-end lg:justify-between w-full">
              {isLoggedIn ? (
                <AuthUser
                  profile={profile}
                  profileDropdownItems={profileDropdownItems}
                  dispatch={dispatch}
                  location={location}
                  unread={unreadNotification}
                />
              ) : (
                <NoAuthUser openLogin={openLogin} openSignup={openSignup} />
              )}
              {/* Mobile menu trigger button */}
              <MainButton
                variant={"secondary"}
                size={"no-padding"}
                onClick={openDrawer}
                appendStyles={"lg:hidden ml-8"}
                title={<MenuOutlined className="text-primaryBlack text-base" />}
              />
            </div>
          </div>
        </div>
        {isLoggedIn && (
          <div className="lg:hidden my-4 px-4 sm:mx-auto sm:px-6 md:px-8 flex w-full sm:w-3/4">
            <SearchBar />
          </div>
        )}
        {/* Mobile Menu */}
        <Drawer
          title={
            <Link to="/" className="flex w-full justify-end">
              <LazyLoadImg
                src={imageUrls.logo}
                className="h-8 w-[5rem]"
                alt={"yarlo logo"}
              />
            </Link>
          }
          placement="right"
          onClose={closeDrawer}
          open={drawerOpen}
          headerStyle={{
            borderBottomWidth: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          {isLoggedIn ? (
            <AuthUserMobile handleLogout={handleLogout} profile={profile} />
          ) : (
            <NoAuthUserMobile
              closeDrawer={closeDrawer}
              openLogin={openLogin}
              openSignup={openSignup}
            />
          )}
        </Drawer>
        {/* Modals */}
        <LoginModal
          visible={showLoginModal}
          onCancel={() => dispatch(setShowLoginModal(false))}
          showSignupModal={openSignup}
          showForgotPasswordModal={openForgotPassword}
        />
        <ForgotPasswordModal
          visible={showForgotPasswordModal}
          onCancel={() => dispatch(setShowForgotPasswordModal(false))}
          showLoginModal={openLogin}
          showOTPModal={openOTP}
        />
        <ResetPasswordModal
          visible={showResetPasswordModal}
          onCancel={() => dispatch(setShowResetPasswordModal(false))}
          showLoginModal={openLogin}
        />
        <SignupModal
          visible={showSignupModal}
          onCancel={() => dispatch(setShowSignupModal(false))}
          showLoginModal={openLogin}
          showOTPModal={openOTP}
        />
        <VerifyOTPModal
          visible={showOTPModal}
          onCancel={() => dispatch(setShowOTPModal(false))}
          showResetPasswordModal={openResetPassword}
        />
      </header>
      {isLoggedIn && <BottomNav />}
    </>
  );
}
