import React, { useState } from "react";
import FloatingLabelInput from "react-floating-label-input";
import {
  InfoCircleOutlined,
  EyeFilled,
  EyeInvisibleFilled,
} from "@ant-design/icons";

function InputField({
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  label,
  placeholder,
  error,
  inputType,
  desc,
  touched,
  isDisabled,
  maxLength,
  minValue,
  maxValue,
  // rightIcon,
  // leftIcon,
  // required,
}) {
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <div className="my-3 relative">
      <FloatingLabelInput
        id={name}
        label={label}
        onBlur={onBlur}
        onChange={isDisabled ? () => null : onChange}
        onFocus={onFocus}
        value={value}
        placeholder={placeholder}
        type={show ? "text" : inputType}
        className={"floating-input"}
        maxDecimalPlaces={2}
        maxLength={maxLength ? maxLength: 64}
        min={minValue}
        max={maxValue}
      />
      {inputType === "password" ? (
        <div
          onClick={toggleShow}
          className="flex absolute top-3.5 right-4 items-center cursor-pointer"
        >
          {show ? (
            <EyeInvisibleFilled className="text-xl inline-flex text-gray-400" />
          ) : (
            <EyeFilled className="text-xl inline-flex text-gray-400" />
          )}
        </div>
      ) : null}
      {error && touched && (
        <div className={"flex items-center mt-1"}>
          <InfoCircleOutlined className="text-[10px] text-red-500" />
          <p className="text-left italic text-[10px] text-red-500 ml-1.5 mb-0">
            {error}
          </p>
        </div>
      )}
      {desc && (
        <p className="text-left text-[10px] text-gray-400 mt-1">{desc}</p>
      )}
    </div>
  );
}

export default InputField;
