import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowLoginModal,
  setShowSignupModal,
} from "../../../slice/userSlice";
import LazyLoadImg from "../../LazyLoadImg";
import imageUrls from "../../LazyLoadImg/imageUrls";

const StartListingCard = ({ img, subTitle, title, handleOpenPost }) => (
  <>
    <div className="rounded-xl md:rounded-2xl xl:rounded-3xl relative h-[20rem] md:h-[21.5rem] lg:h-[25.25rem] xl:h-[26rem] desktop:h-[26.25rem] 2xl:h-[28.5rem] w-full transition-all">
      <LazyLoadImg
        src={img}
        alt={title}
        className="rounded-xl md:rounded-2xl xl:rounded-3xl w-full h-full object-cover"
      />
      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 110%)",
        }}
        className="flex flex-col-reverse py-6 px-5 md:py-7 md:px-6 lg:py-8 lg:px-8 xl:py-9 xl:px-10 2xl:py-10 2xl:px-12 absolute rounded-xl md:rounded-2xl xl:rounded-3xl h-full top-0 left-0 w-full"
      >
        <Link
          to={"/post"}
          onClick={handleOpenPost}
          className="bg-primaryOrange inline-flex rounded-full shadow-none text-white items-center justify-center hover:bg-primaryOrange/75 focus:outline focus:outline-1 focus:outline-primaryOrange/50 font-medium font-sans text-sm py-3 lg:py-[14px] px-8 lg:px-10 w-fit"
        >
          Start Listing
        </Link>
        <p className="text-white font-sans text-[12px] md:text-sm lg:text-base xl:text-[17px] 2xl:text-lg mb-4 xl:mb-7">
          {subTitle}
        </p>
        <p className="text-white font-sans font-bold text-[20px] md:text-[24px] lg:text-[28px] xl:text-[32px] mb-3 lg:mb-4">
          {title}
        </p>
      </div>
    </div>
  </>
);

function ListItemSection() {
  const { isLoggedIn, profile } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openLogin = () => {
    dispatch(setShowLoginModal(true));
    dispatch(setShowSignupModal(false));
  };

  const handleOpenPost = (e) => {
    e.preventDefault();
    if (isLoggedIn) {
      navigate("/post");
   // } else if (isLoggedIn) {
      //navigate("/admin/profile");
    } else {
      openLogin();
    }
  };

  const cards = [
    {
      img: imageUrls.manageRentals,
      title: "Manage your rentals",
      subTitle: "All your rentals from one place",
    },
    {
      img: imageUrls.earnExtra,
      title: "Earn extra income",
      subTitle: "Make extra cash from items you are not using",
    },
  ];

  return (
    <div className="pt-2 pb-24">
      <div
        className="flex flex-col md:flex-row md:justify-between md:items-center py-10 md:py-14 lg:py-16 xl:py-20 2xl:py-24 px-5 md:px-6 lg:px-10 xl:px-14 desktop:px-16 mt-10 mb-14 rounded-2xl lg:rounded-3xl bg-primaryOrange"
        style={{
          background: `url(${imageUrls.listBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <h2 className="font-poppins font-medium text-white text-[26px] lg:text-[2rem] desktop:text-[34px] mb-8 md:mb-0 md:w-[70%] lg:w-[60%] xl:w-[62%] leading-normal text-center md:text-left">
          Get connected to verified customers looking to rent items you are not
          using!
        </h2>

        <Link
          to={"/post"}
          onClick={handleOpenPost}
          className="bg-[#1F1A16] inline-flex rounded-full shadow-none text-white items-center justify-center hover:bg-[#1F1A16]/75 focus:outline focus:outline-1 focus:outline-[#1F1A16]/50 font-medium font-sans text-xl py-5 lg:py-6 px-8 md:px-12"
        >
          List Items
        </Link>
      </div>
      <div className="grid md:grid-cols-2 gap-8 md:gap-5 lg:gap-6 3xl:gap-14 mt-10 3xl:mt-14">
        {cards &&
          cards?.map(({ img, title, subTitle }, index) => (
            <StartListingCard
              key={index}
              img={img}
              title={title}
              subTitle={subTitle}
              handleOpenPost={handleOpenPost}
            />
          ))}
      </div>
    </div>
  );
}

export default ListItemSection;
