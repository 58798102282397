const baseDir = "https://ik.imagekit.io/gideonimgkit/yarlo/tr:default";
const baseDirOriginalSize =
  "https://ik.imagekit.io/gideonimgkit/yarlo/tr:w-0.9";

const imageUrls = {
  logo: `${baseDir}/logo.png`,
  homeHero: `${baseDirOriginalSize}/hero-img.png`,
  specialDeals: `${baseDir}/special-deals-bg.png`,
  keepBrowsing: `${baseDir}/keep-browsing-bg.png`,
  recommended: `${baseDir}/recommended-bg.png`,
  trending: `${baseDir}/trending-bg.png`,
  favorites: `${baseDir}/favorites-bg.png`,
  realEstate: `${baseDir}/home-kitchen.png`,
  vehicles: `${baseDir}/bmw-cars.png`,
  fashion: `${baseDir}/closet.png`,
  electronics: `${baseDir}/electronics.png`,
  furniture: `${baseDir}/furniture.png`,
  kids: `${baseDir}/child-playing-on-beach.png`,
  books: `${baseDir}/pink-books.png`,
  tools: `${baseDir}/toolset.png`,
  connectBg: `${baseDirOriginalSize}/connect-bg.png`,
  listBg: `${baseDirOriginalSize}/list-bg.png`,
  manageRentals: `${baseDir}/laptop-on-desk.png`,
  earnExtra: `${baseDir}/woman-smiling.png`,
  aboutHero: `${baseDirOriginalSize}/about-us-hero.png`,
  aboutHeroParticles: `${baseDirOriginalSize}/hero-particle-bg.png`,
  appMockupBg: `${baseDirOriginalSize}/app-mockup-bg.png`,
  playStore: `${baseDir}/badge-playstore.png`,
  appStore: `${baseDir}/badge-appstore.png`,
  faq: `${baseDir}/faqs.png`,
  missionParticles: `${baseDirOriginalSize}/mission-particle-bg.png`,
  missionImage: `${baseDir}/girl-on-ipad.png`,
  ownerAvatar: `${baseDir}/owner-avatar.png`,
  renterAvatar: `${baseDir}/renter-avatar.png`,
  defaultDp: `${baseDir}/default-dp.png`,
  buckerSystems: `${baseDir}/bucker_systems-partner-logo.png`,
  sixt: `${baseDir}/sixt-partner-logo.png`,
  oakHomes: `${baseDir}/oak_homes-partner-logo.png`,
  bedrock: `${baseDir}/bedrock-partner-logo.png`,
  user: `${baseDir}/user.svg`,
  outdoors: 'https://ik.imagekit.io/yarlo/categories/outdoors.png'
};

export default imageUrls;
