import { configureStore, combineReducers } from "@reduxjs/toolkit";
import listingSlice from "./listingSlice";
import chatSlice from "./chatSlice";
import userReducer from "./userSlice";
import counterReducer from "./stepscounter";
import { authenticationApi } from "../api/auth";
import { listingApi } from "../api/listing";
import { chatApi } from "../api/chat";
import { generalApi } from "../api/general";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const combinedReducer = combineReducers({
  user: userReducer,
  listings: listingSlice,
  message: chatSlice,
  counter: counterReducer,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
  [listingApi.reducerPath]: listingApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [generalApi.reducerPath]: generalApi.reducer,
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authenticationApi.middleware,
      listingApi.middleware,
      chatApi.middleware,
      generalApi.middleware
    ),
});

export let persistor = persistStore(store);
