import React, { Suspense, lazy } from "react";
import Layout from "../Layout";
import BrowseSection from "./Sections/Browse";
import ListItemSection from "./Sections/ListItems";
import PopularSection from "./Sections/Popular";
import { useSelector } from "react-redux";
import HeroBanner from "./Sections/HeroBanner";
// import PartnersBanner from "./Sections/PartnersBanner";
import HeaderComponent from "../Layout/HeaderComponent";
import Spinner from "../Spinner";
const NearyouSection = lazy(() => import("./Sections/Nearyou"));

function Home() {
  const { isLoggedIn } = useSelector((state) => state.user);
  return (
    <Layout>
      <HeaderComponent
        pageTitle={"Yarlo Official Site - Rental Services Marketplace"}
        pageDescription={"Discover & rent across real estate, vehicles, fashion & more on Yarlo, Africa’s online marketplace for rentals services"}
      />
      {!isLoggedIn ? <HeroBanner /> : null}
      <PopularSection />
      <BrowseSection />
      <ListItemSection />
      <Suspense fallback={<Spinner />}>
        <NearyouSection />
      </Suspense>
    </Layout>
  );
}

export default Home;
