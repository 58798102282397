import React from "react";
import Layout from "../Layout";
import HeaderComponent from "../Layout/HeaderComponent";

export default function Index() {
  return (
    <Layout>
      <HeaderComponent
        pageTitle={"Yarlo's Terms of Service"}
        pageDescription={"These Terms of Service are important. Please read them carefully as they contain details of our statutory and regulatory responsibilities to you, and your contractual obligations to us."}
      />
      <div className="container font-poppins">
        <div className="my-10">
          <h2 className="header-text text-2xl">Terms of Service</h2>
        </div>
        <div className="mb-20" id="introduction">
          <h4 className="header-text">1. INTRODUCTION</h4>
          <p>
            Welcome to Yarlo – a digital platform on a mission to change the way
            we rent assets and provide tools to make it easier and faster.
          </p>
          <p>
            By signing up for an account with Yarlo, you are agreeing to be
            bound by the following terms and conditions ("Terms of Service").
            These Terms of Service are to be read in conjunction with:
          </p>
          <div>
            <ul>
              <li>
                our{" "}
                <a href="https://www.yarlo.co/privacy-policy.html">
                  Privacy Policy
                </a>
                , which sets out the terms on which we process any personal data
                we collect from you, or that you or others provide to us; and{" "}
              </li>
              <li>
                our{" "}
                <a href="https://www.yarlo.co/cookies_policy.html">
                  Cookies Policy
                </a>
                , which sets out information about the cookies we use.{" "}
              </li>
            </ul>
          </div>
          <p>
            These Terms of Service are important. Please read them carefully as
            they contain details of our statutory and regulatory
            responsibilities to you, and your contractual obligations to us.
            They also set out the basis on which we provide, and you are
            permitted to use, the various services made available on the website{" "}
            <a href="https://www.yarlo.co">www.yarlo.co</a> and the mobile
            application known as 'Yarlo' (together, the "
            <strong style={{ fontWeight: "bold" }}>Platform</strong>"),
            including the following:
          </p>
          <div>
            <ul>
              <li>Creating a Yarlo Profile;</li>
              <li>Rental Services and</li>
              <li>
                Providing a communication platform between customers, asset
                owners and agents,
              </li>
              <li>
                (the <strong style={{ fontWeight: "bold" }}>"Services"</strong>
                ). Any new services, features or tools which are added to the
                Platform from time to time shall be considered a Service and
                shall also be subject to these Terms of Service, unless
                indicated otherwise.
              </li>
            </ul>
          </div>
          <br />
          <br />
          <p>
            If you do not agree to these Terms of Service, please do not create
            an account or use the Platform.
          </p>
          <p>
            You can review the current version of the Terms of Service at any
            time on this page. We reserve the right to change the Terms of
            Service at any time by posting updates to this page. If we make
            substantial changes, or if we are required to do so by law, we will
            notify you of such change in advance to the email address associated
            with your Yarlo account, but it is your responsibility to check this
            page from time to time to make sure you’re aware of any changes to
            these Terms of Service.
          </p>
        </div>
        <div className=" mb-20">
          <h4 className="header-text">2. INFORMATION ABOUT US</h4>
          <p>
            Yarlo (RC 1881326) is registered in Nigeria. Our registered office
            is at 13 Ojefia Street, Flat 1, Victory Estate, Ago Palace Way,
            Okota, Lagos, Nigeria.
          </p>
          <p>
            You can contact Yarlo at anytime by emailing{" "}
            <a href="mailto:support@yarlo.co">support@yarlo.co</a>.
          </p>
        </div>
        <div className=" mb-20" id="account">
          <h4 className="header-text">3. ACCOUNT</h4>
          <h5>3.1. Account Set-up and maintenance</h5>
          <p>
            To use our Services, you must first register for an account on the
            Platform ("
            <strong style={{ fontWeight: "bold" }}>Yarlo Account</strong>"). In
            the case of a company, by registering for a Yarlo Account, you
            warrant that you have authority to bind the company (as the
            contracting party) to these Terms of Service. In the case of an
            individual, by registering for a Yarlo Account, you warrant that you
            are:
          </p>
          <div>
            <ol type="1">
              <li style={{ display: "list-item" }}>
                18 years of age or older and;
              </li>
              <li style={{ display: "list-item" }}>
                A person who has the right of abode in the Nigeria, or who has
                been granted indefinite leave to remain or has no time limit on
                his/her stay in the Nigeria.
              </li>
            </ol>
          </div>
          <br />
          <br />
          <p>
            You hereby represent and warrant that the information you provide to
            Yarlo upon registration and at all other times, will be true,
            accurate, current, and complete. You will ensure that this
            information is kept up-to-date at all times.
          </p>
          <p>
            You are responsible for any use of our Services with your Yarlo
            Account details and password, and for protecting your Yarlo Account
            details and password from unauthorised use. You must not share this
            information with any other person. You are also responsible for the
            security of any computer from which you choose to sign into your
            Yarlo Account.
          </p>
          <p>
            We reserve the right to refuse to register or to delete your Yarlo
            Account if you have previously had a Yarlo Account deactivated by us
            due to your breach of these Terms of Service (or earlier versions
            thereof).
          </p>
        </div>
        <div className="mb-20">
          <h4 className="header-text">4. ACCESS TO THE WEBSITE</h4>
          <p>
            Yarlo will make reasonable efforts to make the Platform and Services
            available 24 hours a day, 7 days a week. Notwithstanding this, you
            hereby acknowledge that your use of the Platform and the Services
            may be interrupted from time to time, for example, when we perform
            maintenance services or as a result of delays or delivery failures
            resulting from the use of communications networks and facilities.
          </p>
          <p>
            Access to the Platform and use of the Services is provided on an “as
            is” basis which means that we do not warrant the Platform or
            Services will be error- free at all times, and we shall not be
            liable to you for failure of the same.
          </p>
        </div>
        <div className="mb-20" id="asset-owners">
          <h4 className="header-text">5. ASSET OWNERS & AGENTS</h4>
          <div>
            <ul>
              <li>
                Owners post assets on Yarlo to allow Customers to rent their
                assets.
              </li>
              <li>
                Owners may also offer add-ons to customers in addition to their
                assets.
              </li>
              <li>
                Yarlo retains the right to use all publicly listed assets for
                Yarlo marketing and promotional purposes.
              </li>
              <li>Assets uploaded on Yarlo are User Generated Content.</li>
              <li>
                Assets and/or users may be removed by Yarlo from the Site for
                violations of these Terms of Service and/or our Community
                Standards, which may include (but are not limited to) the
                following violations and/or materials:
                <div>
                  <ol>
                    <li>Illegal or Fraudulent services</li>
                    <li>
                      Copyright Infringement, Trademark Infringement, and
                      violation of a third party's terms of service
                    </li>
                    <li>
                      Adult oriented services, Pornographic,
                      Inappropriate/Obscene
                    </li>
                    <li>Intentional copies of Assets</li>
                    <li>Spam, nonsense, or violent or deceptive Posts</li>
                    <li>Items misleading to Customers or others</li>
                    <li>Rental of regulated items</li>
                    <li>Exceedingly low quality Items</li>
                    <li>
                      Promoting Yarlo and/or Yarlo Assets through activities
                      that are prohibited by any laws, regulations, and/or third
                      parties' terms of service, as well as through any
                      marketing activity that negatively affects our
                      relationships with our users or partners.
                    </li>
                  </ol>
                </div>
              </li>
            </ul>
          </div>
          <br />
          <br />
          <p>
            Items that are removed for violations mentioned above, may result in
            the suspension of the Owner’s account.
          </p>
          <p>
            Items that are removed for violations are not eligible to be
            restored or edited. Items may be removed from our Search feature due
            to poor performance and/or user misconduct.
          </p>
          <p>
            Items are required to have an appropriate Item image related to the
            rental service offered. An option to upload multiple additional item
            images are available to all Owners. Owners must deliver the same
            quality of rental service as shown on their item images. Recurring
            deliveries that don’t match the quality shown on the item images may
            lead to the Owner’s account becoming permanently disabled from
            posting items.
          </p>
          <p>
            Statements on the Item Page that undermine or circumvent these Terms
            of Service is prohibited.
          </p>
          <p>
            Certain services are available only to Premium Owners after they
            create Items.
          </p>
          <p>
            We will provide the Services using reasonable skill and care and in
            accordance with our legal and regulatory obligations. We reserve the
            right (without liability to you or prejudice to our other rights) to
            suspend your access to or use of the Services (including through
            deactivation of your Yarlo Account) without notice in the event you
            breach, or we reasonably suspect that you have breached or will
            breach, these Terms of Service. Please note below the additional
            terms and conditions that apply to your use of particular Services.
            If you are someone that is renting or looking to rent a property,
            you are referred to as a "Renter" or "Tennant". If you own a
            property, or multiple properties, that you rent, or wish to rent to
            Renters, you are referred to as a "Landlord" (Landlord includes
            institutional landlords). Any agent that acts on behalf of a
            Landlord to market properties for rent to Renters is referred to as
            a "Letting Agent".
          </p>
          <h5>5.1. Yarlo ID</h5>
          <p>
            Yarlo is all about helping Owners & agents extend their reach. We
            seek to empower top performing Owners with helpful tools to grow
            their business. Owners who invest in self-promotion may achieve
            greater customer satisfaction. And, if they deliver and maintain
            high quality items and ratings, Yarlo may reward them with new
            statuses, special opportunities, benefits, and tools that come with
            it.
          </p>
          <ul>
            <li>
              Yarlo Owners can gain account Levels based on their activity,
              performance and reputation.
            </li>
            <li>
              Advancement in Levels are updated periodically by an automated
              system.
            </li>
          </ul>
          <h5>5.2. Shipping Items</h5>
          <p>
            Some of the rental services on Yarlo are delivered physically. For
            these types of Items, Owners may decide to define a freight pricing
            factor.
          </p>
          <p>
            Items that include a shipping pricing factor must have physical
            deliverables delivered to Customers.
          </p>
          <p>
            Shipping costs added to a Items only pertains to the cost Owner
            requires to ship physical items to Customers.
          </p>
          <p>
            Important: Customers who rent Items that require physical delivery,
            will be asked to provide a shipping address.
          </p>
          <p>
            Owners are responsible for all freight arrangements once the
            Customer provides the shipping address.
          </p>
          <p>
            Yarlo does not handle or guarantee shipping, tracking, quality, and
            condition of items or their delivery and shall not be responsible or
            liable for any damages or other problems resulting from shipping.
          </p>
          <p>
            A tracking number is a great way to avoid disputes related to
            shipping.
          </p>
        </div>
        <div className="mb-20">
          <h4 className="header-text">6. DISPUTES AND CANCELLATIONS</h4>
          <p>
            We encourage our Users, Agents and Owners to try and settle
            conflicts amongst themselves. If for any reason this fails users can
            contact Yarlo's Customer Support for assistance.
          </p>
        </div>
        <div className="mb-20" id="charges">
          <h4 className="header-text">7. CHARGES</h4>
          <p>
            To calculate your earnings, add any add-on fees (like your caution
            fee) to your rental rate. This is your subtotal.
          </p>
          <p>
            Once you have your subtotal, subtract applicable fees and taxes.
            While taxes can vary based on where you live.
          </p>
          <p>
            Earnings will vary depending on the length of a rentals, any
            discounts you applied, etc. If your payout is delayed or appears
            lower than you expected, it could be due to a reservation that was
            cancelled or changed that resulted in a full or partial refund for
            your customer.
          </p>
        </div>
        <div className="mb-20">
          <h4 className="header-text">
            8. YOUR USE OF THE WEBSITE & SERVICES
          </h4>
          <h5>8.1. Prohibited Use</h5>
          <p>You shall not:</p>
          <div className="alphabet">
            <ol>
              <li>
                licence, sell, rent, transfer, assign, distribute, display,
                disclose or otherwise commercially exploit, or otherwise make
                the Platform or any of the Services available to any third
                party;
              </li>
              <li>
                access or use all or any part of the Platform or the Services in
                order to build a product or service which competes with the
                Services, or for the purposes of monitoring the Services’
                availability, performance or functionality;
              </li>
              <li>
                use the Platform or any of the Services to provide services to
                third parties (unless those third parties also have Yarlo
                Accounts and such use is in accordance with the intended and
                usual purpose of the Services), or permit the Platform or the
                Services or any part of them to be combined with, or
                incorporated into, any other service or program;
              </li>
              <li>
                attempt to copy, modify, duplicate, create derivative works of,
                frame, mirror, republish, download, display, transmit or
                distribute all or any portion of the software applications used
                by us as part of the Platform or any of the Services (except as
                may be allowed by any applicable law incapable of exclusion);
              </li>
              <li>
                attempt to reverse compile, disassemble, reverse engineer or
                otherwise reduce to human-perceivable form all or any part of
                the software applications used by us as part of the Platform or
                any of the Services (except as may be allowed by any applicable
                law incapable of exclusion);
              </li>
              <li>
                attempt to obtain, or assist third parties in obtaining,
                unauthorised access to the Platform or Services;
              </li>
              <li>
                interfere with or disrupt the Platform or the Services, or use
                or access the Platform or the Services in a manner that
                unfavourably affects the performance or proper functioning of
                the Platform and the Services, or any computer systems or
                networks used by the Platform or the Services;
              </li>
              <li>
                use any robot, spider or other automatic device or process to
                send or redirect messages, or monitor, download, copy or keep a
                database copy of any of the content transmitted to or made
                available through the Platform or the Services;
              </li>
              <li>
                access, store, distribute or transmit any computer viruses,
                Trojan horses, worms, time bombs or any other harmful or
                deleterious software programs during the course of your use of
                the Platform or Services;
              </li>
              <li>
                remove any copyright, trademark or other proprietary rights
                notices contained in or on the Platform or the Services (whether
                ours or our licensors');
              </li>
              <li>
                remove, cover or otherwise obscure any form of advertisement
                included on the Services;
              </li>
              <li>
                collect, harvest, use, copy, share or transfer any information,
                including but not limited to personal data, obtained from the
                Services (excluding your User Content, and unless the owner of such information has
                expressly permitted the same); or
              </li>
              <li>
                use the Platform or the Services or any aspect or feature
                thereof for any, illegal unlawful or fraudulent purpose or in
                any way that might cause harm to us or any other person.
              </li>
            </ol>
          </div>
          <h5>8.2. Prohibited Content</h5>
          <p>
            You shall not submit or transmit any information, data or content
            such as text, documents, photos, audiovisual content and other media
            content (including all information submitted to us by you for the
            purpose of creating your Yarlo ID as well as all Contributions as
            defined in Section 11 'Contributions' below) ("User Content") during
            the course of your use of the Platform or Services that:
          </p>
          <div className="alphabet">
            <ol>
              <li>is fraudulent or unlawful;</li>
              <li>
                harmful, threatening, defamatory, obscene, infringing, harassing
                or racially or ethnically offensive;
              </li>
              <li>
                infringes, misappropriates or violates a third party’s rights,
                including intellectual property rights or rights of publicity or
                privacy;
              </li>
              <li>facilitates illegal activity;</li>
              <li>depicts sexually explicit images;</li>
              <li>promotes unlawful violence;</li>
              <li>
                is discriminatory based on race, gender, colour, religious
                belief, sexual orientation, disability;
              </li>
              <li>
                breaches any legal duty owned by a third party, such as a
                contractual duty or a duty of confidence;
              </li>
              <li>
                promotes the business of a third party (other than in the usual
                course of use of the Services, for example, placing a review of
                a Letting Agent); or
              </li>
              <li>
                deceives any person, impersonates any person, or misrepresents
                your identity or affiliation with any person.
              </li>
            </ol>
          </div>
          <br />
          <br />
          <p>
            In addition, all User Content must be accurate (where it states
            facts) and be genuinely held (where it states opinions).
          </p>
        </div>
        <div id="indemnity"  className="mb-20">
          <h4 className="header-text">9. INDEMNITY</h4>
          <strong>Limitation on Liability: </strong>
          <p>
            IN NO EVENT WILL YARLO, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>
          <p>
            The term “Affiliate” referred to herein, is an entity that, directly or indirectly, controls, or is under the control of, or is under common control with Yarlo, where control means having more than fifty percent (50%) voting stock or other ownership interest or the majority of voting rights of such entity.
          </p>
        </div>
        <div className="mb-20">
          <h4 className="header-text">10. USER CONTENT</h4>
          <p>
            All personal data will be processed in accordance with our Privacy
            Policy and any other User Content will be considered
            non-confidential and non- proprietary unless you specifically advise
            us otherwise in writing.
          </p>
          <p>
            You shall have the sole responsibility for the legality,
            reliability, integrity, accuracy and quality of your User Content,
            including its compliance with the content standards set out in
            Section ‘Prohibited Content’ above, and agree to promptly update or
            correct any such User Content on becoming aware of any errors or
            inaccuracies.
          </p>
          <p>
            We have no obligation to monitor, censor or review User Content and
            its compliance with the content standards set out in ‘Prohibited
            Content’ above, but we reserve the right to do so. We further
            reserve the right to remove, edit or decline to display any User
            Content without notice, or to require you to amend your User Content
            at any time, if we consider, or have reason to believe, that such
            User Content does not comply with the above content standards.
          </p>
        </div>
        <div className=" mb-20">
          <h4 className="header-text">11. INTELLECTUAL PROPERTY</h4>
          <p>
            You acknowledge that all intellectual property rights in the
            Platform and the Services anywhere in the world belong to us or our
            licensors, and that you have no rights in or to the Platform or the
            Services other than the right to use each of them in accordance with
            these Terms of Service.
          </p>
          <p>
            You retain all ownership rights in your User Content (as defined in
            Section ‘Prohibited Content’ above), but you are required to grant
            us a worldwide, non-exclusive, royalty-free, transferable licence to
            use, reproduce, distribute, prepare derivative works of, display,
            and perform your User Content in connection with our performance of
            the Services and provision of the Platform and (where relevant)
            other online platforms.
          </p>
        </div>
        <div className=" mb-20">
          <h4 className="header-text">12. CONTRIBUTIONS</h4>
          <p>
            When you submit any ideas, suggestions, documents and/or proposals
            relating to the Services (or other products or services) to Yarlo
            through the <strong>"Contact Us"</strong>, the Support interfaces or
            through any other channel or mechanism (collectively{" "}
            <strong>"Contributions"</strong>), you acknowledge and agree that:
          </p>
          <div className="alphabet">
            <ol>
              <li>
                Yarlo shall be entitled to use or disclose (or choose not to use
                or disclose) such Contributions for any purpose, in any way;
              </li>
              <li>
                to the extent there are any intellectual property rights
                subsisting in your Contributions, that hereby grant us a
                worldwide, perpetual, non- exclusive, transferable and
                irrevocable license (with a right to sub- license) under such
                rights to use and exploit the Contributions without limitation
                or restriction; and
              </li>
              <li>
                you are not entitled to any compensation or reimbursement of any
                kind from Yarlo under any circumstances.
              </li>
            </ol>
          </div>
          <br />
          <br />
          <p>You agree to the following limitations on our liability to you:</p>
          <div className="alphabet">
            <ol>
              <li>
                Exclusion of certain losses: Yarlo shall not be liable to you
                for any loss of profits, business, anticipated savings, goodwill
                or business opportunity, business interruption, loss or
                corruption of data or information (subject to our liability to
                you as data controller of personal data), or for any special,
                indirect or consequential loss or damage, howsoever arising
                under these Terms of Service;
              </li>
            </ol>
          </div>
          <p>
            Further, due to the nature of the Platform, the Services and the
            online environment, we do not take responsibility for the following:
          </p>
          <div className="alphabet">
            <ol>
              <li>
                Network problems: the transfer of content and data over
                communication networks and facilities, including the internet,
                is subject to limitations, delays and other problems inherent in
                the use of such communication facilities. We are not responsible
                for any loss, damage, delay or failure resulting from such
                transfers or any other event beyond our reasonable control;
              </li>
              <li>
                Results of Services: you assume full responsibility for results
                achieved or obtained from the use of the Services, and for
                conclusions drawn from such use. We do not warrant that the
                Services or any information obtained by you through the Services
                will meet your particular requirements; and
              </li>
              <li>
                Your actions, instructions and breach: Yarlo shall have no
                liability for any loss or damage caused by errors or omissions
                in any content or information provided by you to Yarlo in
                connection with the Services, or any action taken by Yarlo as a
                result of your direction, or your breach of these Terms of
                Service.
              </li>
            </ol>
          </div>
          <br />
          <br />
          <p>
            Nothing in these Terms of Service shall exclude either party’s
            liability for death or personal injury caused by negligence, fraud
            or fraudulent misrepresentation or any other liability that cannot
            be excluded such as under the rules and regulations of the Financial
            Conduct Authority.
          </p>
          <p>
            All warranties, conditions, representations or other terms implied
            by statute or common law in relation to the Platform and any
            Services provided by us are excluded to the fullest extent permitted
            by law.
          </p>
        </div>
        <div className="mb-20" id="termination">
          <h4 className="header-text">13. TERMINATION</h4>
          <p>
            You may terminate these Terms of Service with us at any time, for
            any reason, by deactivating your Yarlo Account and ceasing all use
            of the Platform and the Services. Please note that termination of
            these Terms of Service will not affect the validity of any Policy or
            tenancy agreement that you have entered into.
          </p>
          <p>
            We may terminate these Terms of Service with you and deactivate your
            Yarlo Account immediately in the event you commit a material or
            persistent breach of these Terms of Service, the Services are
            discontinued, we lose the right to provide you with the Platform or
            any of the Services, or where the provision of the Platform or any
            of the Services becomes unlawful. We will use reasonable endeavours
            to provide you with notice in advance; however, you acknowledge that
            this may not be possible in all circumstances.
          </p>
          <p>
            For the avoidance of doubt, termination of these Terms of Service
            will not result in the termination of any Policy rentd by you or any
            tenancy agreement entered into you by.
          </p>
        </div>
        <div className=" mb-20">
          <h4 className="header-text">14. PRIVACY & YOUR INFORMATION</h4>
          <p>
            See our{" "}
            <a href="https://www.yarlo.co/privacy-policy.html">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="https://www.yarlo.co/cookies_policy.html">
              Cookies Policy
            </a>{" "}
            which are hereby incorporated into these Terms of Service by
            reference, for more information about how your personal data will be
            processed by us.
          </p>
        </div>
          <div className=" mb-20">
          <h4 className="header-text">15. OTHER IMPORTANT TERMS</h4>
          <p>
            <strong style={{ fontWeight: "bold" }}>
              Assignment and other dealings:
            </strong>{" "}
            You may not assign, transfer, sub-license or deal in any other
            manner with any or all of your rights or obligations under these
            Terms of Service, without our prior written consent. We reserve the
            right to transfer, assign, sub-contract or deal in any other manner
            with any or all of our rights or obligations under these Terms of
            Service, without notifying you or receiving your consent.
          </p>
          <p>
            <strong style={{ fontWeight: "bold" }}>Waiver:</strong> If we do not
            insist immediately that you do anything you are required to do under
            these Terms of Service, or if we delay in taking steps against you
            in respect of your breaking this contract, that will not mean that
            you do not have to do those things and it will not prevent us taking
            steps against you at a later date.
          </p>
          <p>
            <strong style={{ fontWeight: "bold" }}>Third Party Rights:</strong>{" "}
            No one other than a party to these Terms of Service, their
            successors and permitted assignees, shall have any right to enforce
            any of its terms.
          </p>
          <p>
            <strong style={{ fontWeight: "bold" }}>Entire Agreement:</strong>{" "}
            These Terms of Service, and all documents referred to in them,
            constitute the entire agreement between the parties and supersedes
            and extinguishes all previous agreements, promises, assurances,
            warranties, representations and understandings between them, whether
            written or oral, relating to its subject matter.
          </p>
          <p>
            <strong style={{ fontWeight: "bold" }}>
              Governing Law/ Jurisdiction:
            </strong>{" "}
            These Terms of Service are governed by Nigerian law and you can
            bring legal proceedings in respect of any dispute or claim arising
            out of or in connection with these Terms of Service in the Nigerian
            courts.
          </p>
        </div>
      </div>
    </Layout>
  );
}
