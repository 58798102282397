import React, { useEffect, useState } from "react";
import TitleHead from "./title";
import { useGetNotificationsQuery } from "../../api/general";
import { useSelector } from "react-redux";
import Spinner from "../Spinner";

export default function Notification() {
  const { profile } = useSelector((state) => state.user);
  const { data, isLoading } = useGetNotificationsQuery(profile?.userId);
  const [notification, setNotification] = useState([]);

  useEffect(() => {
    setNotification(data);
  }, [data]);

  return (
    <div>
      <TitleHead title="Notifications" />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex h-full items-center justify-center overflow-y-scroll">
            <div
              className="site-layout-background"
              style={{
                minHeight: 360,
                height: 500,
              }}
            >
              {notification?.length > 0 ? (
                notification.map((note, i) => (
                  <div className="mt-4" key={i}>
                    <div className="p-8 bg-white">
                      <p>
                        <span className="font-poppins text-base font-semibold text-green-500">
                          {note.title}
                        </span>
                        <span className="font-poppins text-[9px] font-semibold text-right float-right text-gray-400">
                          {note.dateCreated}
                        </span>
                      </p>
                      <p className="font-poppins text-base text-gray-500">
                        {note.notificationMessage}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="font-poppins text-2xl font-semibold text-headingBlack mt-8">
                  {" "}
                  No Notifications
                </p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
