import React, { useEffect, useState } from "react";
import useMessageAlert from "../Message";
import TitleHead from "./title";
import {
  BullhornIcon,
  DeleteIcon,
  EditIcon,
  MoreIcon,
  PauseIcon,
  PinDropIcon,
  GiftIcon
} from "../CustomIcons";
import { appendImageUrl, formatAmount } from "../../helpers";
import { Dropdown, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  useArchiveListingMutation,
  useDeleteListingMutation,
  useGetUserListingsQuery,
  useLazyGetListingByIDQuery,
  useAddSpecialDealListingMutation,
} from "../../api/listing";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, isEmpty } from "lodash";
import Spinner from "../Spinner";
import { resetNewListing } from "../../slice/listingSlice";
import { reset } from "../../slice/stepscounter";
import InputField from "../Form/InputField";
import { Form, Formik } from "formik";

function Advert() {
  const { openMessage, contextHolder } = useMessageAlert();
  const { profile } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useGetUserListingsQuery(profile?.userId);
  const [deleteListing, { isLoading: deleting }] = useDeleteListingMutation();
  const [archiveListing, { isLoading: archiving }] =
    useArchiveListingMutation();
  const [getListing, { isLoading: editing }] = useLazyGetListingByIDQuery();
  const [openModal, setOpenModal] = useState(false);
  const [currentListingId, setCurrentListingId] = useState("");
  const [currentAction, setCurrentAction] = useState("");
  const [openSpecialDealModal, setSpecialDealModal] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [dealPrice, setDealPrice] = useState(0);
  const [addSpecialDealList, {isLoading: addingSpecialDeal}] = useAddSpecialDealListingMutation();
  const [listing, setListing] = useState({});

  const handleDeleteListing = (id) => {
    deleteListing({ listingId: id, userId: profile?.userId })
      .unwrap()
      .then(() => {
        openMessage("success", "Listing deleted successfully!");
      })
      .catch((error) => {
        openMessage(
          "error",
          error?.data?.errorMessage || "Failed to delete listing"
        );
      });
  };

  const handlePercentage=(e)=> {
    const discount = (e/100) *  listing?.rentAmount;
    setDealPrice(listing?.rentAmount - discount);
    setPercentage(e);
  }

  const handleDealPrice=(e)=> {
    setDealPrice(e);
    const per = ((listing?.rentAmount - e)/listing?.rentAmount) * 100;
    setPercentage(per);
  }

  const handleArchiveListing = (id) => {
    archiveListing({ listingId: id, userId: profile?.userId })
      .unwrap()
      .then(() => {
        openMessage("success", "Listing archived successfully!");
      })
      .catch((error) => {
        openMessage(
          "error",
          error?.data?.errorMessage || "Failed to archive listing"
        );
      });
  };

  const handleEditListing = (status) => {
    if (status === "ACTIVE") {
      return openMessage(
        "warning",
        "You must archive a listing first before editing"
      );
    } else {
      dispatch(reset());
      getListing(currentListingId)
        .unwrap()
        .then((res) => dispatch(resetNewListing(res)))
        .then(() => navigate("/post"))
        .catch((error) => {
          openMessage(
            "error",
            error?.data?.errorMessage || "Failed to edit listing"
          );
        });
    }
  };

  const handlePromoteListing = (status) => {
    if (status !== "ACTIVE") {
      return openMessage("warning", "You can only promote an active listing");
    } else {
      navigate(`/promote/${currentListingId}`);
    }
  };

  const handleSpecialDealListing = (status, ad) => {
    if (status !== "ACTIVE") {
      return openMessage("warning", "You can only create special deals for an active listing");
    } else {
      setListing(ad);
      setSpecialDealModal(true);
    }
  };

  const showDeleteModal = () => {
    setCurrentAction("delete");
    setOpenModal(true);
  };

  const showArchiveModal = () => {
    setCurrentAction("archive");
    setOpenModal(true);
  };

  const handleUpdateSpecialDeal = () => {
    addSpecialDealList({
      userId: profile?.userId,
      listingId: currentListingId,
      price: dealPrice
    }).unwrap();
  };

  useEffect(() => {
    refetch(profile?.userId);
  }, [refetch, profile.userId]);

  return (
    <div>
      {contextHolder}
      <TitleHead title="My Adverts" />
      {isEmpty(data) ? (
        <div className="flex h-full items-center justify-center">
          <p className="font-poppins text-2xl font-semibold text-headingBlack mt-8">
            No listings yet
          </p>
        </div>
      ) : (
        <div className="p-4 lg:p-6 xl:p-8">
          {isLoading || deleting || archiving || editing || addingSpecialDeal? (
            <Spinner />
          ) : (
            <>
              {data?.map((ad, i) => (
                <ListingItem
                  key={i}
                  advert={ad}
                  deleteAdvert={showDeleteModal}
                  archiveAdvert={showArchiveModal}
                  setCurrentListingId={setCurrentListingId}
                  editAdvert={handleEditListing}
                  promoteListing={handlePromoteListing}
                  specialDealListing={handleSpecialDealListing}
                />
              ))}
            </>
          )}
        </div>
      )}
      <Modal
        open={openModal}
        title={`${currentAction === "delete" ? "Delete" : "Archive"} Listing`}
        onCancel={() => setOpenModal(false)}
        onOk={() => {
          setOpenModal(false);
          if (currentAction === "delete") {
            handleDeleteListing(currentListingId);
          } else {
            handleArchiveListing(currentListingId);
          }
        }}
        wrapClassName="preview-modal"
      >
        <div className="flex h-[5rem] items-center justify-center">
          <p className="text-lg text-primaryBlack font-medium">
            {`Are you sure you want to ${currentAction} this listing?`}
          </p>
        </div>
      </Modal>

      <Modal
        open={openSpecialDealModal}
        title={`Add Special Deal for Listing`}
        onCancel={() => setSpecialDealModal(false)}
        okText={'Save Deal'}
        onOk={() => {
          
          handleUpdateSpecialDeal();
          setSpecialDealModal(false);
        }}
        wrapClassName="preview-modal"
      >
        <Formik>
      {({
          handleBlur,
          errors,
          touched,
      }) => (
      <Form>
        <div className="flex mt-6 flex-col w-full">
          <div className="grid sm:grid-cols-2 sm:gap-4 lg:gap-y-0">
            <InputField
              name={"dealPrice"}
              inputType={"number"}
              label={"Deal Price"}
              placeholder={"Enter Deal Price(Optional)"}
              onChange={(e) => handleDealPrice(e.target.value)}
              onBlur={handleBlur}
              value={dealPrice}
              error={errors.dealPrice}
              touched={touched.dealPrice}
              maxValue={listing?.rentAmount}
              minValue={0}
            />

            <InputField
              name={"percentage"}
              inputType={"number"}
              label={"Percentage Off"}
              placeholder={"Enter Deal Percentage(Optional)"}
              onChange={(e) => handlePercentage(e.target.value)}
              onBlur={handleBlur}
              value={percentage}
              error={errors.percentage}
              touched={touched.percentage}
              minValue={0}
              maxLength={100}
            />
          </div>
        </div>
      </Form>
      )}
      </Formik>
    </Modal>
      
    </div>
  );
}

export default Advert;

const ListingItem = ({
  advert,
  deleteAdvert,
  setCurrentListingId,
  archiveAdvert,
  editAdvert,
  promoteListing,
  specialDealListing
}) => (
  <div className="rounded-2xl bg-white p-3 flex flex-col md:flex-row shadow-md mb-5">
    <Link to={`/listing/${advert?.listingId}`}>
      <img
        src={appendImageUrl(advert?.imagesLocation?.[0])}
        alt="ad"
        className="h-[160px] md:h-[146px] desktop:h-[160px] rounded-[10px] w-full md:max-w-[260px] object-cover"
      />
    </Link>
    <div className="flex flex-col mt-4 md:mt-0 md:ml-4 desktop:ml-6 w-full relative min-h-full">
      <div className="flex justify-between lg:pr-1">
        <Link to={`/listing/${advert?.listingId}`}>
          <p className="font-poppins text-lg lg:text-2xl font-medium mt-3 mb-1 lg:mb-[5px] text-headingBlack">
            {advert?.listingHeading}
          </p>
        </Link>
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: (
                  <Link
                    to={"#"}
                    onClick={(e) => {
                      e.preventDefault();
                      editAdvert(advert?.listingStatus);
                    }}
                  >
                    <p className="mb-0 text-sm font-medium text-primaryBlack">
                      Edit Advert
                    </p>
                  </Link>
                ),
                icon: <EditIcon className={"w-4 h-4"} />,
              },
              {
                key: "2",
                label: (
                  <Link
                    to={"#"}
                    onClick={(e) => {
                      e.preventDefault();
                      promoteListing(advert?.listingStatus);
                    }}
                  >
                    <p className="mb-0 text-sm font-medium text-primaryBlack">
                      Promote
                    </p>
                  </Link>
                ),
                icon: <BullhornIcon className={"w-4 h-4"} />,
              },
              {
                key: "3",
                label: (
                  <Link
                    to={"#"}
                    onClick={(e) => {
                      e.preventDefault();
                      archiveAdvert();
                    }}
                  >
                    <p className="mb-0 text-sm font-medium text-[#F1B906]">
                      Archive
                    </p>
                  </Link>
                ),
                icon: <PauseIcon className={"w-4 h-4"} />,
              },
              {
                key: "4",
                label: (
                  <Link
                    to={"#"}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteAdvert();
                    }}
                  >
                    <p className="mb-0 text-sm font-medium text-[#FF3B30]">
                      Delete
                    </p>
                  </Link>
                ),
                icon: <DeleteIcon className={"w-4 h-4"} />,
              },
              {
                key: "5",
                label: (
                  <Link
                    to={"#"}
                    onClick={(e) => {
                      e.preventDefault();
                      specialDealListing(advert?.listingStatus, advert);
                    }}
                  >
                    <p className="mb-0 text-sm font-medium text-primaryBlack">
                      Add Deal
                    </p>
                  </Link>
                ),
                icon: <GiftIcon className={"w-4 h-4"} />,
              },
            ],
          }}
          trigger={["click"]}
          placement={"bottomRight"}
          overlayClassName="ad-menu"
        >
          <MoreIcon
            className={"w-[5px] h-[18px] cursor-pointer"}
            onClick={() => setCurrentListingId(advert?.listingId)}
          />
        </Dropdown>
      </div>
      <div className="flex items-center">
        <PinDropIcon className="w-3 h-3.5 md:w-4 md:h-5" />
        <p className="font-poppins text-base ml-2 text-textGray mb-0">
          {advert?.localGovernmentArea}
        </p>
      </div>
      <div className="flex items-center justify-between mt-auto pb-2">
        <p className="text-primaryOrange text-lg lg:text-xl font-semibold mb-0">
          ₦{formatAmount(advert?.rentAmount)}
        </p>
        {advert?.dealPrice == undefined && advert?.dealPrice > 0 ? <p className="text-primaryOrange text-lg lg:text-xl font-semibold mb-0">
          ₦{formatAmount(advert?.dealPrice)}
        </p>: <></>}
        <p
          className={`text-green text-base font-medium mb-0 ${
            advert?.listingStatus === "PENDING"
              ? " text-[#878787]"
              : advert?.listingStatus === "ACTIVE"
              ? " text-[#01AB07]"
              : " text-[#F1B906]"
          }`}
        >
          {capitalize(advert?.listingStatus)}
        </p>
      </div>
    </div>
  </div>
);
