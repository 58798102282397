import { message } from "antd";

function useMessageAlert() {
  const [messageApi, contextHolder] = message.useMessage();

  const openMessage = (type, message) => {
    messageApi.destroy();
    messageApi.open({
      type,
      content: message,
      className: "message-alert text-base font-poppins font-semibold",
    });
  };

  return { openMessage, contextHolder };
}

export default useMessageAlert;
