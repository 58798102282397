import React from "react";
import imageUrls from "../LazyLoadImg/imageUrls";
import LazyLoadImg from "../LazyLoadImg";

function Mission() {
  return (
    <div
      className="py-14 lg:py-20 xl:py-24 -mx-4 sm:-mx-6 md:-mx-8 xl:-mx-32 2xl:-mx-36 2xl:mx-auto px-4 sm:px-6 md:px-8 xl:px-32 2xl:px-36"
      style={{
        background: `url(${imageUrls.missionParticles})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="grid md:grid-cols-2 gap-10 md:gap-4 lg:gap-5 xl:gap-8 desktop:gap-12 max-w-7xl desktop:mx-auto">
        <div className="flex order-2 md:order-1">
          <LazyLoadImg
            src={imageUrls.missionImage}
            alt="girl on ipad"
            className="rounded-3xl w-[20.125rem] h-[15.25rem] md:w-[21.25rem] md:h-[16.125rem] lg:w-[27.75rem] lg:h-[21rem] xl:w-[30.25rem] xl:h-[22.875rem] mx-auto"
          />
        </div>
        <div className="flex flex-col justify-center order-1 md:order-2">
          <h2 className="text-primaryOrange leading-normal text-center font-sans font-bold text-[2rem] lg:text-[2.125rem] xl:text-[2.5rem]">
            Our Mission
          </h2>
          <p className="text-placeholder text-center md:text-left font-sans text-sm lg:text-base mb-0">
            We believe access to rental services should be as frictionless as
            possible for all parties involved. We are on a mission to change the
            way Africans rent assets and provide tools to make it easier and
            faster.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Mission;
