import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import MainButton from "../Buttons/MainButton";
import { PinDropIcon } from "../CustomIcons";
import { IoMdSnow, IoIosWater, IoIosWifi, IoIosBed } from "react-icons/io";
import { GiCarSeat, GiLayeredArmor, GiOfficeChair, GiSteeringWheel } from "react-icons/gi";
import { BsAndroid2, BsApple, BsFillAlarmFill, BsFillFuelPumpFill } from "react-icons/bs";
import { CgGym, CgScreen, CgSmartHomeRefrigerator } from "react-icons/cg";
import { BiSolidBath, BiSolidCctv, BiSolidCoffeeAlt } from 'react-icons/bi';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai'
import {
  MdBolt,
  MdLocalDining,
  MdOutlineCheckroom,
  MdOutlineGpsFixed,
  MdShield,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdLocalLaundryService,
  MdMicrowave
} from "react-icons/md";
import {
  FaBluetooth,
  FaFireExtinguisher,
  FaFirstAid,
  FaGamepad,
  FaParking,
  FaPaw,
  FaSwimmer,
  FaUsb,
  FaTrain,
} from "react-icons/fa";
import { Avatar, Collapse, Image } from "antd";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoginModal } from "../../slice/userSlice";
import {
  useAddFavoriteListingMutation,
  useDeleteFavoriteListingMutation,
  useGetListingByIDQuery,
  useAddAttemptedContactMutation,
} from "../../api/listing";
import { useLocation } from "react-router-dom";
import { appendImageUrl, formatAmount } from "../../helpers";
import { useLazyGetUserQuery } from "../../api/auth";
import Spinner from "../Spinner";
import { setReceiverData } from "../../slice/chatSlice";
import useMessageAlert from "../Message";
import HeaderComponent from "../Layout/HeaderComponent";
import { MyContext } from "../../context/Context";
import ReactGA from 'react-ga';

const FeatureTag = ({ Icon, name }) => (
  <div className="flex items-center rounded-3xl gap-2 p-4 bg-[#F9F9F9] text-primaryBlack/80">
    {Icon && <Icon className="w-5 h-5" />}
    <span className="text-sm font-normal leading-6 capitalize">{name}</span>
  </div>
);

export const VendorCard = ({
  firstName,
  lastName,
  accountId,
  image,
  preferredName,
  vendorId,
  setReceiver,
  phoneNumber,
  showPhoneNumber,
  listingId,
  openLogin,
  isLoggedIn
}) => (
  
  <div className="md:mt-16 flex w-full justify-center mx-auto font-poppins">
    
    <div className="bg-[#F9F9F9] rounded-3xl flex flex-col w-full gap-y-5 py-8 px-6 md:p-8 items-center justify-center">
    
      <p className="mb-[0] text-2xl text-black font-bold leading-6 capitalize">
        Vendor
      </p>
      {!isLoggedIn ? <NoAuthOverlay openLogin={openLogin} /> :
      <div>
        

      
      <div className="flex flex-col gap-3 items-center">
        <Avatar size={"large"} src={image} className="w-32 h-32" />

        <p className="mb-[0] text-xl text-black font-bold leading-6 capitalize">
          {preferredName || `${firstName} ${lastName}`}
        </p>

        <span className="mb-[0] text-base text-primaryOrange font-medium leading-6 capitalize">
          ID: {accountId}
        </span>
      </div>
      <div className="flex flex-wrap items-center justify-center w-full gap-4">
        <MainButton
          buttonType="link"
          href={`/messages/${vendorId}/${listingId}`}
          title={"Message Vendor"}
          size={"xm"}
          appendStyles={"shadow-none rounded-full px-4 text-sm font-semibold leading-6 hover:bg-orange-500 hover:transition-colors"}
          onClick={setReceiver}
        />
        <MainButton
          title={phoneNumber}
          size={"xm"}
          variant={"outline"}
          appendStyles={"shadow-none rounded-full px-4 text-sm font-semibold hover:bg-primaryOrange hover:text-white hover:transition-colors leading-6"}
          onClick={showPhoneNumber}
        />
      </div>
    </div>}
    </div>
  </div>
);

const NoAuthOverlay = ({ openLogin }) => (
  <div
    className="top-0 left-0 w-full flex items-center justify-center z-10 h-full"
    style={{
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 25%)",
    }}
  >
    <div className="desktop:py-10 px-2 font-poppins md:w-4/5 lg:w-2/4 text-center">
      <p className="font-medium text-xl text-primaryBlack text-center mb-8">
        You need to login to be able to contact the Vendor
      </p>
      <MainButton
        title={"Sign in to continue"}
        size={"sm"}
        onClick={openLogin}
      />
    </div>
  </div>
);

const RenderTag = ({ feature }) => {
  switch (feature) {
    case "Cooling":
      return <FeatureTag Icon={IoMdSnow} name={feature} />;
    case "Power":
      return <FeatureTag Icon={MdBolt} name={feature} />;
    case "Power Supply":
      return <FeatureTag Icon={MdBolt} name={feature} />;
    case "Security":
      return <FeatureTag Icon={MdShield} name={feature} />;
    case "Swimming Pool":
      return <FeatureTag Icon={FaSwimmer} name={feature} />;
    case "Pool":
      return <FeatureTag Icon={FaSwimmer} name={feature} />;
    case "Water":
      return <FeatureTag Icon={IoIosWater} name={feature} />;
    case "Games":
      return <FeatureTag Icon={FaGamepad} name={feature} />;
    case "Pets Allowed":
      return <FeatureTag Icon={FaPaw} name={feature} />;
    case "Internet & WiFi":
      return <FeatureTag Icon={IoIosWifi} name={feature} />;
    case "Internet":
      return <FeatureTag Icon={IoIosWifi} name={feature} />;
    case "WiFi":
      return <FeatureTag Icon={IoIosWifi} name={feature} />;
    case "GPS":
      return <FeatureTag Icon={MdOutlineGpsFixed} name={feature} />;
    case "Parking":
      return <FeatureTag Icon={FaParking} name={feature} />;
    case "Parking Space":
      return <FeatureTag Icon={FaParking} name={feature} />;
    case "Changing Room":
      return <FeatureTag Icon={MdOutlineCheckroom} name={feature} />;
    case "Breakfast":
      return <FeatureTag Icon={MdLocalDining} name={feature} />;
    case "Chairs":
      return <FeatureTag Icon={GiOfficeChair} name={feature} />;
    case "Armored":
      return <FeatureTag Icon={GiLayeredArmor} name={feature} />;
    case "Fueling":
      return <FeatureTag Icon={BsFillFuelPumpFill} name={feature} />;
    case "Bluetooth":
      return <FeatureTag Icon={FaBluetooth} name={feature} />;
    case "Driver":
      return <FeatureTag Icon={GiSteeringWheel} name={feature} />;
    case "Gym & Spa":
      return <FeatureTag Icon={CgGym} name={feature} />;
    case "USB Port(s)":
      return <FeatureTag Icon={FaUsb} name={feature} />;
    case "DSTV":
      return <FeatureTag Icon={CgScreen} name={feature} />;
    case "Projector & Screens":
      return <FeatureTag Icon={CgScreen} name={feature} />;
    case "Fire Extinguisher":
      return <FeatureTag Icon={FaFireExtinguisher} name={feature} />;
    case "First Aid Kit":
      return <FeatureTag Icon={FaFirstAid} name={feature} />;
    case "Microwave":
      return <FeatureTag Icon={MdMicrowave} name={feature} />;
    case "Laundry Services":
      return <FeatureTag Icon={MdLocalLaundryService} name={feature} />;
    case "Refrigerator":
      return <FeatureTag Icon={CgSmartHomeRefrigerator} name={feature} />;
    case "Android Auto":
      return <FeatureTag Icon={BsAndroid2} name={feature} />;
    case "Leather Seats":
      return <FeatureTag Icon={GiCarSeat} name={feature} />;
    case "Alarm":
      return <FeatureTag Icon={BsFillAlarmFill} name={feature} />;
    case "CCTV":
      return <FeatureTag Icon={BiSolidCctv} name={feature} />;
    case "Coffee":
      return <FeatureTag Icon={BiSolidCoffeeAlt} name={feature} />;
    case "CarPlay":
      return <FeatureTag Icon={BsApple} name={feature} />;
    case "Transport":
      return <FeatureTag Icon={FaTrain} name={feature} />;

    default:
      return <FeatureTag name={feature} />;
  }
};

function Listing() {
  const { setBreadcrumbPaths } = useContext(MyContext);
  const [showDescription, setShowDescription] = useState(true);
  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };
  
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isLoggedIn, profile } = useSelector((state) => state.user);
  const { data, isLoading, isError } = useGetListingByIDQuery(
    pathname.split("/")[2]
  );
  const listingId = pathname.split("/")[2];
  const [getUser, { data: ownerData }] = useLazyGetUserQuery();
  const [addToFavorites] = useAddFavoriteListingMutation();
  const [addAttempt] = useAddAttemptedContactMutation();
  const [removeFromFavorites] = useDeleteFavoriteListingMutation();
  const { openMessage, contextHolder } = useMessageAlert();
  const [showCaseImg, setShowcaseImg] = useState(data?.imagesLocation?.[0]);
  const { Panel } = Collapse;
  const [phoneNumber, setPhoneNumber] = useState("View Contact");
  const openLogin = () => {
    addAttempt(listingId).unwrap();
    dispatch(setShowLoginModal(true));
  };

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    className: "mt-8 nearyou",
    slidesToShow:
      data?.imagesLocation?.length > 3
        ? 3.1
        : data?.imagesLocation?.length > 2
        ? 2.9
        : data?.imagesLocation?.length > 1
        ? 1.9
        : data?.imagesLocation?.length,
    slidesToScroll: 1,
    autoPlay: false,
    responsive: [
      {
        breakpoint: 1375,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4.3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 1
        }
      }
    ]
  };

  const showPhoneNumber = () => {
    addAttempt(listingId).unwrap();
    setPhoneNumber("+" + ownerData.phoneNumber);
  };

  const setReceiver = () => {
    addAttempt(listingId).unwrap();
    dispatch(setReceiverData({ ...ownerData, title: data?.listingHeading }));
  };

  const handleFavorite = () => {
    let params = {
      userId: profile?.userId,
      listingId: data?.listingId,
    };
    if (data?.favorite) {
      removeFromFavorites(params)
        .unwrap()
        .then(() => {
          openMessage("success", "removed from favorites!");
        })
        .catch((error) => {
          openMessage(
            "error",
            error?.data?.errorMessage ||
            "Failed to remove listing from favorites"
          );
        });
    } else {
      addToFavorites(params)
        .unwrap()
        .then(() => {
          openMessage("success", "added to favorites!");
        })
        .catch((error) => {
          openMessage(
            "error",
            error?.data?.errorMessage || "Failed to add listing to favorites"
          );
        });
    }
  };

  useEffect(() => {
    if (!isLoading) {
      getUser(data?.ownerId);
    }
    setShowcaseImg(data?.imagesLocation[0]);
  }, [data, getUser, isLoading, isLoggedIn]);

  useEffect(() => {
    if (isError) {
      openMessage("error", "Failed to retrieve listing!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    ReactGA.pageview(window.location.pathname + window.location.search);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (data && !isLoading) {
      setBreadcrumbPaths([{ title: data?.category, path: `/listings?category=${data?.category}` }, { disabled: true, title: data?.listingHeading }])
    }
  }, [setBreadcrumbPaths, data, isLoading]);

  return (
    <Layout>
      <HeaderComponent
        pageTitle={"Rent " + " " +data?.listingHeading   + " " + data?.category + " " + data?.subCategory+ " in " + data?.localGovernmentArea + " " + data?.state + " Nigeria"|| "Listing Description"}
        pageDescription={
          data?.listingDescription + " " + data?.localGovernmentArea + " " + data?.state + " Nigeria"||
          "More details and description of the listing being showcased"
        }
      />
      {contextHolder}
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-5 w-full justify-center items-start gap-8 mx-auto my-14">

            <div className="md:col-span-3 w-full flex flex-col items-start mx-auto gap-y-12">
              {/* Image and Carousel */}
              <div className="flex flex-col w-full gap-y-5">
                <div className="relative w-[100%] h-full">

                  <Image
                    src={appendImageUrl(showCaseImg || data?.imagesLocation?.[0])}
                    alt={data?.listingHeading + " " + data?.subcategory + " " + data?.state + " "+ data?.imagesLocation?.[0].split('_')[data?.imagesLocation?.[0].split('_').length - 1] || "listing"}
                    className="inline-flex rounded-2xl bg-orange-100 object-cover w-[100%] h-[26.2rem] md:h-[27rem]"
                  />
                  { isLoggedIn ?
                  <div
                    onClick={handleFavorite}
                    className="flex absolute top-0 right-0 z-10 mt-3 mr-3 bg-gray-100/40 rounded-xl p-2 cursor-pointer"
                  >
                    {data?.favorite ? (
                      <AiFillHeart size={20} className='text-red-500' />
                    ) : (
                      <AiOutlineHeart size={20} className='text-white' />
                    )}
                  </div>
                  : <></>}
                </div>

                <Slider {...settings}>
                  {data?.imagesLocation?.map((img, index) => (
                    <img
                      src={appendImageUrl(img)}
                      onClick={() => setShowcaseImg(img)}
                      alt={data?.listingHeading + " " + data?.subcategory + " " + data?.state + " "+ data?.imagesLocation?.[0].split('_')[data?.imagesLocation?.[0].split('_').length - 1]  || "listing"}
                      key={index}
                      className={`rounded-lg w-[7.5rem] h-[5.5rem] mx-2 cursor-pointer object-cover ${showCaseImg === img
                        ? "border-4 border-solid border-primaryOrange bg-primaryOrange bg-opacity-60"
                        : ""
                        }`}
                    />
                  ))}
                </Slider>
              </div>

              <div className="w-full h-full flex md:hidden flex-col items-start mx-auto gap-y-3">

                {/* Title and price*/}
                <div className="flex flex-col w-full gap-4">
                  <p className="mb-[0] text-3xl text-black font-bold font-poppins leading-9">
                    {data?.listingHeading}
                  </p>
                  { !data?.deal ?
                <div>
                  <p className="mb-[0] text-2xl text-red font-bold font-poppins leading-6 capitalize">
                    ₦{formatAmount(data?.rentAmount)}
                    <span className="text-[1rem] text-black">
                      {' '} {data?.rentalPeriod}
                    </span>
                  </p>
                </div>
                : 
                <div>
                  <p className="mb-[0] text-2xl text-black-600 font-bold font-poppins leading-6 capitalize line-through">
                    ₦{formatAmount(data?.dealPrice)}
                  </p>
                  <p className="mb-[0] text-2xl text-primaryOrange font-bold font-poppins leading-6 capitalize">
                    ₦{formatAmount(data?.rentAmount)}
                    <span className="text-[1rem] text-black">
                      {' '} {data?.rentalPeriod}
                    </span>
                  </p>
                </div>
                }
                </div>

                {/* Location and Rating */}
                <div className="flex items-center w-full gap-x-6">
                  {/*
                  <p className="mb-[0] text-base text-[#878380] font-medium leading-6 flex items-center gap-2">
                    <svg className='w-4 h-4 text-primaryOrange' width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Star">
                        <path id="Star_2" d="M12.9968 10.6558L13.7375 14.7549C13.8 15.1023 13.6534 15.4539 13.3626 15.6546C13.0777 15.8629 12.6986 15.8879 12.3878 15.7213L8.69785 13.7968C8.56955 13.7284 8.42708 13.6918 8.28128 13.6876H8.0555C7.97719 13.6993 7.90054 13.7243 7.83055 13.7626L4.13976 15.6963C3.9573 15.788 3.75068 15.8204 3.54823 15.788C3.05501 15.6946 2.72592 15.2248 2.80674 14.729L3.54823 10.63C3.59738 10.3309 3.49824 10.0268 3.28246 9.81436L0.273999 6.89838C0.0223913 6.65427 -0.0650882 6.28769 0.0498848 5.95693C0.161525 5.62701 0.446458 5.38623 0.790544 5.33208L4.93124 4.73139C5.24616 4.69889 5.52276 4.50727 5.6644 4.22401L7.48897 0.48322C7.53229 0.399906 7.58811 0.323257 7.6556 0.258273L7.73058 0.199953C7.76974 0.15663 7.81472 0.120805 7.86471 0.0916451L7.95553 0.0583196L8.09716 0H8.44791C8.76117 0.0324924 9.03694 0.219948 9.18107 0.499882L11.0298 4.22401C11.1631 4.49644 11.4222 4.68556 11.7213 4.73139L15.862 5.33208C16.2119 5.38207 16.5043 5.62368 16.6202 5.95693C16.7293 6.29102 16.6352 6.6576 16.3785 6.89838L13.2626 9.84768C13.0468 10.0568 12.9477 10.3592 12.9968 10.6558Z" fill="#FF7D12" />
                      </g>
                    </svg>
                    4.7
                  </p>

                  <div className="w-px rounded-full h-5 bg-[#878380]/60"></div>
*/}
                  <p className="mb-[0] text-base text-[#878380] font-medium font-poppins leading-6 flex items-center gap-2">
                    <PinDropIcon
                      className={"w-4 h-4"}
                    />
                    {data?.localGovernmentArea}
                    {", "}
                    {data?.state}
                  </p>

                </div>

                {/* Facilities */}
                <div className="flex flex-col w-full gap-3 mt-2">
                  <p className="mb-[0] text-lg text-black font-bold leading-6 capitalize">Facilities</p>
                  <div className="flex flex-wrap items-center justify-start gap-4">

                    {data?.numBedrooms > 0 && (
                      <FeatureTag
                        Icon={IoIosBed}
                        name={`${data?.numBedrooms} ${data?.numBedrooms > 1 ? "beds" : "bed"
                          }`}
                      />
                    )}
                    {data?.numBathrooms > 0 && (
                      <FeatureTag
                        Icon={BiSolidBath}
                        name={`${data?.numBathrooms} ${data?.numBathrooms > 1 ? "baths" : "bath"
                          }`}
                      />
                    )}
                    {data?.features?.slice(0, 2).map((feature, index) => (
                      <RenderTag feature={feature} key={index} />
                    ))}

                  </div>
                </div>

              </div>

              <div className="flex flex-col w-full gap-y-12 relative">
                {/*!isLoggedIn && <NoAuthOverlay openLogin={openLogin} />*/}
                {/* Description */}
                <div className="flex flex-col w-full gap-4">
                  <p className="mb-[0] text-xl text-black font-bold font-poppins leading-6 capitalize">Description</p>
                  <span className="text-base text-textGray font-poppins leading-7 w-full">
                    {data?.listingDescription}
                  </span>
                </div>

                {/* Facilities */}
                <div className="flex flex-col w-full gap-4">
                  <p className="mb-[0] text-xl text-black font-bold leading-6 capitalize">Facilities</p>

                  <div className="flex items-start justify-start w-full">
                    <div className="grid grid-cols-2 md:grid-cols-3 w-full md:w-10/12 items-start justify-between gap-x-8 gap-y-8">

                      {data?.numBedrooms > 0 && (
                        <FeatureTag
                          Icon={IoIosBed}
                          name={`${data?.numBedrooms} ${data?.numBedrooms > 1 ? "beds" : "bed"
                            }`}
                        />
                      )}
                      {data?.numBathrooms > 0 && (
                        <FeatureTag
                          Icon={BiSolidBath}
                          name={`${data?.numBathrooms} ${data?.numBathrooms > 1 ? "baths" : "bath"
                            }`}
                        />
                      )}
                      {data?.features?.map((feature, index) => (
                        <RenderTag feature={feature} key={index} />
                      ))}

                    </div>
                  </div>

                </div>

                {/* Terms */}
                <div className="flex flex-col w-full gap-4 mt-4">

                  <div className="flex items-center justify-between font-poppins w-full cursor-pointer" onClick={toggleDescription} >
                    <p className="mb-[0] text-xl text-black font-bold font-poppins leading-6 capitalize">Terms & Conditions</p>
                    {showDescription ? (
                      <MdOutlineKeyboardArrowUp size={24} onClick={toggleDescription} />
                    ) : (
                      < MdOutlineKeyboardArrowDown size={24} onClick={toggleDescription} />
                    )}
                  </div>

                  {showDescription && (
                    <span className="text-base text-textGray font-poppins leading-7 w-full">
                      {data?.termsCondition}
                    </span>
                  )}

                </div>

              </div>

              <div className="flex md:hidden mt-6 w-full">
                {/* Vendor Card */}
                {(
                  <VendorCard
                    firstName={ownerData?.firstName}
                    lastName={ownerData?.lastName}
                    preferredName={ownerData?.preferredName}
                    accountId={ownerData?.accountId}
                    image={appendImageUrl(ownerData?.imageLink, "user")}
                    vendorId={ownerData?.userId}
                    setReceiver={setReceiver}
                    phoneNumber={phoneNumber}
                    showPhoneNumber={showPhoneNumber}
                    listingId={listingId}
                  />
                )}
              </div>

            </div>

            <div className="md:col-span-2 w-full h-full md:flex hidden flex-col items-start mx-auto gap-y-3">

              {/* Location and Rating */}
              <div className="flex items-center w-full gap-x-6">
{/*
                <p className="mb-[0] text-base text-[#878380] font-medium leading-6 flex items-center gap-2">
                  <svg className='w-4 h-4 text-primaryOrange' width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Star">
                      <path id="Star_2" d="M12.9968 10.6558L13.7375 14.7549C13.8 15.1023 13.6534 15.4539 13.3626 15.6546C13.0777 15.8629 12.6986 15.8879 12.3878 15.7213L8.69785 13.7968C8.56955 13.7284 8.42708 13.6918 8.28128 13.6876H8.0555C7.97719 13.6993 7.90054 13.7243 7.83055 13.7626L4.13976 15.6963C3.9573 15.788 3.75068 15.8204 3.54823 15.788C3.05501 15.6946 2.72592 15.2248 2.80674 14.729L3.54823 10.63C3.59738 10.3309 3.49824 10.0268 3.28246 9.81436L0.273999 6.89838C0.0223913 6.65427 -0.0650882 6.28769 0.0498848 5.95693C0.161525 5.62701 0.446458 5.38623 0.790544 5.33208L4.93124 4.73139C5.24616 4.69889 5.52276 4.50727 5.6644 4.22401L7.48897 0.48322C7.53229 0.399906 7.58811 0.323257 7.6556 0.258273L7.73058 0.199953C7.76974 0.15663 7.81472 0.120805 7.86471 0.0916451L7.95553 0.0583196L8.09716 0H8.44791C8.76117 0.0324924 9.03694 0.219948 9.18107 0.499882L11.0298 4.22401C11.1631 4.49644 11.4222 4.68556 11.7213 4.73139L15.862 5.33208C16.2119 5.38207 16.5043 5.62368 16.6202 5.95693C16.7293 6.29102 16.6352 6.6576 16.3785 6.89838L13.2626 9.84768C13.0468 10.0568 12.9477 10.3592 12.9968 10.6558Z" fill="#FF7D12" />
                    </g>
                  </svg>
                  4.7
                </p>

                <div className="w-px rounded-full h-5 bg-[#878380]/60"></div>
*/}
                <p className="mb-[0] text-base text-[#878380] font-medium font-poppins leading-6 flex items-center gap-2">
                  <PinDropIcon
                    className={"w-4 h-4"}
                  />
                  {data?.localGovernmentArea}
                  {", "}
                  {data?.state}
                </p>

              </div>

              {/* Title and price*/}
              <div className="flex flex-col w-full gap-4">
                <p className="mb-[0] text-3xl text-black font-bold font-poppins leading-9">
                  {data?.listingHeading}
                </p>
                { !data?.deal ?
                <div>
                  <p className="mb-[0] text-2xl text-primaryOrange font-bold font-poppins leading-6 capitalize">
                    ₦{formatAmount(data?.rentAmount)}
                    <span className="text-[1rem] text-black">
                      {' '} {data?.rentalPeriod}
                    </span>
                  </p>
                </div>
                : 
                <div>
                  <p className="mb-[0] text-2xl text-black-600 font-bold font-poppins leading-6 capitalize line-through">
                    ₦{formatAmount(data?.dealPrice)}
                  </p>
                  <p className="mb-[0] text-2xl text-primaryOrange font-bold font-poppins leading-6 capitalize">
                    ₦{formatAmount(data?.rentAmount)}
                    <span className="text-[1rem] text-black">
                      {' '} {data?.rentalPeriod}
                    </span>
                  </p>
                </div>
                }
              </div>

              {/* Facilities */}
              <div className="flex flex-col w-full gap-3 mt-2">
                <p className="mb-[0] text-lg text-black font-bold leading-6 capitalize">Facilities</p>
                <div className="flex flex-wrap items-center justify-start gap-4">

                  {data?.numBedrooms > 0 && (
                    <FeatureTag
                      Icon={IoIosBed}
                      name={`${data?.numBedrooms} ${data?.numBedrooms > 1 ? "beds" : "bed"
                        }`}
                    />
                  )}
                  {data?.numBathrooms > 0 && (
                    <FeatureTag
                      Icon={BiSolidBath}
                      name={`${data?.numBathrooms} ${data?.numBathrooms > 1 ? "baths" : "bath"
                        }`}
                    />
                  )}
                  {data?.features?.slice(0, 2).map((feature, index) => (
                    <RenderTag feature={feature} key={index} />
                  ))}

                </div>
              </div>

              {/* Vendor Card */}
              
              { (
                <VendorCard
                  firstName={ownerData?.firstName}
                  lastName={ownerData?.lastName}
                  preferredName={ownerData?.preferredName}
                  accountId={ownerData?.accountId}
                  image={appendImageUrl(ownerData?.imageLink, "user")}
                  vendorId={ownerData?.userId}
                  setReceiver={setReceiver}
                  phoneNumber={phoneNumber}
                  showPhoneNumber={showPhoneNumber}
                  listingId={listingId}
                  isLoggedIn={isLoggedIn}
                  openLogin={openLogin}
                />
              )}

            </div>

          </div>
        </div>
      )}
    </Layout>
  );
}

export default Listing;
