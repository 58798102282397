import React, { useRef } from "react";
import { Modal } from "antd";
import { Formik, Form } from "formik";
import * as yup from "yup";
import MainButton from "../Buttons/MainButton";
import { Link } from "react-router-dom";
import InputField from "../Form/InputField";
import { useForgotPasswordMutation } from "../../api/auth";
import { useDispatch } from "react-redux";
import useMessageAlert from "../Message";
import { setSignupInfo } from "../../slice/userSlice";

function ForgotPasswordModal({
  showLoginModal,
  visible,
  onCancel,
  showOTPModal,
}) {
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const { openMessage, contextHolder } = useMessageAlert();

  const handleLoginSubmit = async (values) => {
    let payload = {
      emailAddress: values.emailAddress,
    };

    forgotPassword(payload)
      .unwrap()
      .then(() => {
        showOTPModal();
        dispatch(setSignupInfo({ ...payload, isForgotPassword: true }));
      })
      .catch((error) => {
        openMessage(
          "error",
          error?.data?.errorMessage || "an unexpected error occured"
        );
      });
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      className={"generic-modal"}
      footer={[
        <p className="text-sm text-[#84818A] font-sans border-t border-[#F2F2F3] pt-4">
          Protected by reCAPTCHA and subject to the{" "}
          <Link
            to={"/privacy-policy"}
            className={"text-[#009580] hover:text-primaryOrange"}
          >
            Yarlo Privacy Policy
          </Link>{" "}
          and{" "}
          <Link
            to={"/terms"}
            className={"text-[#009580] hover:text-primaryOrange"}
          >
            Terms of Service.
          </Link>
        </p>,
      ]}
    >
      {contextHolder}
      <div className="py-4">
        <h1 className="text-[2rem] font-medium text-[#202020] mb-3">
          Forgot Password?
        </h1>
        <p className="text-primaryBlack font-medium font-sans text-base mb-0">
          Remember Password?{" "}
          <span
            onClick={showLoginModal}
            className="text-primaryOrange hover:text-primaryOrange/75 cursor-pointer"
            tabIndex={0}
          >
            Sign in
          </span>
        </p>
      </div>
      <Formik
        innerRef={formikRef}
        initialValues={{
          emailAddress: "",
        }}
        onSubmit={handleLoginSubmit}
        validationSchema={yup.object().shape({
          emailAddress: yup
            .string()
            .email("Please provide a valid email address")
            .required("This is a required field!"),
        })}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          handleSubmit,
          touched,
        }) => (
          <Form>
            <div className="flex flex-col w-full">
              <InputField
                name={"emailAddress"}
                inputType={"emailAddress"}
                label={"Email Address"}
                placeholder={"Email Address"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailAddress}
                error={errors.emailAddress}
                touched={touched.emailAddress}
              />

              <MainButton
                title={"Reset Password"}
                size={"md"}
                appendStyles={"font-sans font-medium mt-6"}
                onClick={handleSubmit}
                isLoading={isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ForgotPasswordModal;
