import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { v4 as uuidv4 } from "uuid";

const baseUrl = process.env.REACT_APP_BASE_URL;
const newClientId = uuidv4();
// initialize an empty api instance that we'll inject endpoints into later as needed
export const apiInstance = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.token;
      const clientId = getState().user.clientId;
      headers.set("Client-Type", "YARLO_WEB");
      headers.set("accept", "application/json");
      // pass token and clientId if set in state
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      if (clientId) {
        headers.set("Client-ID", clientId);
      } else {
        headers.set("Client-ID", newClientId);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: ["userInfo", "chatList", "chat", "adsList", "listing"],
});
