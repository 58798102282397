import React from "react";
import LazyLoadImg from "../LazyLoadImg";
import imageUrls from "../LazyLoadImg/imageUrls";

function Hero() {
  return (
    <div
      className="py-14 lg:py-20 xl:py-24 -mx-4 sm:-mx-6 md:-mx-8 xl:-mx-32 2xl:-mx-36 2xl:mx-auto px-4 sm:px-6 md:px-8 xl:px-32 2xl:px-36"
      style={{
        background: `url(${imageUrls.aboutHeroParticles})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="grid md:grid-cols-2 gap-10 md:gap-4 lg:gap-5 xl:gap-8 desktop:gap-12 max-w-7xl desktop:mx-auto">
        <div className="flex flex-col justify-center">
          <h1 className="text-primaryOrange leading-normal text-center font-sans font-bold text-[2.5rem] xl:text-[3rem]">
            Providing access to rental services across Africa
          </h1>
        </div>
        <div className="flex">
          <LazyLoadImg
            src={imageUrls.aboutHero}
            alt="providing access to rental services across Africa"
            className="w-[18.5rem] h-[17.5rem] md:w-[21.5rem] md:h-[21.5rem] lg:w-[28rem] lg:h-[24rem] xl:w-[30rem] xl:h-[27.5rem] desktop:w-[33.5rem] desktop:h-[30rem] mx-auto"
          />
        </div>
      </div>
    </div>
  );
}

export default Hero;
