import React, { useState } from "react";
import { isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { SearchIcon } from "../CustomIcons";

const SearchBar = () => {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  
  const handleChange = (e) => {
    e.preventDefault();
    setQuery(e.target.value);
  };

  const searchKeyword = () => {
    if (!isEmpty(query)) {
      navigate(`/listings?category=none&keyword=${query}`);
    }
  }

  return (
    <form
      className="flex items-center search-bar w-full"
      onSubmit={(e) => {
        e.preventDefault();
        searchKeyword();
      }}
    >
      <label htmlFor="search-listing" className="sr-only">
        Search
      </label>
      <div className="relative w-full">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <SearchIcon className="w-4 h-4 text-placeholder" />
        </div>
        <input
          type="text"
          name="search-listing"
          className="border border-[#DEDEDE] placeholder:text-[#1F1A16] text-sm font-poppins rounded-full focus:outline focus:outline-2 focus:outline-primaryOrange/50 block w-full pl-10 px-2 py-3"
          placeholder="Search anything"
          required
          onChange={handleChange}
        />
        <Link
          to={`/listings?category=none&keyword=${query}`}
          type="submit"
          className="absolute right-0 bottom-0 text-white focus:ring-4 focus:outline-none font-medium font-poppins rounded-full bg-[#1F1A16] text-sm px-6 py-3 border border-[#1F1A16]"
        >
          {/* <button className="absolute right-0 bottom-0 text-white focus:ring-4 focus:outline-none font-semibold font-poppins rounded-r bg-primaryBlack text-sm px-5 py-2.5 border border-primaryBlack"> */}
          Search
          {/* </button> */}
        </Link>
      </div>
    </form>
  );
};
export default SearchBar;
