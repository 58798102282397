import React from "react";
import Hero from "./Hero";
import Layout from "../Layout";
import DownloadApp from "./DownloadApp";
import FAQs from "./FAQs";
import HowItWorks from "./HowItWorks";
import Mission from "./Mission";
import HeaderComponent from "../Layout/HeaderComponent";

function AboutUs() {
  return (
    <Layout>
      <HeaderComponent
        pageTitle={"Who we are | About Yarlo"}
        pageDescription={
          "We are on a mission to change the way Africans rent assets and provide tools to make it easier and faster."
        }
      />
      <Hero />
      <Mission />
      <HowItWorks />
      <FAQs />
      <DownloadApp />
    </Layout>
  );
}

export default AboutUs;
