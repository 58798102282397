import React from "react";
import { Collapse } from "antd";
import { ArrowUpIcon } from "../CustomIcons";
import LazyLoadImg from "../LazyLoadImg";
import imageUrls from "../LazyLoadImg/imageUrls";

const { Panel } = Collapse;

function FAQs() {
  return (
    <div
      id="faq"
      className="bg-[#1F1A16]/[0.02] py-14 lg:py-20 xl:py-24 -mx-4 sm:-mx-6 md:-mx-8 xl:-mx-32 2xl:-mx-36 2xl:mx-auto px-4 sm:px-6 md:px-8 xl:px-32 2xl:px-36"
    >
      <h2 className="text-primaryOrange leading-normal text-center font-sans font-bold text-[2rem] lg:text-[2.125rem] xl:text-[2.5rem] mb-8 desktop:mb-10">
        Frequently Asked Questions
      </h2>
      <p className="text-placeholder text-center font-sans text-sm md:text-base lg:text-lg mb-0 lg:w-[90%] xl:w-[85%] lg:mx-auto">
        We have tried to address the most common questions customers have asked
        us, please reach out to us directly if you do not find your question
        listed below
      </p>

      <div className="text-center my-10">
        <LazyLoadImg
          src={imageUrls.faq}
          alt="yarlo frequently asked questions character illustration"
          className="w-[17rem] h-[15rem] md:w-[19rem] md:h-[16.75rem] lg:w-[21rem] lg:h-[18.5rem]"
        />
      </div>

      <Collapse
        accordion
        expandIconPosition="end"
        bordered={false}
        className="bg-transparent py-4 faq-container"
        expandIcon={({ isActive }) => (
          <ArrowUpIcon
            className={`w-5 h-5 transition-transform ${
              isActive ? "text-primaryOrange" : "text-placeholder rotate-180"
            }`}
          />
        )}
      >
        <Panel
          header="How can I change the personal details I use for Yarlo?"
          key="1"
        >
          <p className="font-normal text-sm md:text-base text-[#1F1A16] font-sans">
            If you want to change your details please get in touch with one of
            the Yarlo support team on{" "}
            <a
              href="mailto:support@yarlo.co"
              className="text-primaryOrange underline hover:text-[#009580]"
            >
              support@yarlo.co
            </a>
          </p>
        </Panel>
        <Panel header="How do I receive the rental?" key="2">
          <p className="font-normal text-sm md:text-base text-[#1F1A16] font-sans">
            It’s best to contact the owner for pickup or delivery services as we
            currently do not offer delivery for rental services.
          </p>
        </Panel>
        <Panel header="How can I trust owners/renters?" key="3">
          <p className="font-normal text-sm md:text-base text-[#1F1A16] font-sans">
            Every user has to accept the Yarlo’s Terms of Service when joining
            our platform. If you have any additional documents you would like
            the renter to know (for example, T&C), you can include them
            under the terms and conditions before you submit your assset for
            approval. Both owner and renter must decide whether or not they
            agree with terms and condition before proceeding. Please also keep
            in mind that any additional terms should not contradict Yarlo’s
            Terms of Service.
          </p>
        </Panel>
        <Panel
          header="What should I do if I have issues with the property mid-rental?"
          key="4"
        >
          <p className="font-normal text-sm md:text-base text-[#1F1A16] font-sans">
            If you are not satisfied with an ongoing rental service, we have two
            options available to you. First, we always recommend trying to
            resolve the issue directly with the owner/renter. However, if you
            ever feel like you need a little help, kindly send an email to{" "}
            <a
              href="mailto:support@yarlo.co"
              className="text-primaryOrange underline hover:text-[#009580]"
            >
              support@yarlo.co
            </a>
          </p>
        </Panel>
        <Panel header="How do vendors get paid?" key="5">
          <p className="font-normal text-sm md:text-base text-[#1F1A16] font-sans">
            The Yarlo platform is open for both customers and vendors to review; and agree on suitable payment terms.
            </p><p>
            We ask both customers and vendors review Yarlo's <a href="/terms_of_service#indemnity" className="text-primaryOrange underline hover:text-[#009580] font-medium">limitation on liabilities </a> as covered under the <a href="/terms_of_service" className="text-primaryOrange underline hover:text-[#009580] font-medium">"terms & condition"</a> section of the website and mobile application before offering/receiving a rental service.
          </p>
        </Panel>
      </Collapse>
      <p className="font-normal text-sm md:text-base lg:text-lg text-placeholder text-center font-sans">
        Do you still have any more questions?{" "}
        <a
          href="/contact_us"
          className="text-primaryOrange underline hover:text-[#009580] font-medium"
        >
          Contact Us
        </a>
      </p>
    </div>
  );
}

export default FAQs;
