import React, { useRef } from "react";
import Layout from "../Layout";
import { PinDropIcon } from "../CustomIcons";
import { formatAmount } from "../../helpers";
import { VendorCard } from "../Listing";
import MainButton from "../Buttons/MainButton";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import CustomDatePicker from "../Form/DatePicker";
import validationSchema from "../../helpers/validationSchema";
import SelectField from "../Form/SelectField";
import imageUrls from "../LazyLoadImg/imageUrls";

function BookListing() {
  const formikRef = useRef(null);

  const proceed = () => {
    formikRef.current.handleSubmit();
  };

  return (
    <Layout>
      <h1 className="mt-10 mb-5 font-poppins text-[1.375rem] md:text-[1.5rem] lg:text-[1.75rem] xl:text-[2rem] font-semibold">
        Summary
      </h1>
      <div className="my-5 grid grid-cols-4 lg:grid-cols-6 gap-10 lg:gap-5 xl:gap-10">
        <div className="col-span-4">
          <div className="rounded-2xl bg-white p-3 flex flex-col md:flex-row">
            <img
              src={imageUrls.furniture}
              alt="ad"
              className="h-[180px] md:h-[188px] xl:h-[200px] desktop:h-[240px] rounded-[10px] w-full max-w-[360px] object-cover"
            />
            <div className="mt-4 md:mt-0 md:ml-4 desktop:ml-6">
              <p className="font-poppins text-lg lg:text-2xl font-medium mt-3 mb-3 lg:mb-6 text-headingBlack">
                Hostel Apartment
              </p>
              <div className="flex items-center mb-2 md:mb-3 lg:mb-5">
                <PinDropIcon className="w-3 h-3.5 md:w-4 md:h-5" />
                <p className="font-poppins text-base ml-2 text-textGray mb-0">
                  Lagos Mainland
                </p>
              </div>
              <p className="text-primaryOrange text-lg lg:text-xl font-bold">
                ₦{formatAmount(1000000)}
              </p>
            </div>
          </div>
          <div className="rounded-2xl bg-white p-4 xl:p-10 mt-5 desktop:mt-10">
            <Formik
              initialValues={{
                startDate: null,
                endDate: null,
                paymentMethod: "",
              }}
              innerRef={formikRef}
              onSubmit={(values) => {
                //console.log("form values", values);
              }}
              validationSchema={yup
                .object()
                .shape(validationSchema.bookListing)}
            >
              {({ values, setFieldValue, errors }) => (
                <Form>
                  <div className="flex flex-col w-full relative">
                    <div className="grid sm:grid-cols-2 sm:gap-4">
                      <Field
                        id="startDate"
                        type="date"
                        component={CustomDatePicker}
                        hasLabel
                        label="Start Date"
                        value={values.startDate}
                        format={"DD/MM/YYYY"}
                        error={errors.startDate}
                      />
                      <Field
                        id="endDate"
                        type="date"
                        component={CustomDatePicker}
                        hasLabel
                        label="End Date"
                        value={values.endDate}
                        format={"DD/MM/YYYY"}
                        error={errors.endDate}
                      />
                    </div>
                    <SelectField
                      id="paymentMethod"
                      hasLabel
                      label="Payment Method"
                      onChange={setFieldValue}
                      value={values.paymentMethod}
                      error={errors.paymentMethod}
                      options={[
                        {
                          value: "Debit/Credit Card",
                          label: "Debit/Credit Card",
                        },
                        { value: "Bank Transfer", label: "Bank Transfer" },
                      ]}
                    />
                  </div>
                </Form>
              )}
            </Formik>

            <div className="font-poppins mt-5">
              <h3 className="text-lg text-headingBlack mb-4">Details</h3>
              <div>
                <div className="my-3 flex items-center justify-between">
                  <p className="text-headingBlack text-base mb-0">
                    Property Price
                  </p>
                  <p className="text-primaryOrange font-medium text-base mb-0">
                    ₦230,000
                  </p>
                </div>
                <div className="my-3 flex items-center justify-between">
                  <p className="text-headingBlack text-base mb-0">
                    Service Charge
                  </p>
                  <p className="text-primaryOrange font-medium text-base mb-0">
                    ₦50,000
                  </p>
                </div>
                <div className="my-3 flex items-center justify-between">
                  <p className="text-headingBlack text-base mb-0">
                    Caution Fee
                  </p>
                  <p className="text-primaryOrange font-medium text-base mb-0">
                    ₦20,000
                  </p>
                </div>
                <div className="my-3 flex items-center justify-between border-y border-borderGray py-4">
                  <p className="text-headingBlack font-medium text-lg mb-0">
                    Total
                  </p>
                  <p className="text-primaryOrange font-semibold text-lg mb-0">
                    ₦300,000
                  </p>
                </div>
              </div>
              <div className="text-center mt-8">
                <MainButton
                  title={"Proceed to Payment"}
                  size={"lg"}
                  appendStyles={"font-poppins max-w-full"}
                  onClick={proceed}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4 lg:col-span-2">
          <VendorCard
            firstName={"Precious"}
            lastName={"Brown"}
            accountId={"0099882"}
            image={imageUrls.defaultDp}
          />
        </div>
      </div>
    </Layout>
  );
}

export default BookListing;
