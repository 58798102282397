import React, { useRef } from "react";
import { Modal } from "antd";
import { Formik, Form } from "formik";
import * as yup from "yup";
import MainButton from "../Buttons/MainButton";
import { Link } from "react-router-dom";
import InputField from "../Form/InputField";
import { useLoginMutation, useLazyGetUserQuery, useFacebookSigninMutation, useGoogleSigninMutation } from "../../api/auth";
import { useDispatch } from "react-redux";
import { loginUser, getUserSuccess } from "../../slice/userSlice";
import validationSchema from "../../helpers/validationSchema";
import useMessageAlert from "../Message";
import { v4 as uuidv4 } from "uuid";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import "./modal.css";
import { FaFacebookF } from "react-icons/fa";
function LoginModal({
  showSignupModal,
  visible,
  onCancel,
  showForgotPasswordModal,
}) {
  const [authenticateUser, { isLoading: authLoading }] = useLoginMutation();
  const [getUser, { isLoading: getUserLoading }] = useLazyGetUserQuery();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const { openMessage, contextHolder } = useMessageAlert();
  const clientId = uuidv4();
  const [googleSignIn, { isLoading: googleSigninLoading }] = useGoogleSigninMutation(); 
  const [facebookSignIn, { isLoading: faceSigninLoading }] = useFacebookSigninMutation(); 

  const handleLoginSubmit = async (values) => {
    let payload = {
      emailAddress: values.email,
      currentPassword: values.password,
    };

    try {
      const res = await authenticateUser(payload).unwrap();
      dispatch(loginUser({ token: res.token, clientId }));
      openMessage("success", "You have signed in successfully");
      formikRef.current.resetForm();
      const userData = await getUser(res?.userId).unwrap();
      dispatch(getUserSuccess(userData));
    } catch (error) {
      openMessage(
        "error",
        error?.data?.errorMessage || "an unexpected error occured"
      );
    }
  };

  const retrieveGoogleSignIn = async (response) => {
    let requestBody = {
      accessToken: response.access_token,
    };
    try{ 
      const res = await googleSignIn(requestBody).unwrap();
      dispatch(loginUser({ token: res.token, clientId }));
      openMessage("success", "You have signed in successfully");
      formikRef.current.resetForm();
      const userData = await getUser(res?.userId).unwrap();
      dispatch(getUserSuccess(userData));
    } catch(error) {
          openMessage(
            "error",
            error?.data?.errorMessage || "an unexpected error occured"
          );
      }
  };

  const retrieveFacebookSignin = async (response) => {
    let requestBody = {
      userId: response.userID,
      firstName: response.name.substring(0, response.name.indexOf(' ')),
      lastName: response.name.substring(response.name.indexOf(' ') + 1),
      emailAddress: response.email,
      accessToken: response.accessToken, 
      accountId: response.id,
    };
    try{ 
      const res = await facebookSignIn(requestBody).unwrap();
      dispatch(loginUser({ token: res.token, clientId }));
      openMessage("success", "You have signed in successfully");
      formikRef.current.resetForm();
      const userData = await getUser(res?.userId).unwrap();
      dispatch(getUserSuccess(userData));
    } catch(error) {
          openMessage(
            "error",
            error?.data?.errorMessage || "an unexpected error occured"
          );
      }
  };
  const errorMessage = (error) => {
      console.log(error);
  };

  const login = useGoogleLogin({
    scope: "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.birthday.read",
    onSuccess: tokenResponse => retrieveGoogleSignIn(tokenResponse),
  });

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      className={"generic-modal"}
      footer={[
        <p className="text-sm text-[#84818A] font-poppins border-t border-[#F2F2F3] pt-4">
          Protected by reCAPTCHA and subject to the{" "}
          <Link to={"/privacy-policy"} className={"text-[#009580] hover:text-primaryOrange"}>
            Yarlo Privacy Policy
          </Link>{" "}
          and{" "}
          <Link to={"/terms_of_service"} className={"text-[#009580] hover:text-primaryOrange"}>
            Terms of Service.
          </Link>
        </p>,
      ]}
    >
      {contextHolder}
      <div className="py-4">
        <h1 className="text-[2rem] font-medium text-[#202020] mb-3">Sign in</h1>
        <p className="text-primaryBlack font-medium font-poppins text-base mb-0">
          New user?{" "}
          <span
            onClick={showSignupModal}
            className="text-primaryOrange hover:text-primaryOrange/75 cursor-pointer"
            tabIndex={0}
          >
            Create an account
          </span>
        </p>
      </div>
      <Formik
        innerRef={formikRef}
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={handleLoginSubmit}
        validationSchema={yup.object().shape(validationSchema.login)}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          handleSubmit,
          touched,
        }) => (
          <Form>
            <div className="flex flex-col w-full">
              <InputField
                name={"email"}
                inputType={"email"}
                label={"Email Address"}
                placeholder={"Email Address"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={errors.email}
                touched={touched.email}
              />
              <InputField
                name={"password"}
                inputType={"password"}
                label={"Password"}
                placeholder={"Password"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={errors.password}
                touched={touched.password}
              />
              <div className="flex items-center justify-between my-6">
                <span
                  onClick={showForgotPasswordModal}
                  className="text-[#47464A] text-base block cursor-pointer font-sans hover:text-primaryOrange"
                  tabIndex={0}
                >
                  Forgot password?
                </span>
                <MainButton
                  title={"Sign in"}
                  size={"md"}
                  appendStyles={"font-poppins font-medium"}
                  onClick={handleSubmit}
                  isLoading={authLoading || getUserLoading}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="container-text">
        <div className="border-text"/>
          <span className="content-text">or sign in with</span>
        <div className="border-text"/>
      </div>
      <div className="flex flex-row space-x-10">
        <GoogleLogin onSuccess={login} onError={errorMessage} />
        <FacebookLogin
          appId="683998559854271"
          autoLoad={false}
          fields="name,email,picture"
          callback={retrieveFacebookSignin} 
          render={renderProps => (
            <button className="flex flex-row login-facebook-text" onClick={renderProps.onClick}><span><FaFacebookF className="text-sm facebook-icon" /> <span className="px-9">Sign in with Facebook</span></span></button>
          )}
        />
      </div>
    </Modal>
  );
}

export default LoginModal;
