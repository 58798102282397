import React from "react";

export default function TitleHead({ title }) {
  return (
    <div className="text-left pb-7 pl-5">
      <h4 className="text-xl md:text-xl lg:text-2xl mb-0 font-bold text-[primaryBlack] font-sans">
        {title}
      </h4>
    </div>
  );
}
