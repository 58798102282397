import { apiInstance } from "./";

export const listingApi = apiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getSpecialDeals: builder.query({
      query: (params) => ({
        url: "/api/listing/special-deals",
        method: "GET",
        params,
      }),
    }),
    getBrowsingHistory: builder.query({
      query: (userId) => `api/listing/history/`+ encodeURI(userId),
    }),
    getFavorite: builder.query({
      query: (userId) => `api/listing/favorite/`+ encodeURI(userId),
    }),
    getRecommended: builder.query({
      query: (params) => ({
        url: "api/listing/recommended",
        method: "GET",
        params,
      }),
    }),
    getTrending: builder.query({
      query: (params) => ({
        url: "/api/listing/trending",
        method: "GET",
        params,
      }),
    }),
    getListingByCategory: builder.query({
      query: (params) => ({
        url: "/api/listing/filter",
        method: "GET",
        params,
      }),
    }),
    getListingByID: builder.query({
      query: (id) => ({
        url: `/api/listing/listingId/${id}`,
        method: "GET",
      }),
      providesTags: ["listing"],
    }),
    addFavoriteListing: builder.mutation({
      query: ({ userId, listingId }) => ({
        url: `/api/listing/favorite?userId=${userId}&listingId=${listingId}`,
        method: "POST",
      }),
      invalidatesTags: ["listing"],
    }),
    deleteFavoriteListing: builder.mutation({
      query: ({ userId, listingId }) => ({
        url: `/api/listing/favorite?userId=${userId}&listingId=${listingId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["listing"],
    }),
    postListing: builder.mutation({
      query: ({ userId, patch }) => ({
        url: `/api/listing/userId/`+ encodeURI(userId),
        method: "POST",
        body: patch,
        redirect: "follow",
      }),
      invalidatesTags: ["adsList"],
    }),
    updateListing: builder.mutation({
      query: ({ userId, patch }) => ({
        url: `/api/listing/userId/`+ encodeURI(userId),
        method: "PUT",
        body: patch,
        redirect: "follow",
      }),
      invalidatesTags: ["adsList","listing"],
    }),
    addListingImages: builder.mutation({
      query: ({ listingId, patch, imageNumber }) => ({
        url: `/api/listing/image/${listingId}?imageNum=${imageNumber}`,
        method: "PUT",
        body: patch,
        redirect: "follow",
      }),
      invalidatesTags: ["adsList"],
    }),
    getUserListings: builder.query({
      query: (userId) => ({
        url: `/api/listing/userId/`+ encodeURI(userId),
        method: "GET",
      }),
      providesTags: ["adsList"],
    }),
    archiveListing: builder.mutation({
      query: ({ listingId, userId }) => ({
        url: `/api/listing/archive?userId=${userId}&listingId=${listingId}`,
        method: "PUT",
        redirect: "follow",
      }),
      invalidatesTags: ["adsList"],
    }),
    deleteListing: builder.mutation({
      query: ({ listingId, userId }) => ({
        url: `/api/listing/delete?userId=${userId}&listingId=${listingId}`,
        method: "DELETE",
        redirect: "follow",
      }),
      invalidatesTags: ["adsList"],
    }),
    deleteListingImage: builder.mutation({
      query: ({ listingId, userId, locations }) => ({
        url: `/api/listing/image?userId=${userId}&listingId=${listingId}&locations=${locations}`,
        method: "DELETE",
        redirect: "follow",
      }),
      invalidatesTags: ["adsList"],
    }),
    searchListing: builder.query({
      query: (params ) => ({
        url: `/api/listing/search`,
        method: "GET",
        params
      }),
    }),
    addSpecialDealListing: builder.mutation({
      query: (params ) => ({
        url: `/api/listing/special-deal`,
        method: "PUT",
        body: params,
      }),
      invalidatesTags: ["adsList"],
    }),
    addPaidListing: builder.mutation({
      query: ({userId, listingId }) => ({
        url: `/api/listing/paid?listingId=${listingId}&userId=${userId}`,
        method: "PUT",
        redirect: "follow",
      }),
      invalidatesTags: ["adsList"],
    }),
    addAttemptedContact: builder.mutation({
      query: (listingId) => ({
        url: `/api/listing/attempt-contact?listingId=${listingId}`,
        method: "PUT",
        redirect: "follow",
      }),
    }),
  }),
});

export const {
  useLazyGetSpecialDealsQuery,
  useGetSpecialDealsQuery,
  useLazyGetBrowsingHistoryQuery,
  useGetBrowsingHistoryQuery,
  useGetFavoriteQuery,
  useLazyGetFavoriteQuery,
  useGetListingByCategoryQuery,
  useLazyGetListingByCategoryQuery,
  useGetRecommendedQuery,
  useLazyGetRecommendedQuery,
  useGetTrendingQuery,
  useLazyGetTrendingQuery,
  useGetListingByIDQuery,
  useLazyGetListingByIDQuery,
  usePostListingMutation,
  useUpdateListingMutation,
  useAddListingImagesMutation,
  useGetUserListingsQuery,
  useLazyGetUserListingsQuery,
  useDeleteListingMutation,
  useArchiveListingMutation,
  useAddFavoriteListingMutation,
  useDeleteFavoriteListingMutation,
  useLazySearchListingQuery,
  useDeleteListingImageMutation,
  useAddSpecialDealListingMutation,
  useAddPaidListingMutation,
  useAddAttemptedContactMutation,
} = listingApi;
