import React, { useRef } from "react";
import { Modal } from "antd";
import { Formik, Form } from "formik";
import * as yup from "yup";
import MainButton from "../Buttons/MainButton";
import { Link } from "react-router-dom";
import InputField from "../Form/InputField";
import { useResetPasswordMutation } from "../../api/auth";
import { useSelector } from "react-redux";
import validationSchema from "../../helpers/validationSchema";
import useMessageAlert from "../Message";

function ResetPasswordModal({ visible, onCancel, showLoginModal }) {
  const { signupInfo } = useSelector((state) => state.user);
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const formikRef = useRef(null);
  const { openMessage, contextHolder } = useMessageAlert();

  const handleSubmit = async (values) => {
    let payload = {
      emailAddress: signupInfo?.emailAddress,
      newPassword: values.password,
      otpValue: signupInfo?.otpValue,
    };
    resetPassword(payload)
      .unwrap()
      .then((res) => {
        openMessage(
          "sucess",
          "Password reset successful, login with your new password"
        );
        showLoginModal();
      })
      .catch((error) => {
        openMessage(
          "error",
          error?.data?.errorMessage || "an unexpected error occured"
        );
      });
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      className={"generic-modal"}
      footer={[
        <p className="text-sm text-[#84818A] font-sans border-t border-[#F2F2F3] pt-4">
          Protected by reCAPTCHA and subject to the{" "}
          <Link to={"/"} className={"text-[#009580] hover:text-primaryOrange"}>
            Yarlo Privacy Policy
          </Link>{" "}
          and{" "}
          <Link to={"/"} className={"text-[#009580] hover:text-primaryOrange"}>
            Terms of Service.
          </Link>
        </p>,
      ]}
    >
      {contextHolder}
      <div className="py-4">
        <h1 className="text-[2rem] font-medium text-[#202020] mb-3">
          Reset Password
        </h1>
      </div>
      <Formik
        innerRef={formikRef}
        initialValues={{
          password: "",
          newPassword: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={yup.object().shape(validationSchema.resetPassword)}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          handleSubmit,
          touched,
        }) => (
          <Form>
            <div className="flex flex-col w-full">
              <InputField
                name={"password"}
                inputType={"password"}
                label={"New Password"}
                placeholder={"New Password"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={errors.password}
                touched={touched.password}
              />

              <InputField
                name={"newPassword"}
                inputType={"password"}
                label={"Confirm Password"}
                placeholder={"Confirm Password"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword}
                error={errors.newPassword}
                touched={touched.newPassword}
              />
              <MainButton
                title={"Continue"}
                size={"md"}
                appendStyles={"font-sans font-medium mt-6"}
                onClick={handleSubmit}
                isLoading={isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ResetPasswordModal;
