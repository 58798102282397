import React from "react";
import Layout from "../Layout";
import HeaderComponent from "../Layout/HeaderComponent";

export default function Index() {
  return (
    <Layout>
      <HeaderComponent
        pageTitle={"Privacy Policy"}
        pageDescription={"View Privacy policy"}
      />
      <div className="container font-poppins">
        <div className="my-10">
          <h2 className="header-text text-2xl">Privacy Policy</h2>
        </div>

        <div className=" mb-20" id="introduction">
          <h4 className="header-text">1. INTRODUCTION</h4>
          <p>
            Protecting your data, privacy and personal information is very
            important to Yarlo Global Limited ("<b>Yarlo</b>", "<b>us</b>", "
            <b>our</b>" or "<b>we</b>"). It is vitally important to us that our
            customers feel secure when using the Services.
            <br />
            <br />
            This policy (together with our terms of use at{" "}
            <a href="https://www.yarlo.co/terms_of_service.html">
              terms of service
            </a>{" "}
            and any other documents referred to in it), sets out the basis on
            which any personal data we collect from you, or that you provide to
            us, will be processed by Yarlo. Please read this privacy policy
            carefully to understand the types of information we collect from
            you, how we use that information, the circumstances under which we
            will share it with third parties, and your rights in relation to the
            personal data you provide to us.
            <br />
            <br />
            When visiting Yarlo's website at{" "}
            <a href="https://www.yarlo.co">https://www.yarlo.co</a> (our
            "Website"), using our application:
            <br />
            <br />
            "Yarlo" (our "<b>App</b>") or using any of the services offered via
            the Website or the App (the "<b>Services</b>"), you will be asked to
            indicate your acknowledgment of, and where applicable your consent
            to, the practices described in this policy.
            <br />
            <br />
            Our Website contains links to third party websites. If you follow a
            link to any of those third party websites, please note that they
            have their own privacy policies and that we do not accept any
            responsibility or liability for their policies or processing of your
            personal information. Please check these policies before you submit
            any personal information to such third-party websites.
          </p>
        </div>
        <div className=" mb-20">
          <h4 className="header-text">2. INFORMATION WE MAY COLLECT</h4>
          <p>
            We may collect and process the following data about you:
            <br />
            <br />
            By continuing to use our Platform, you are agreeing to our use of
            cookies.
            <br />
            <br />
            <b>Information that you provide to us</b>. You will be asked to
            provide us with your information when you:
          </p>
          <ul>
            <li>
              fill in forms on our Website or App, or correspond with us by
              phone, email or otherwise;
            </li>
            <li>
              register to use our Services, subscribe to our newsletter,
              promotional emails or other marketing materials;
            </li>
            <li>use the Services;</li>
            <li>report a problem with our Services; or</li>
            <li>
              complete any surveys we ask you to fill in that we use for
              research purposes (although you do not have to respond to these if
              you do not want to).
            </li>
          </ul>
          <p>
            The information you will be asked to provide to us for these
            purposes will include your name, address, Addresses, date of birth,
            e-mail address, phone number, valid ID (NIN,
            international Passport, Driver's License, Voters Card), personal
            description and photograph, or further information required to verify your identity. We may also
            request rent affordability assessment,
            move-to and move-from addresses, moving dates, tenancy details, and
            other data required for the provision of those services (including
            data relating to other occupants or household members).
            <br />
            <br />
            <b>Information we collect about you</b>. With regard to each of your
            visits to our Website or our App we may automatically collect the
            following information; however, this information cannot be used to
            identify you:
          </p>
          <ul>
            <li>
              device-specific information, such as your hardware model,
              operating system version, unique device identifiers, and mobile
              network information;
            </li>
            <li>
              technical information about your computer, including where
              available, your IP address, operating system and browser type, for
              system administration and analytical purposes;
            </li>
            <li>
              details of your visits to our Website and App, including the full
              Uniform Resource Locators (URL) clickstream to, through and from
              our Website and App (including date and time), length of visits to
              certain pages, and page interaction information (such as
              scrolling, clicks, and mouse-overs); and
            </li>
            <li>
              information showing us from which app store you downloaded our
              App.
            </li>
            <li>information showing us your device location</li>
            <li>content created by you e.g. property details, images</li>
            <li>messages you send and receive</li>
          </ul>
          <p>
            <b>Information we receive from other sources</b>. When using our
            Services, we will be in contact with third parties who may provide
            us with certain information about you in order to enable your use of
            the Services.
            <br />
            <br />
            If when using our Services you input any personal data of a third
            party, you must have obtained clear permission from the individuals
            whose data you provide us with before sharing that data with us.
            <br />
            <br />
            For the avoidance of any doubt, any reference in this privacy policy
            to your data shall include data about other individuals that you
            have provided us with.
          </p>
        </div>
        <div className="mb-20" id="how-we-use-data">
          <h4 className="header-text">
            3. HOW WE USE YOUR INFORMATION AND JUSTIFICATION OF USE
          </h4>
          <p>
            Use of personal information under data protection laws must be
            justified under one of a number of legal "grounds" and we are
            required to set out the ground in respect of each use of your
            personal data in this policy. These are the principal grounds that
            justify our use of your information:
          </p>
          <ul>
            <li>
              <b>Consent</b>: where you have consented to our use of your
              information (you are providing explicit, informed, freely given
              consent, in relation to any such use and may withdraw your consent
              in the circumstance detailed below by notifying us);
            </li>
            <li>
              <b>Contract performance</b>: where your information is necessary
              to enter into or perform our contract with you;
            </li>
            <li>
              <b>Legal obligation</b>: where we need to use your information to
              comply with our legal obligations;
            </li>
            <li>
              <b>Legitimate interests</b>: where we use your information to
              achieve a legitimate interest and our reasons for using it
              outweigh any prejudice to your data protection rights; and
            </li>
            <li>
              <b>Legal claims</b>: where your information if necessary for us to
              defend, prosecute or make a claim against you or a third party.
            </li>
          </ul>
          <p>
            We use information held about you (and information about others that
            you have provided us with) in the following ways:
          </p>
        </div>
        <div className="mb-20">
          <h4 className="header-text">4. APPLICABLE TO ALL USERS</h4>
          <p>
            Type of information collected: <b>Email address</b>, <b>name</b>.
          </p>
          <ul>
            <li>
              <b>Use of information</b>: To provide you with access to our
              Website, App and any other information which you request from us,
              and to use our Services.
            </li>
            <li>
              <b>Justification</b>: Contract performance.
            </li>
          </ul>
          <p>
            Type of information collected: <b>Email address</b>, <b>name</b>.
          </p>
          <ul>
            <li>
              <b>Use of information</b>: For marketing products and services
              that we believe will be of interest to you.
            </li>
            <li>
              <b>Justification</b>: Legitimate interest (for marketing our own
              similar products and services and any re-engagement campaigns).
              Consent (for marketing unrelated products or services or products
              or services of third parties).
            </li>
          </ul>
          <p>
            Type of information collected: <b>Email address</b>.
          </p>
          <ul>
            <li>
              <b>Use of information</b>: To notify you about changes to our
              Services.
            </li>
            <li>
              <b>Justification</b>: Legitimate interests (to update our Services
              from time to time).
            </li>
          </ul>
        </div>
        <div className="mb-20" id="marketing">
          <h4 className="header-text">5. MARKETING</h4>
          <p>
            We may use information for marketing products and services to you in
            the following ways:
          </p>
          <ol type="a">
            <li>
              Newsletters and marketing emails relating to our own similar
              services and products. Where required by law, we will ask for your
              consent at the time we collect your data to conduct any of these
              types of marketing.
              <br />
              <br />
              <b>Justification</b>: Legitimate interest (to market our products
              and services - you have the right to unsubscribe at any time)
            </li>
            <li>
              To send you details about unrelated services or products or
              special offers and discounts which are being provided by our
              selected business partners. Where required by law, we will ask
              your consent at the time we collect your data to conduct any of
              these types of marketing.
              <br />
              <br />
              <b>Justification</b>: Consent (which can be withdrawn at any time)
            </li>
          </ol>
          <p>
            We will provide an option to unsubscribe or opt-out of further
            communication on any electronic marketing communication sent to you
            or you may opt out by contacting us.
          </p>
        </div>
        <div className="mb-20">
          <h4 className="header-text">
            6. WHERE WE STORE YOUR PERSONAL INFORMATION
          </h4>
          <p>
            The personal data that we collect from you (including email
            addresses that form part of our prospective marketing database) is
            processed in Nigeria and stored on Amazon Web Services (USA) Cloud
            Servers. This data may however be processed by staff operating
            outside of Nigeria who work for us or for one of our business
            partners or service providers. A full list of our third party
            sub-processors and details of their privacy policies can be
            requested. Countries outside Nigeria may not provide the same level
            of adequate protection for the rights and freedoms of data subjects
            in relation to the processing of personal data. In countries which
            do not provide appropriate safeguards, we shall transfer your data
            subject only to your consent except for transfers to and from: (i)
            any country with a valid adequacy decision from the Nigerian
            Commission; or (ii) any organisation which ensures an adequate level
            of protection in accordance with applicable data protection laws.
            <br />
            <br />
            Your passwords are stored on Amazon&apos;s servers in encrypted
            form. We do not disclose your account details. It is your
            responsibility to keep your password secure. Unfortunately, the
            transmission of information via the internet is not completely
            secure. Although Yarlo will do its best to protect your personal
            data, we cannot guarantee the security of your data transmitted to
            our Website, any transmission is at your own risk. Once we have
            received your information, we will use strict procedures and
            security features to try to prevent any unauthorised access.
            <br />
            <br />
            Please contact us if you would like further details on the specific
            safeguards applied to the export of your personal information
            outside Nigeria.
          </p>
        </div>
        <div className="mb-20" id="disclosure">
          <h4 className="header-text">7. DISCLOSURE OF YOUR INFORMATION</h4>
          <p>
            We may also disclose your personal information to third parties in
            the following circumstances:
          </p>
          <ol type="a">
            <li>
              We may disclose your personal information to our service providers
              and business partners, including payments processors, database
              tool providers and insurance providers (to assist us in performing
              any contract we enter into with them or you, including providing
              the Website and the Services it enables), analytics providers, (to
              assist us in the improvement and optimisation of the Website)
              and/or a member of our group, which means our subsidiaries, our
              ultimate holding company and its subsidiaries.
              <br />
              <br />
              We may in the future also share your personal information with
              third party payment processor Stripe (Paystack) which manages card
              tokenization, payment of rent, collections and withdrawals for
              both Customers & Agents. Further information in relation to
              Paystack will be found in our terms of service as soon as we
              onboard them.
              <br />
              <br />
              <b>Justification</b>: Contract performance, legitimate interest,
              (to allow our Service providers to provide the necessary
              services).
            </li>
            <li>
              If we sell, buy or lease any business or assets, we may disclose
              your personal information to the prospective seller or buyer of
              such business or assets.
              <br />
              <br />
              <b>Justification</b>: Legitimate interest (to sell or lease our
              business or assets); and where required by applicable law, consent
              (for sensitive personal data).
            </li>
            <li>
              If Yarlo or substantially all of its assets are acquired by a
              third party, personal information about our customers will be one
              of the transferred assets.
              <br />
              <br />
              <b>Justification</b>: Legitimate interest (to sell or lease our
              business or assets); and where required by applicable law, consent
              (for sensitive personal data).
            </li>
            <li>
              If we are under a duty to disclose or share your personal data in
              order to comply with any legal obligation or to protect the
              rights, property, or safety of Yarlo, our customers, or others.
              This includes exchanging information with other companies and
              organisations for the purposes of fraud protection.
              <br />
              <br />
              <b>Justification</b>: Legal obligation, vital interest.
            </li>
            <li>
              Fraud Prevention and other checks. We and other organisations may
              also access and use your personal information to conduct credit
              checks and checks to prevent fraud. If false or accurate
              information is provided and fraud is identified or suspected,
              details may be passed to fraud prevent agencies.
              <br />
              <br />
              <b>Justification</b>: Legitimate interest (to assist with the
              prevention of fraud and to assess your risk profile).
            </li>
            <li>
              As a Renter we will share your following personal information with
              Letting Agents and Asset owners: name, email address, might
              include previous and current addresses, nationality, Payment and
              Maintenance information. As a Letting Agent/Asset owner we will
              share your following personal information with Renters and Letting
              Agents: name, Yarlo wallet and maintenance information for
              payments.
              <br />
              <br />
              <b>Justification</b>: Consent
            </li>
          </ol>
        </div>
        <div className="mb-20">
          <h4 className="header-text">
            8. HOW LONG WE RETAIN YOUR PERSONAL DATA
          </h4>
          <p>
            We will hold the above information for as long as is necessary in
            order to provide you with the Services, deal with any specific
            issues that may raise, or otherwise as is required by law or any
            relevant regulatory body. Once your account is terminated or
            deactivated, we shall delete the personal data relating to your
            account within 72 hrs. If your account is inactive for 2 years, we
            may contact you to assess whether you want to continue to use the
            Services. Some personal data may need to be retained for longer than
            this to ensure Yarlo can comply with applicable laws and internal
            compliance procedures, including retaining your email address for
            marketing communication suppression if you have opted not to receive
            any further marketing.
            <br />
            <br />
            If information is used for two purposes, we will retain it until the
            purpose with the latest period expires but we will stop using it for
            the purpose with a shorter period when that period expires.
            <br />
            <br />
            We restrict access to your personal information to those persons who
            need to use it for the relevant purpose(s). Our retention periods
            are based on business needs and your information that is no longer
            needed is either irreversibly anonymised (and the anonymised
            information may be retained) or securely destroyed.
          </p>
        </div>
        <div className="mb-20">
          <h4 className="header-text">9. YOUR RIGHTS</h4>
          <p>
            Under the General Data Protection Regulation, you have various
            rights in relation to your personal data. All of these rights can be
            exercised by contacting us at{" "}
            <a href="mailto:info@yarlo.co">info@yarlo.co</a>.<br />
            <br />
            You have the following rights in relation to your personal data:
          </p>
          <p>
            <b>Right to Rectification</b>:<br />
            We will use reasonable endeavours to ensure that your personal
            information is accurate. In order to assist us with this, you should
            notify us of any changes to the personal information that you have
            provided to us by sending us a request to rectify your personal data
            where you believe the personal data we have is inaccurate or
            incomplete.
          </p>
          <p>
            <b>Right to erasure / ‘Right to be forgotten'</b>:<br />
            Asking us to delete all of your personal data will result in Yarlo
            deleting your personal data without undue delay (unless there is a
            legitimate and legal reason why Yarlo is unable to delete certain of
            your personal data, in which case we will inform you of this in
            writing).
          </p>
          <p>
            <b>Right to restriction of processing</b>:<br />
            You have the right to ask us to stop processing your personal data
            at any time.
          </p>
          <p>
            <b>Right to data portability</b>:<br />
            You have the right to request that Yarlo provides you with a copy of
            all of your personal data and to transmit your personal data to
            another data controller in a structured, commonly used and
            machine-readable format, where it is technically feasible for us to
            do so.
          </p>
          <p>
            <b>
              Right to object to discussions based solely on automated
              processing
            </b>
            :<br />
            You have the right to not be subject to a decision based solely on
            automated processing which produces legal effects concerning your or
            similarly significant effects and to obtain human intervention, to
            express your point of view or contest the decision.
            <br />
            <br />
            Yarlo will not ordinarily charge you in respect of any requests we
            receive to exercise any of your rights detailed above; however, if
            you make excessive, repetitive or manifestly unfounded requests, we
            may charge you an administration fee in order to process such
            requests or refuse to act on such requests. Where we are required to
            provide a copy of the personal data undergoing processing this will
            be free of charge; however, any further copies requested may be
            subject to reasonable fees based on administrative costs.
            <br />
            <br />
            Asking us to stop processing your personal data or deleting your
            personal data will likely mean that you are no longer able to use
            Yarlo's Services, or at least those aspects of the Services which
            require the processing of the types of personal data you have asked
            us to delete, which may result in you no longer being able to use
            the Services.
            <br />
            <br />
            Where you request Yarlo to rectify or erase your personal data or
            restrict any processing of such personal data, Yarlo may notify
            third parties to whom such personal data has been disclosed of such
            request. However, such third party may have the right to retain and
            continue to process such personal data in its own right, for example
            payment processing or insurance companies.
          </p>
        </div>
        <div className="mb-20">
          <h4 className="header-text">10. CHANGES TO THIS POLICY</h4>
          <p>
            Any changes we make to our privacy policy in the future will be
            posted on this page, and where appropriate, notified to you by email
            or notifications via the App. We therefore encourage you to review
            it from time to time to stay informed of how we are processing your
            information.
          </p>
        </div>
        <div className="mb-20" id="contacts">
          <h4 className="header-text">11. CONTACT</h4>
          <p>
            Questions, comments and requests regarding this privacy policy are
            welcome and should be addressed to{" "}
            <a href="mailto:info@yarlo.co">info@yarlo.co</a>.<br />
            For the purpose of the relevant data protection legislation, the
            data controller is Yarlo Global Limited (company no. 1881326) with
            registered address at 13 Ojefia Street Flat 1, Victory Estate Ago
            Palace Way, Okota, Lagos, Nigeria.
            <br />
            Our data protection officer is: Kome Okah-Avae;{" "}
            <a href="mailto:kome@yarlo.co">kome@yarlo.co</a>.
          </p>
        </div>
        <div className="mb-20">
          <h4 className="header-text">12. COOKIES</h4>
          <p>
            Yarlo uses cookies to distinguish you from other users. This helps
            us provide you with a good experience when you use our Website, and
            also allows us to improve our Services. Please note that it is
            possible to disable cookies being stored on your computer by
            changing your browser settings. However, our Website may not perform
            properly or some features may not be available to you if you disable
            cookies.
            <br />
            For detailed information on the cookies we use and the purposes for
            which we use them see our Cookie policy at{" "}
            <a href="https://www.yarlo.co/cookies_policy.html">
              https://www.yarlo.co/cookie-policy
            </a>
            .
          </p>
        </div>
      </div>
    </Layout>
  );
}
