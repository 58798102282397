import React, { useState } from "react";
import { Modal, Checkbox } from "antd";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import MainButton from "../Buttons/MainButton";
import { Link } from "react-router-dom";
import InputField from "../Form/InputField";
import CustomDatePicker from "../Form/DatePicker";
import validationSchema from "../../helpers/validationSchema";
import { useDispatch } from "react-redux";
import { setSignupInfo } from "../../slice/userSlice";
import { useSendOTPMutation } from "../../api/auth";
import dayjs from "dayjs";
import useMessageAlert from "../Message";
import InputAdornment from "@mui/material/InputAdornment";
import SelectField from "../Form/SelectField";
import { mapToOptions } from "../../helpers";

function SignupModal({ showLoginModal, visible, onCancel, showOTPModal }) {
  const dispatch = useDispatch();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [sendOTP] = useSendOTPMutation();
  const { openMessage, contextHolder } = useMessageAlert();
  const currentDateMinus18 = new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toLocaleDateString("en-UK");
  const handleSubmit = async (values) => {
    if (!acceptTerms) {
      openMessage("warning", "Please accept Terms to continue");
    } else {
      const phoneNumber = "234" + values.phoneNumber.replace(/^0+/, "").trim();
      let reqBody = {
        emailAddress: values.emailAddress,
        phoneNumber: phoneNumber,
        reset: false,
      };
      let signupInfo = {
        ...values,
        phoneNumber: phoneNumber,
        dateOfBirth: dayjs(values.dateOfBirth).format("DD/MM/YYYY"),
        preferredName: "",
      };
      sendOTP(reqBody)
        .unwrap()
        .then((res) => {
          dispatch(setSignupInfo(signupInfo));
          showOTPModal();
        })
        .catch((error) =>
          openMessage(
            "error",
            error?.data?.errorMessage || "an unexpected error occured"
          )
        );
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      className={"generic-modal"}
      footer={[
        <div className="flex border-t border-[#F2F2F3] pt-4">
          <Checkbox
            name="terms"
            className="terms-checkbox"
            checked={acceptTerms}
            onChange={() => setAcceptTerms(!acceptTerms)}
          />
          <p className="text-sm text-[#84818A] font-poppins ml-3 mb-0">
            By clicking Create account, I agree that I have read and accepted
            the{" "}
            <Link
              to={"/privacy-policy"}
              className={"text-[#009580] hover:text-primaryOrange"}
            >
              Yarlo Privacy Policy
            </Link>{" "}
            and{" "}
            <Link
              to={"/terms_of_service"}
              className={"text-[#009580] hover:text-primaryOrange"}
            >
              Terms of Service.
            </Link>
          </p>
        </div>,
      ]}
    >
      <div className="py-4">
        {contextHolder}
        <h1 className="text-[2rem] font-medium text-[#202020] mb-3">Sign up</h1>
        <p className="text-primaryBlack font-medium font-poppins text-base mb-0">
          Already have an account?{" "}
          <span
            onClick={showLoginModal}
            className="text-primaryOrange hover:text-primaryOrange/75 cursor-pointer"
            tabIndex={0}
          >
            Sign in
          </span>
        </p>
      </div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          dateOfBirth: currentDateMinus18,
          emailAddress: "",
          phoneNumber: "",
          userPassword: "",
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        validationSchema={yup.object().shape(validationSchema.signup)}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          handleSubmit,
          touched,
        }) => (
          <Form>
            <div className="flex flex-col w-full">
              <div className="grid sm:grid-cols-2 sm:gap-4">
                <InputField
                  name={"firstName"}
                  inputType={"text"}
                  label={"First Name"}
                  placeholder={"First Name"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={errors.firstName}
                  touched={touched.firstName}
                />

                <InputField
                  name={"lastName"}
                  inputType={"text"}
                  label={"Last Name"}
                  placeholder={"Last Name"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={errors.lastName}
                  touched={touched.lastName}
                />
              </div>
              <Field
                id="dateOfBirth"
                type="date"
                component={CustomDatePicker}
                hasLabel
                label="Date of Birth"
                value={values.dateOfBirth}
                format={"D/M/YYYY"}
                error={errors.dateOfBirth}
                touched={touched.dateOfBirth}
              />
              <InputField
                name={"emailAddress"}
                inputType={"email"}
                label={"Email Address"}
                placeholder={"Email Address"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailAddress}
                error={errors.emailAddress}
                touched={touched.emailAddress}
              />
              <div className="grid sm:grid-cols-3 sm:gap-4">
                <div className="col-span-1">
                  <SelectField
                    id={"countryCode"}
                    hasLabel
                    label={"Country Code"}
                    options={mapToOptions(["+234"])}
                    value={"+234"}
                  />
                </div>
                <div className="col-span-2">
                  <InputField
                    name={"phoneNumber"}
                    inputType={"text"}
                    label={"Phone Number"}
                    placeholder={"08010000000"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                    error={errors.phoneNumber}
                    touched={touched.phoneNumber}
                    InputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      inputProps: { maxLength: 11 },
                      startAdornment: (
                        <InputAdornment position="start">+234</InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <InputField
                name={"userPassword"}
                inputType={"password"}
                label={"Password"}
                placeholder={"Password"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.userPassword}
                error={errors.userPassword}
                touched={touched.userPassword}
              />
              <div className="flex items-center my-6">
                <MainButton
                  title={"Create Account"}
                  size={"md"}
                  appendStyles={"font-poppins font-medium w-full"}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default SignupModal;
