import { useCallback, useEffect, useState } from "react";
import { Drawer, Upload, Dropdown, Modal } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyGetDirectMessagesQuery,
  useSendChatMutation,
  useUploadImagesMutation,
  useDeleteChatListItemMutation,
} from "../../api/chat";
import { appendImageUrl } from "../../helpers";
import { PaperclipIcon, SendlinearIcon, OptionsIcon, DeleteIcon } from "../CustomIcons";
import useMessageAlert from "../Message";
import Spinner from "../Spinner";
import { setReceiverData, logoutChat } from "../../slice/chatSlice";
import imageUrls from "../LazyLoadImg/imageUrls";
import { Link, useNavigate } from "react-router-dom";
import { Client, Stomp } from "@stomp/stompjs";
import SockJS from "sockjs-client";

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const SOCKET_URL = process.env.REACT_APP_WEB_SOCKET;

const SentChatItem = ({
  name,
  message,
  profileImage,
  timestamp,
  imageLocation,
}) => (
  <>
    <div className="flex flex-col gap-y-1">
      <div className="flex items-end justify-end w-full space-x-4">
        {!isEmpty(message) && (
          <div className="flex max-w-xl bg-primaryOrange px-5 py-4 rounded-t-3xl rounded-bl-3xl">
            <span className="text-sm font-normal leading-6 text-white block">
              {message}
            </span>
          </div>
        )}
        <img
          src={profileImage}
          alt={"sender-profile"}
          className="md:block hidden rounded-full relative w-9 h-9 bg-orange-400 object-cover"
        />
      </div>

      <div className="flex justify-end md:mr-14">
        <p className="mb-[0] text-textGray1 text-xs leading-6 font-normal">
          {timestamp}
        </p>
      </div>

      <div className="flex justify-end md:mr-14">
        {!isEmpty(imageLocation) && (
          <div className="flex max-w-xl flex-row-reverse gap-2 flex-wrap">
            <img
              src={appendImageUrl(imageLocation, "chat")}
              alt={"chat-pic"}
              className="rounded-lg w-20 h-20 object-cover"
            />
          </div>
        )}
      </div>
    </div>
  </>
);

const ReceivedChatItem = ({
  name,
  message,
  profileImage,
  timestamp,
  imageLocation,
}) => (
  <>
    <div className="flex flex-col gap-y-1">
      <div className="flex items-end justify-start w-full space-x-4">
        <img
          src={profileImage}
          alt={"receiver-profile"}
          className="md:block hidden rounded-full relative w-9 h-9 bg-gray-200 object-cover"
        />
        {!isEmpty(message) && (
          <div className="flex max-w-xl bg-[#F7F6F6] px-5 py-4 rounded-t-3xl rounded-br-3xl">
            <span className="text-sm font-normal leading-6 text-primaryBlack block">
              {message}
            </span>
          </div>
        )}
      </div>

      <div className="flex justify-start ml-4 md:ml-14">
        <p className="mb-[0] text-textGray1 text-xs leading-6 font-normal">
          {timestamp}
        </p>
      </div>

      <div className="flex justify-start md:ml-14">
        {!isEmpty(imageLocation) && (
          <div className="flex max-w-xl gap-2 flex-wrap">
            <img
              src={appendImageUrl(imageLocation, "chat")}
              alt={"chat-pic"}
              className="rounded-lg w-20 h-20 object-cover"
            />
          </div>
        )}
      </div>
    </div>
  </>
);

const MessageInput = ({ handleChange, value, sendChat, uploadImage }) => (
  <form
    className="flex items-center w-full"
    onSubmit={(e) => {
      e.preventDefault();
      sendChat();
    }}
  >
    <label htmlFor="chat-message" className="sr-only">
      Type your message here
    </label>
    <div className="relative w-full border-t border-borderGray">
      <input
        type="text"
        name="chat-message"
        className="border-0 placeholder:text-textGray1 font-normal leading-6 text-sm block w-full py-4 pl-3 md:pl-5 pr-32 md:pr-36 focus:outline-0"
        placeholder="Type your message here"
        required
        value={value}
        onChange={handleChange}
      />
      <div className="absolute inset-y-0 right-0 bg-white flex items-center gap-6 border-l border-borderGray px-4 md:px-6">
        <Upload fileList={[]} onChange={uploadImage} accept="image/*">
          <PaperclipIcon className={"w-5 h-5 cursor-pointer"} />
        </Upload>
        <div
          onClick={sendChat}
          className="cursor-pointer text-white bg-primaryOrange w-10 h-10 flex items-center justify-center rounded-full"
        >
          <SendlinearIcon className='w-3.5 h-3.5 -ml-0.5 text-center' />
        </div>
      </div>
    </div>
  </form>
);

const LoadingChatItem = ({ name, profileImage, timestamp }) => (
  <div className="absolute bottom-0 right-0 mb-10 md:px-5 w-full">
    <div className="flex items-end justify-end w-full space-x-4">
      <div>
        <div className="flex max-w-xl bg-primaryOrange px-5 py-4 rounded-t-3xl rounded-bl-3xl">
          <div className="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="text-right">
          <span className="text-textGray1 text-xs leading-6 font-normal">{timestamp}</span>
          {/* <span className="text-primaryBlack text-sm font-semibold ml-2">
            {name}
          </span> */}
        </div>
      </div>
      <img
        alt={"sender-profile"}
        src={profileImage}
        className="rounded-full w-9 h-9 bg-orange-400 object-cover"
      />
    </div>
  </div>
);

export default function Chat({ chatDrawerOpen, closeChatDrawer, listingId }) {
  const { profile, isLoggedIn } = useSelector((state) => state.user);
  const { receiverData } = useSelector((state) => state.message);
  //const { headers } = useSelector((state) => state.);
  const dispatch = useDispatch();
  const [sendChat, { isLoading: sendingChat }] = useSendChatMutation();
  const [uploadImg, { isLoading: uploadingImage }] = useUploadImagesMutation();
  const [getDMs, { data, isLoading, isFetching }] =
    useLazyGetDirectMessagesQuery();
  const { openMessage, contextHolder } = useMessageAlert();
  const chatId = receiverData?.chatId;
  const [message, setMessage] = useState("");
  const handleChange = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };
  const [ deleteChatItem, { isLoading: deletingChat } ] = useDeleteChatListItemMutation();
  const [openModal, setOpenModal] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [messages, setMessages] = useState([]);

  const handleGetDms = useCallback(() => {
    let params = {
      userId: profile?.userId,
      chatId,
    };
    if (isLoggedIn && !isEmpty(chatId)) {
      getDMs(params)
        .unwrap()
        .then(response => {
          setMessages(response);
        })
        .catch((error) => {
          openMessage(
            "error",
            error?.data?.message || "Failed to get messages"
          );
        });
      
    }
  }, [getDMs, isLoggedIn, profile, chatId]);

  const handleUploadImage = ({ fileList: newFileList }) => {
    const formdata = new FormData();
    formdata.append("file", newFileList[0].originFileObj);
    let params = {
      body: {
        sender: profile?.userId,
        receiver: receiverData?.userId,
        message,
        receiverEmailAddress: receiverData?.emailAddress,
        receiverFcmToken: receiverData?.fcmToken,
        senderFirstName: profile?.firstName,
        senderLastName: profile?.lastName,
        timestamp: dayjs().toISOString(),
        title: receiverData?.title,
      },
      chatId,
      listingId,
    };
    stompClient.send("/api/message/send?chatId="+ chatId+"&listingId="+listingId, headers, JSON.stringify(params.body));
    setMessage("");
    sendChat(params)
      .unwrap()
      .then((res) => {
        uploadImg({ chatMessageId: res.currentChatMessageId, patch: formdata })
          .unwrap()
          .then((res) => console.log("res", res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log("send chat err", err));
  };

  const deleteChatList = (chatId) => {
    let params = {
      userId: profile?.userId,
      chatId,
    };
    deleteChatItem(params)
      .unwrap()
      .then(() => {
        dispatch(logoutChat());
        window.location.reload();
      })
      .catch((err) => console.log("delete chat err", err));
  };

  const showDeleteModal = () => {
    setCurrentAction("delete");
    setOpenModal(true);
  };

  let stompClient;
  const token = profile.token;
  const clientId = profile.clientId;
  const headers = { "Client-Type": "YARLO_WEB","accept":  "application/json", "Authorization": `Bearer ${token}`,"Client-ID": clientId};
  useEffect(() => {
    var socket = new SockJS(SOCKET_URL+ "/api/message/message-websocket");
    stompClient = Stomp.over(socket);
    stompClient.connect(
      {},
      function (frame) {
        stompClient.subscribe("/api/message/topic/"+ chatId, function (messageOutput) {
          setMessages([...messages,  JSON.parse(messageOutput.body)]);
        });
      },
    //console.log("error")
    );
  });

  const handleSendMessage = () => {
    if (isEmpty(message)) {
      return openMessage("error", "Please type a message first");
    } else if (isEmpty(receiverData)) {
      return openMessage("error", "No Receiver selected");
    } else {
      let params = {
        body: {
          sender: profile?.userId,
          receiver: receiverData?.userId,
          message,
          receiverEmailAddress: receiverData?.emailAddress,
          receiverFcmToken: receiverData?.fcmToken,
          senderFirstName: profile?.firstName,
          senderLastName: profile?.lastName,
          timestamp: dayjs().toISOString(),
          title: receiverData?.title,
          listingId: listingId,
        },
        chatId: chatId,
        listingId,
      };
      let url = "/api/message/send/"+chatId;
      stompClient.send(url, headers, JSON.stringify(params.body));
      setMessage("");
      /*sendChat(params)
        .unwrap()
        .then((res) => {
          if (isEmpty(receiverData.chatId)) {
            dispatch(setReceiverData({ ...receiverData, chatId: res.chatId }));
          }
          openMessage("success", "message sent");
          handleGetDms();
          //navigate("/messages");
        })
        .catch((error) => {
          openMessage(
            "error",
            error?.data?.errorMessage || "Failed to send message"
          );
        });*/
    }
  };

  useEffect(() => {
    handleGetDms();
  }, [handleGetDms]);

  return (
    <>
      {contextHolder}
      <div className="hidden lg:block w-full h-full border-y border-r border-borderGray relative pb-28">

        <div className="flex items-center justify-between px-5 py-3 border-b border-[#E5DEDE]">
          {!isEmpty(receiverData) && (
            <div className="flex items-center gap-3">
              <img
                src={!isEmpty(receiverData?.imageLink)
                  ? appendImageUrl(receiverData?.imageLink, "user")
                  : imageUrls.defaultDp}
                alt={"profile"}
                className="rounded-full w-9 h-9 object-cover bg-orange-100"
              />
              <p className="text-sm font-semibold text-primaryBlack leading-4 capitalize mb-[0]">
                {receiverData?.preferredName ||
                  `${receiverData?.firstName} ${receiverData?.lastName}`}
              </p>
            </div>
          )}
          {/* Call vendor and more options */}
          <div className="flex items-center">
            <div className="flex items-center justify-center h-4 w-4 ml-4">
            <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: (
                  <Link
                    to={"#"}
                    onClick={(e) => {
                      e.preventDefault();
                      showDeleteModal(true);
                    }}
                  >
                    <p className="mb-0 text-sm font-medium text-[#FF3B30]">
                      Delete
                    </p>
                  </Link>
                ),
                icon: <DeleteIcon className={"w-4 h-4"} />,
              },
            ],
          }}
          trigger={["click"]}
          placement={"bottomRight"}
          overlayClassName="ad-menu"
        >
              <OptionsIcon />
              </Dropdown>
            </div>
          </div>
        </div>
      <Modal
        open={openModal}
        title={`Delete Chat`}
        onCancel={() => setOpenModal(false)}
        onOk={() => {
          setOpenModal(false);
          if (currentAction === "delete") {
            deleteChatList(chatId);
          }
        }}
        wrapClassName="preview-modal"
      >
        <div className="flex h-[5rem] items-center justify-center">
          <p className="text-lg text-primaryBlack font-medium">
            {`Are you sure you want to Delete this Chat?`}
          </p>
        </div>
      </Modal>
        <div className="py-4 px-2 md:p-4 overflow-y-auto h-full scrollbar-thin scroll-smooth scrollbar-thumb-orange-100 scrollbar-rounded-full space-y-4">
          {isLoading || isFetching || deletingChat ? (
            <Spinner />
          ) : (
            <>
              {isEmpty(messages) ? (
                <div className="flex items-center justify-center m-auto h-full">
                  <p className="text-base font-normal mb-0 text-textGray1">
                    Open a conversation to see it here
                  </p>
                </div>
              ) : (
                messages?.map((dm, i) => (
                  <>
                    {dm?.sender === profile.userId ? (
                      <>
                        <SentChatItem
                          key={i}
                          name={
                            profile?.preferredName ||
                            `${profile?.firstName} ${profile?.lastName}`
                          }
                          profileImage={
                            !isEmpty(profile?.imageLink)
                              ? appendImageUrl(profile?.imageLink, "user")
                              : imageUrls.defaultDp
                          }
                          timestamp={dayjs.utc(
                            dm?.timestamp,
                            "D/M/YYYY HH:mm:ss"
                          ).fromNow()}
                          message={dm?.message}
                          imageLocation={dm?.fileLocation}
                        />
                      </>
                    ) : (
                      <>
                        <ReceivedChatItem
                          key={i}
                          name={
                            receiverData?.preferredName ||
                            `${receiverData?.firstName} ${receiverData?.lastName}`
                          }
                          profileImage={
                            !isEmpty(receiverData?.imageLink)
                              ? appendImageUrl(receiverData?.imageLink, "user")
                              : imageUrls.defaultDp
                          }
                          timestamp={dayjs.utc(
                            dm?.timestamp,
                            "D/M/YYYY HH:mm:ss"
                          ).fromNow()}
                          message={dm?.message}
                          imageLocation={dm?.fileLocation}
                        />
                      </>
                    )}
                  </>
                ))
              )}
              {sendingChat && (
                <LoadingChatItem
                  name={
                    profile?.preferredName ||
                    `${profile?.firstName} ${profile?.lastName}`
                  }
                  profileImage={appendImageUrl(profile?.imageLink, "user")}
                  timestamp={dayjs().format("HH:mm A")}
                />
              )}
            </>
          )}
        </div>

        <div className="absolute w-full bottom-0 left-0">
          <MessageInput
            handleChange={handleChange}
            value={message}
            sendChat={handleSendMessage}
            uploadImage={handleUploadImage}
          />
        </div>

      </div>
      <Drawer
        placement="left"
        open={chatDrawerOpen}
        onClose={closeChatDrawer}
        width={"100%"}
        rootClassName={"chat-drawer lg:hidden overflow-y-auto h-full"}
        headerStyle={{ padding: "12px 16px" }}
        bodyStyle={{ padding: "0px 8px 56px 8px" }}
        title={
          <div className="flex items-center justify-between">
            {!isEmpty(receiverData) && (
              <div className="flex items-center gap-3">
                <img
                  src={!isEmpty(receiverData?.imageLink)
                    ? appendImageUrl(receiverData?.imageLink, "user")
                    : imageUrls.defaultDp}
                  alt={"profile"}
                  className="rounded-full w-9 h-9 object-cover bg-orange-100"
                />
                <p className="text-sm font-semibold text-primaryBlack leading-4 capitalize mb-[0]">
                  {receiverData?.preferredName ||
                    `${receiverData?.firstName} ${receiverData?.lastName}`}
                </p>
              </div>
            )}
            {/* Call vendor and more options */}
            {/* <div className="flex items-center">
              <div className="flex items-center justify-center h-4 w-4">
                <PhoneIcon />
              </div>
              <div className="flex items-center justify-center h-4 w-4 ml-4">
                <OptionsIcon />
              </div>
            </div> */}
          </div>
        }
      >
        <div className="py-4 md:p-4 space-y-4">
          {isLoading || isFetching || deletingChat ? (
            <Spinner />
          ) : (
            <>
              {isEmpty(data) ? (
                <div className="flex items-center justify-center m-auto h-[75vh]">
                  <p className="text-base font-normal mb-0 text-textGray1">
                    Open a conversation to see it here
                  </p>
                </div>
              ) : (
                messages?.map((dm, i) => (
                  <>
                    {dm?.sender === profile.userId ? (
                      <>
                        <SentChatItem
                          key={i}
                          name={
                            profile?.preferredName ||
                            `${profile?.firstName} ${profile?.lastName}`
                          }
                          profileImage={appendImageUrl(
                            profile?.imageLink,
                            "user"
                          )}
                          timestamp={dayjs.utc(
                            dm?.timestamp,
                            "D/M/YYYY HH:mm:ss"
                          ).fromNow()}
                          message={dm?.message}
                          imageLocation={dm?.fileLocation}
                        />
                      </>
                    ) : (
                      <>
                        <ReceivedChatItem
                          key={i}
                          name={
                            receiverData?.preferredName ||
                            `${receiverData?.firstName} ${receiverData?.lastName}`
                          }
                          profileImage={appendImageUrl(
                            receiverData?.imageLink,
                            "user"
                          )}
                          timestamp={dayjs.utc(
                            dm?.timestamp,
                            "D/M/YYYY HH:mm:ss"
                          ).fromNow()}
                          message={dm?.message}
                          imageLocation={dm?.fileLocation}
                        />
                      </>
                    )}
                  </>
                ))
              )}
              {sendingChat || uploadingImage || deletingChat ? (
                <LoadingChatItem
                  name={
                    profile?.preferredName ||
                    `${profile?.firstName} ${profile?.lastName}`
                  }
                  profileImage={appendImageUrl(profile?.imageLink, "user")}
                  timestamp={dayjs().format("HH:mm A")}
                />
              ) : null}
            </>
          )}
        </div>

        <div className="absolute w-full bottom-0 left-0">
          <MessageInput
            handleChange={handleChange}
            value={message}
            sendChat={handleSendMessage}
            uploadImage={handleUploadImage}
          />
        </div>
      </Drawer>
    </>
  );
}
