import React, { useState, useEffect, useContext } from "react";
import Layout from "../Layout";
import ChatList from "./ChatList";
import Chat from "./Chat";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import { isEmpty } from "lodash";
import HeaderComponent from "../Layout/HeaderComponent";
import { MyContext } from "../../context/Context";

export default function Message() {
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();
  const [chatDrawerOpen, setChatDrawerOpen] = useState(false);
  const { setBreadcrumbPaths } = useContext(MyContext);
  
  const receiverId = pathname.split("/")[2];
  const listingId = pathname.split("/").length > 3 ?pathname.split("/")[3]: "";

  const openChatDrawer = () => {
    setChatDrawerOpen(true);
  };

  const closeChatDrawer = () => {
    setChatDrawerOpen(false);
  };

  useEffect(() => {
    if ((width < 990) & !isEmpty(receiverId)) {
      openChatDrawer();
    }
    setBreadcrumbPaths([{ disabled: true, title: "Messages" }]);
  }, [setBreadcrumbPaths, receiverId, width]);

  return (
    <Layout className=''>
      <HeaderComponent
        pageTitle={"Chat Directly - Yarlo"}
        pageDescription={"Chat with rental asset owners directly, negotiate pricing and agree on terms"}
      />
      <div className="pt-10 md:pt-12 w-full bg-white h-[45rem] overflow-y-hidden flex">
        <ChatList openChatDrawer={openChatDrawer} />
        <Chat
          chatDrawerOpen={chatDrawerOpen}
          closeChatDrawer={closeChatDrawer}
          listingId={listingId}
        />
      </div>
    </Layout>
  );
}
