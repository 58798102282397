import React from "react";
import Layout from "../Layout";
import "../Home/index.css";
import Admin from "./Admin";
import HeaderComponent from "../Layout/HeaderComponent";

export default function Profile() {
  return (
    <Layout>
      <HeaderComponent
        pageTitle={"Account Information"}
        pageDescription={
          "Manage your Yarlo account profile, listed assets, notifications, credentials and more"
        }
      />
      <Admin />
    </Layout>
  );
}
