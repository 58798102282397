import React, { useState, useRef, useCallback, useEffect } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import InputField from "../Form/InputField";
import SelectField from "../Form/SelectField";
import { mapToOptions } from "../../helpers";
import MainButton from "../Buttons/MainButton";
import validationSchema from "../../helpers/validationSchema";
import useMessageAlert from "../Message";
import { useDispatch, useSelector } from "react-redux";
import { decrement, reset } from "../../slice/stepscounter";
import { useGetDropdownDataQuery } from "../../api/general";
import { flow, isEmpty } from "lodash";
import {
  useAddListingImagesMutation,
  usePostListingMutation,
  useUpdateListingMutation,
  useDeleteListingImageMutation,
} from "../../api/listing";
import { resetNewListing, updateNewListing } from "../../slice/listingSlice";
import Spinner from "../Spinner";
import TextArea from "../Form/TextArea";

export default function Pricing() {
  const dispatch = useDispatch();
  const { newListing } = useSelector((state) => state.listings);
  const { profile } = useSelector((state) => state.user);
  const formikRef = useRef(null);
  const { data: dropdownData } = useGetDropdownDataQuery();
  const { openMessage, contextHolder } = useMessageAlert();
  const [rentalPeriods, setRentalPeriods] = useState([]);
  const [priceTypes, setPricingTypes] = useState([]);
  const [refundPolicies, setRefundPolicies] = useState([]);
  const [addListing, addListingResults] = usePostListingMutation();
  const [updateListing, updateListingResults] = useUpdateListingMutation();
  const [addListingImages, addImagesResults] = useAddListingImagesMutation();
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [deleteImages] = useDeleteListingImageMutation();

  const onSubmit = async (values) => {
    const formData = new FormData();
    // filter imagesLocation object from newListing using lodash flow to chain the calls
    let imageNumber = newListing?.imagesLocation.length;
    const listingDetails = flow([
      Object.entries,
      (arr) =>
        arr.filter(
          ([key]) =>
            key !== "imagesLocation" &&
            key !== "additionalImages" &&
            key !== "rawImages"
        ),
      Object.fromEntries,
    ])(newListing);
    try {
      if (!isEmpty(newListing?.listingId)) {
        updateListing({
          userId: profile?.userId,
          patch: {
            ...listingDetails,
            rentAmount: values.rentAmount * 1,
            cautionFee: values.cautionFee * 1,
            rentalPeriod: values.rentalPeriod,
            negotiable: values.priceType === "Fixed" ? false : true,
            refundable: values.refundable === "No refund" ? false : true,
            termsCondition: values.termsCondition,
          },
        }).unwrap();
        newListing?.additionalImages?.map((im) => formData.append("file", im));
        if (formData.get("file") !== null) {
          await addListingImages({
            listingId: newListing?.listingId,
            patch: formData,
            imageNumber,
          }).unwrap();
        }
        if (newListing.removedImages?.length > 0) {
          await deleteImages({
            listingId: newListing?.listingId,
            userId: profile?.userId,
            locations: newListing.removedImages,
          }).unwrap();
        }
      } else {
        let res = await addListing({
          userId: profile?.userId,
          patch: {
            ...listingDetails,
            rentAmount: values.rentAmount * 1,
            cautionFee: values.cautionFee * 1,
            rentalPeriod: values.rentalPeriod,
            negotiable: values.priceType === "Fixed" ? false : true,
            refundable: values.refundable === "No refund" ? false : true,
            termsCondition: values.termsCondition,
          },
        }).unwrap();
        if (!isEmpty(newListing?.imagesLocation)) {
          newListing?.imagesLocation?.map((im) => formData.append("file", im));
          try {
            await addListingImages({
              listingId: res?.listingId,
              patch: formData,
              imageNumber: 0,
            }).unwrap();
          } catch(error) {
            console.log(error?.data?.errorMessage || "Failed to add listing");
          }
        } else {
          console.log("Browser error: No image attached to request");
        }
        
      }
      openMessage(
        "success",
        "Your posting has been sent, admin will review and approve or decline your posting!"
      );
      sleep(1000).then(() => {
        dispatch(resetNewListing({}));
        dispatch(reset());
      });
    } catch (error) {
      openMessage(
        "error",
        error?.data?.errorMessage || "Failed to add listing"
      );
    }
  };

  const goBack = () => {
    dispatch(decrement());
  };

  const handleSetDropdowns = useCallback(() => {
    let periods;
    let pricingType;
    let refundPolicy;

    if (!isEmpty(dropdownData)) {
      periods = dropdownData?.find(
        (types) => types.dropdownKey === "rentalPeriods"
      );
      setRentalPeriods(mapToOptions(periods?.values));
      pricingType = dropdownData?.find(
        (types) => types.dropdownKey === "pricingTypes"
      );
      setPricingTypes(mapToOptions(pricingType?.values));
      refundPolicy = dropdownData?.find(
        (types) => types.dropdownKey === "refundPolicies"
      );
      setRefundPolicies(mapToOptions(refundPolicy?.values));
    }
  }, [dropdownData]);

  useEffect(() => {
    handleSetDropdowns();
  }, [handleSetDropdowns]);

  return (
    <div>
      {contextHolder}
      {addListingResults.isFetching ||
      updateListingResults.isFetching ||
      addImagesResults.isFetching ? (
        <Spinner />
      ) : (
        <>
          <h2 className="font-semibold text-2xl text-headingBlack mb-2">
            Pricing
          </h2>
          <p className="text-sm text-headingBlack mb-0">
            Details about the item pricing policy and fees
          </p>
          <Formik
            initialValues={{
              rentAmount: newListing?.rentAmount
                ? `${newListing?.rentAmount}`
                : undefined,
              rentalPeriod: newListing?.rentalPeriod || undefined,
              termsCondition: newListing?.termsCondition || undefined,
              priceType:
                `${newListing?.negotiable === true ? "Negotiable" : "Fixed"}` ||
                undefined,
              refundable:
                `${newListing?.refundable === true ? "Refund" : "No refund"}` ||
                undefined,
              cautionFee: newListing?.cautionFee
                ? `${newListing?.cautionFee}`
                : undefined,
            }}
            onSubmit={(values) => {
              dispatch(updateNewListing(values));
              sleep(500).then(() => onSubmit(values));
            }}
            validationSchema={yup.object().shape(validationSchema.pricing)}
            innerRef={formikRef}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              setFieldValue,
              touched,
            }) => (
              <Form>
                <div className="flex mt-6 flex-col w-full">
                  <div className="grid sm:grid-cols-2 sm:gap-4 lg:gap-y-0">
                    <InputField
                      name={"rentAmount"}
                      inputType={"number"}
                      label={"Price"}
                      placeholder={"Enter Price (Numbers only)"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.rentAmount}
                      error={errors.rentAmount}
                      touched={touched.rentAmount}
                      minValue={0}
                    />

                    <SelectField
                      id={"rentalPeriod"}
                      hasLabel
                      label={"Rental Period"}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      options={rentalPeriods}
                      value={values.rentalPeriod}
                      error={errors.rentalPeriod}
                      touched={touched.rentalPeriod}
                    />
                    <SelectField
                      id={"priceType"}
                      hasLabel
                      label={"Pricing Type"}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      options={priceTypes}
                      value={values.priceType}
                      error={errors.priceType}
                      touched={touched.priceType}
                    />
                    <SelectField
                      id={"refundable"}
                      hasLabel
                      label={"Refund Policy"}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      options={refundPolicies}
                      value={values.refundable}
                      error={errors.refundable}
                      touched={touched.refundable}
                    />

                    <InputField
                      name={"cautionFee"}
                      inputType={"number"}
                      label={"Caution Fee"}
                      placeholder={"Enter Caution Fee(Optional)"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cautionFee}
                      error={errors.cautionFee}
                      touched={touched.cautionFee}
                      minValue={0}
                    />
                    <TextArea
                      name={"termsCondition"}
                      hasLabel
                      label={"Terms and Conditions"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.termsCondition}
                      error={errors.termsCondition}
                      touched={touched.termsCondition}
                      maxLength={1000}
                    />
                  </div>
                </div>
                <div className="flex flex-col-reverse sm:flex-row justify-between pb-1 pt-6 mt-6 -mx-5 md:-mx-8 lg:-mx-10 px-5 md:px-8 lg:px-10 border-t border-black/[0.1]">
                  <MainButton
                    title={"Back"}
                    variant={"outline-gray"}
                    appendStyles={
                      "font-poppins mt-3 sm:mt-0 hover:bg-primaryOrange/50"
                    }
                    onClick={goBack}
                  />
                  <MainButton
                    title={"Submit"}
                    appendStyles={"font-poppins"}
                    onClick={() => formikRef?.current?.handleSubmit()}
                    isLoading={
                      addListingResults.isLoading ||
                      updateListingResults.isLoading
                    }
                  />
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
}
