import { apiInstance } from "./";

export const generalApi = apiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getDropdownData: builder.query({
      query: () => "/api/dropdown/all",
    }),
    getNotifications: builder.query({
      query: (userId) => `/api/notification/userId/`+ encodeURI(userId),
    }),
    getNumNotifications: builder.query({
      query: (userId) => `/api/notification/unread/`+encodeURI(userId) ,
    }),
    defaultToken: builder.query({
      query: () => ({
        url: `/api/token/generate`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetDropdownDataQuery,
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
  useLazyDefaultTokenQuery,
  useLazyGetNumNotificationsQuery,
} = generalApi;
