import { apiInstance } from "./";

export const transactionApi = apiInstance.injectEndpoints({
    endpoints: (builder) => ({
      postTransaction: builder.mutation({
        query: ({ userId, patch }) => ({
          url: `/api/transaction/process/`+ encodeURI(userId),
          method: "POST",
          body: patch,
          redirect: "follow",
        }),
      }),
    }),
  });

  export const {
    usePostTransactionMutation,
    
  } = transactionApi;
  