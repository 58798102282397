import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MyContext } from '../../context/Context';

const Breadcrumb = ({location}) => {
  const { replaceText, isReplacingId, setIsReplacingId, breadcrumbPaths } = useContext(MyContext)
  useEffect(() => {
    return () => {
      if (isReplacingId) {
        setIsReplacingId(false);
      }
    };
  }, [replaceText, isReplacingId, setIsReplacingId]);

  const isHomePage = location.pathname === '/';
  const paths = location.pathname.split('/').filter(path => path !== '');
  const url = new URLSearchParams(location.search);
  const category = url.get("category")?.replace(/%20/g, " ") || undefined;
  const isListingPage = location.pathname.includes('/listing');
  const isMessagingPage = location.pathname.includes('/messages');

  if (isHomePage) {
    return null;
  }

  return (
    <nav className="flex items-center text-sm capitalize px-4 sm:px-6 md:px-8 xl:px-32 2xl:px-36">
      <Link to="/" className="text-gray-500 hover:text-gray-700 transition-colors duration-200">Home</Link>
      {breadcrumbPaths.map((breadcrumbPath, index) => (
        <React.Fragment key={index}>

          <span className="mx-2 text-gray-400">
            <svg className='w-2.5 h-2.5' viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L6 6L1 11" stroke="#878380" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </span>

          {breadcrumbPath.disabled ? (
            <span className="text-gray-500 hover:text-gray-700 transition-colors duration-200 capitalize">
              {breadcrumbPath.title}
            </span>
          ) : (
            <Link
              to={breadcrumbPath.path}
              className="text-gray-500 hover:text-gray-700 transition-colors duration-200 capitalize"
            >
              {breadcrumbPath.title}
            </Link>
          )}
        </React.Fragment>
      ))}
    </nav>
  );
};

export default Breadcrumb;
