import React from "react";
import Stepper from "./Steps";
import { useLocation } from "react-router-dom";
import Layout from "../Layout";
import HeaderComponent from "../Layout/HeaderComponent";

export default function PostListing() {
  const location = useLocation();
  return (
    <Layout>
      <HeaderComponent
        pageTitle={"Rent your items on Yarlo"}
        pageDescription={"To start renting your items on Yarlo, you first need to upload it first. You can provide category, sub-category, item description, terms and conditions, item images, and price. All these details are available to your customer to help them rent your items"}
      />
      <Stepper listingId={location?.state?.listingId} />
    </Layout>
  );
}
