import React, { useRef } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import validationSchema from "../../helpers/validationSchema";
import MainButton from "../Buttons/MainButton";
import InputField from "../Form/InputField";
import TextArea from "../Form/TextArea";
import useMessageAlert from "../Message";
import { useNavigate } from "react-router-dom";

export default function Contact() {
    const formikRef = useRef(null);
    const { openMessage, contextHolder  } = useMessageAlert();
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const navigate = useNavigate();

    async function sendContactUs(data) {
        var raw = JSON.stringify({
          emailAddress: data.emailAddress,
          phoneNumber: data.phoneNumber,
          fullName: data.fullName,
          messageBody: data.description,
        });
        var requestOptions = {
          method: "POST",
          body: raw,
          headers: new Headers({
            "Content-Type": "application/json",
            Accept: "application/json",
          }),
          redirect: "follow",
        };
    
        fetch("https://api-web.yarlo.net/api/interest/newsletter", requestOptions)
          .then(() => {
            openMessage(
              "success",
              "Thank you for the feedback! A support personnel will reach to you soon to answer any question you might have"
            );
            sleep(3000).then(() => {
                navigate('/');
            });
          })
          .catch(() => {
            openMessage(
                "error",
                "Sorry! Failed to send feedback, please try again"
              );
          });
          
      }

    return (
    <div className="pt-10">
    <h2 className="font-semibold text-2xl text-headingBlack mb-2">
            Contact Us Form
          </h2>
          <p className="text-sm text-headingBlack mb-0">
            Leave Feedback for Yarlo
          </p>
          {contextHolder}
          <Formik
            initialValues={{
              fullName: '',
              emailAddress: '',
              phoneNumber: '',
              description: '',
            }}
            onSubmit={(values) => {
                sleep(500).then(() => sendContactUs(values));
            }}
            validationSchema={yup.object().shape(validationSchema.contactUs)}
            innerRef={formikRef}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
            }) => (
              <Form>
                <div className="flex mt-6 flex-col w-full">
                  <div className="grid sm:grid-cols-2 sm:gap-4 lg:gap-y-0">
                    <InputField
                      name={"fullName"}
                      inputType={"text"}
                      label={"Name"}
                      placeholder={"Enter Name"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fullName}
                      error={errors.fullName}
                      touched={touched.fullName}
                    />
                    <InputField
                      name={"emailAddress"}
                      inputType={"text"}
                      label={"Email Address"}
                      placeholder={"Enter Email Address"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emailAddress}
                      error={errors.emailAddress}
                      touched={touched.emailAddress}
                      minValue={0}
                    />
                    <InputField
                      name={"phoneNumber"}
                      inputType={"text"}
                      label={"Phone Number"}
                      placeholder={"Enter Phone Number(Optional)"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                      error={errors.phoneNumber}
                      touched={touched.phoneNumber}
                    />
                    
                    <TextArea
                      name={"description"}
                      hasLabel
                      label={"Leave Feedback"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      error={errors.description}
                      touched={touched.description}
                      maxLength={1000}
                    />
                  </div>
                </div>
                <div className="flex flex-col-reverse sm:flex-row justify-between pb-1 pt-6 mt-6 -mx-5 md:-mx-8 lg:-mx-10 px-5 md:px-8 lg:px-10 border-t border-black/[0.1]">
                  <MainButton
                    title={"Submit"}
                    appendStyles={"font-poppins"}
                    onClick={() => formikRef?.current?.handleSubmit()}
                    //isLoading={}
                  />
                </div>
              </Form>
            )}
          </Formik>
          </div>
    );
}