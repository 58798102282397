import React, { useState, useEffect, useCallback } from "react";
import { Drawer, Dropdown, Select, Slider, Space } from "antd";
import { MdFilterList, MdKeyboardArrowDown } from "react-icons/md";
import SelectField from "../Form/SelectField";
import MainButton from "../Buttons/MainButton";
import InputField from "../Form/InputField";
import { useGetDropdownDataQuery } from "../../api/general";
import { isEmpty } from "lodash";
import { mapToOptions } from "../../helpers";
import { ArrowDownIcon } from "../CustomIcons";

function FilterHeader({ categoryName, applyFilters, sort, subCategoryName }) {
  const [filterDrawerOpen, setfilterDrawerOpen] = useState(false);
  const [priceLow, setPriceLow] = useState();
  const [priceHigh, setPriceHigh] = useState();
  const [numBeds, setNumBeds] = useState(undefined);
  const [numBaths, setNumBaths] = useState(undefined);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategory, setSubCategory] = useState(subCategoryName || null);
  const [categories, setCategories] = useState([mapToOptions(['Category'])]);
  const [features, setFeatures] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [category, setCategory] = useState(categoryName || null);
  const { data: dropdownData } = useGetDropdownDataQuery();

  const handleApply = (category1, subCategory1) => {
    let filters = {
      numBedrooms: numBeds === "any" ? undefined : numBeds,
      numBathrooms: numBaths === "any" ? undefined : numBaths,
      startPrice: priceLow,
      endPrice: priceHigh,
      category: category1 || undefined,
      subCategory: subCategory1 || undefined,
      features,
    };
    applyFilters(filters);
    //closeFilterDrawer();
  };

  const waitForMinMax = () => {
    let timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
      //handleApply();
    }
  }

  const openFilterDrawer = () => {
    setfilterDrawerOpen(true);
  };
  const closeFilterDrawer = () => {
    setfilterDrawerOpen(false);
  };

  const setPriceRange = (val) => {
    const [low, high] = val;
    setPriceLow(low);
    setPriceHigh(high);
  };

  const handleSubCategory = (id, val) => {
    setSubCategory(val);
    setShowSubCategoryDropdown(false);
  };

  const handleCategory = (id, val) => {
    setCategory(val);
    setShowCategoryDropdown(false);
  };

  const handleFeatures = (id, val) => {
    setFeatures(val);
  };

  const resetAll = () => {
    setNumBaths(undefined);
    setNumBeds(undefined);
    setPriceRange([0, 50000000]);
    setCategory(null);
    setSubCategory(null);
    setFeatures([]);
  };

  const range = ["any", "1", "2", "3", "4", "4+"];

  useEffect(() => {
    if (!isEmpty(dropdownData)) {
      let subCat;
      let cat = dropdownData?.find((item) => item.dropdownKey === "categories");
      
      setCategories(mapToOptions(cat?.values));
      /*let rsFacilities = dropdownData?.find(
        (types) => types.dropdownKey === "realEstateFeatures"
      );
      let eFacilities = dropdownData?.find(
        (types) => types.dropdownKey === "eventFacilities"
      );
      setFeatureList(
        mapToOptions([...rsFacilities?.values, ...eFacilities.values])
      );*/
      //setSubCategory(undefined);
      switch (category) {
        case "Real Estate":
          subCat = dropdownData?.find(
            (subCat) => subCat.dropdownKey === "realEstateSubCategories"
          );
          setSubCategories(mapToOptions(subCat?.values));
          break;
        case "Vehicles":
          subCat = dropdownData?.find(
            (subCat) => subCat.dropdownKey === "vehicleSubCategories"
          );
          setSubCategories(mapToOptions(subCat?.values));
          break;
        case "Books":
          subCat = dropdownData?.find(
            (subCat) => subCat.dropdownKey === "booksSubCategories"
          );
          setSubCategories(mapToOptions(subCat?.values));
          break;
        case "Outdoors":
          subCat = dropdownData?.find(
            (subCat) => subCat.dropdownKey === "outdoorSubCategories"
          );
          setSubCategories(mapToOptions(subCat?.values));
          break;
        case "Electronics":
          subCat = dropdownData?.find(
            (subCat) => subCat.dropdownKey === "electronicsSubCategories"
          );
          setSubCategories(mapToOptions(subCat?.values));
          break;
        case "Fashion":
          subCat = dropdownData?.find(
            (subCat) => subCat.dropdownKey === "fashionSubCategories"
          );
          setSubCategories(mapToOptions(subCat?.values));
          break;
        case "Tools":
          subCat = dropdownData?.find(
            (subCat) => subCat.dropdownKey === "toolsSubCategories"
          );
          setSubCategories(mapToOptions(subCat?.values));
          break;
        case "Kid Items":
          subCat = dropdownData?.find(
            (subCat) => subCat.dropdownKey === "kidsSubCategories"
          );
          setSubCategories(mapToOptions(subCat?.values));
          break;
        case "Furniture":
          subCat = dropdownData?.find(
            (subCat) => subCat.dropdownKey === "furnitureSubCategories"
          );
          setSubCategories(mapToOptions(subCat?.values));
          break;

        default:
          break;
      }
      setSubCategory(undefined);
      handleApply(category, undefined);
    }
  }, [category, dropdownData]);

  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [showSubCategoryDropdown, setShowSubCategoryDropdown] = useState(false);
  const [sortValue, setSortValue] = useState(0);

  const items = [
    {
      key: "1",
      label: "Price Low to High",
    },
    {
      key: "2",
      label: "Price High to Low",
    },
    {
      key: "3",
      label: "Most Recently Added",
    },
  ];

  const changeSort = (e) => {
    setSortValue(e.key);
    sort(e.key);
  };

  const handleChangeCategory = (id, value) => {
    setCategory(value);
  }

  const handleSubChangeCategory = (id, value) => {
    setSubCategory(value);
    handleApply(category, value);
  }

  return (
    <>
      <div className="md:hidden flex gap-3 items-center justify-end my-6 cursor-pointer hover:text-primaryOrange"
        onClick={openFilterDrawer}
      >
        <MdFilterList size={20} />
        <span className="text-lg font-medium leading-6">Filter</span>
      </div>

      <Drawer
        title={
          <div className="flex w-full justify-end">
            <span className="font-poppins text-lg text-primaryBlack font-semibold">
              Select Filters
            </span>
          </div>
        }
        placement="right"
        onClose={closeFilterDrawer}
        open={filterDrawerOpen}
        headerStyle={{
          borderBottomWidth: 0,
          display: "flex",
          alignItems: "center",
        }}
        width={"100%"}
        rootClassName={"filter-drawer"}
      >
        <div>
          <h5 className="font-semibold mb-8">Price range</h5>
          <Slider
            min={0}
            max={50000000}
            step={100000}
            onChange={setPriceRange}
            value={[priceLow, priceHigh]}
            range
            trackStyle={{ backgroundColor: "#FF7D12" }}
          />
          <div className="grid grid-cols-2 gap-4">
            <InputField
              value={priceLow}
              name={"priceLow"}
              onChange={(e) => {
                setPriceLow(e.target.value);
              }}
              inputType={"number"}
              placeholder={"Enter min"}
            />
            <InputField
              value={priceHigh}
              name={"priceHigh"}
              onChange={(e) => setPriceHigh(e.target.value)}
              inputType={"number"}
              placeholder={"Enter max"}
            />
          </div>
        </div>

        <div className="mt-5">
          <h5 className="font-semibold">Category</h5>
          <SelectField
            id="category"
            onChange={handleCategory}
            value={category}
            options={categories}
            placeholder={"Select category"}
          />
        </div>
        <div className="mt-5">
          <h5 className="font-semibold">Sub Category</h5>
          <SelectField
            id="subCategory"
            onChange={handleSubCategory}
            value={subCategory}
            options={subCategories}
            placeholder={"Select sub category"}
          />
        </div>
        {category === "Real Estate" && subCategory === "Residential" ? (
          <>
            <div className="mt-5">
              <h5 className="font-semibold">Beds</h5>
              <div className="border border-borderGray rounded-lg grid grid-cols-6 font-poppins">
                {range?.map((num, index) => (
                  <BedsRangeBox
                    key={index}
                    number={num}
                    numBeds={numBeds}
                    setNumBeds={setNumBeds}
                  />
                ))}
              </div>
            </div>
            <div className="mt-5">
              <h5 className="font-semibold">Baths</h5>
              <div className="border border-borderGray rounded-lg grid grid-cols-6 font-poppins">
                {range?.map((num, index) => (
                  <BathsRangeBox
                    key={index}
                    number={num}
                    numBaths={numBaths}
                    setNumBaths={setNumBaths}
                  />
                ))}
              </div>
            </div>
          </>
        ) : null}
        {category === "Real Estate" && (
          <div className="mt-5">
            <h5 className="font-semibold">Features</h5>
            <SelectField
              id={"features"}
              placeholder={"Select features & Amenities"}
              onChange={handleFeatures}
              options={featureList}
              value={features}
              isMulti
            />
          </div>
        )}
       {/*<div className="flex mt-16 flex-col md:flex-row items-center justify-between">
          <MainButton
            title={"Apply Filters"}
            size={"lg"}
            appendStyles={"font-poppins"}
            onClick={handleApply}
          />

          <MainButton
            title={"Reset all"}
            size={"lg"}
            appendStyles={"font-poppins mt-4 md:mt-0"}
            variant={"secondary"}
            onClick={resetAll}
          />
        </div>*/}
      </Drawer>

      <div className="w-full hidden md:flex items-center justify-between  pl-0 pr-2 py-0 gap-12 bg-[#F9F9F9] my-6 text-base">
        <div className="flex items-center gap-11">

          <div className='relative flex flex-col font-poppins'>

            {/*<span className="text-base font-medium flex items-center gap-2 text-primaryBlack hover:text-primaryOrange cursor-pointer"
              onClick={handleCategoryClick}
            >
              Category
              <ArrowDownIcon className="w-3 h-3" />
        </span>*/}
            <SelectField options ={ categories } bordered={false} placeholder="Category" onChange={handleChangeCategory}>
              </SelectField>

            {/*showCategoryDropdown && (
              <div className="z-10 absolute -left-1 mt-10 text-sm bg-white px-3 py-2 rounded-t-md border-b-4 border-orange-400 shadow-lg w-48">
                <SelectField
                  id="category"
                  onChange={(id, value) => {
                    handleCategory(id, value);
                    handleApply()
                  }}
                  value={category}
                  options={categories}
                  placeholder={"Select category"}
                />
              </div>
                )*/}

          </div>

          <div className='relative flex flex-col'>
          <SelectField options ={ subCategories } bordered={false} placeholder="Sub Category" onChange={handleSubChangeCategory}>
          </SelectField>
                {/*
            <span className="text-base font-medium flex items-center gap-2 text-primaryBlack hover:text-primaryOrange cursor-pointer"
              onClick={handleSubCategoryClick}
            >
              Sub Category
              <ArrowDownIcon className="w-3 h-3" />
            </span>

            {showSubCategoryDropdown && (
              <div className="z-10 absolute -left-1 mt-10 text-sm bg-white px-3 py-2 rounded-t-md border-b-4 border-orange-400 shadow-lg w-48">
                <SelectField
                  id="category"
                  onChange={(id, value) => {
                    handleSubCategory(id, value);
                    handleApply()
                  }}
                  value={subCategory}
                  options={subCategories}
                  placeholder={"Select sub category"}
                />
              </div>
            )}
*/}
          </div>

          <div className="flex items-center gap-2 text-primaryBlack">
            <span className="text-base font-medium">Price:</span>

            <div className="flex items-center gap-2">
              <input
                type="text"
                placeholder="Min"
                className="text-sm font-medium p-1 rounded-full border-[0.09375rem] border-[#E2E1E1] bg-transparent focus:outline-1 focus:outline-orange-500 w-20 placeholder:text-center placeholder:text-primaryBlack text-center"
                value={priceLow}
                name={"priceLow"}
                onChange={(e) => {
                  setPriceLow(e.target.value);
                  //handleApply();
                }}
                onKeyUp={waitForMinMax(3000) }
              />
              <span>-</span>
              <input
                type="text"
                placeholder="Max"
                className="text-sm font-medium p-1 rounded-full border-[0.09375rem] border-[#E2E1E1] bg-transparent focus:outline-1 focus:outline-orange-500 w-20 placeholder:text-center placeholder:text-primaryBlack text-center"
                value={priceHigh}
                name={"priceHigh"}
                onChange={(e) => {
                  setPriceHigh(e.target.value);
                  //handleApply();
                }}
                onKeyUp={waitForMinMax( 3000) }
              />
            </div>

          </div>
        </div>

        {/* Sort by price or recently added */}
        <Dropdown
          menu={{
            onClick: changeSort,
            items,
            style: { fontFamily: "Poppins" },
          }}
        >
          <div className="group flex gap-2 items-center cursor-pointer text-base font-medium">
            Sort By:
            <span className="text-primaryOrange">{items.find((item) => item.key === sortValue)?.label}</span>
            <ArrowDownIcon className="w-3 h-3" />
          </div>
        </Dropdown>

      </div>
    </>
  );
}

export default FilterHeader;

const BedsRangeBox = ({ number, numBeds, setNumBeds }) => (
  <div
    className={`border-r py-3 px-4 lg:py-4 lg:px-8 last:border-0 first:rounded-l-md last:rounded-r-md flex items-center justify-center cursor-pointer ${numBeds === number && "bg-primaryOrange text-white"
      }`}
    onClick={() => setNumBeds(number)}
  >
    <span className="font-medium text-base">{number}</span>
  </div>
);

const BathsRangeBox = ({ number, numBaths, setNumBaths }) => (
  <div
    className={`border-r py-3 px-4 lg:py-4 lg:px-8 last:border-0 first:rounded-l-md last:rounded-r-md flex items-center justify-center cursor-pointer ${numBaths === number && "bg-primaryOrange text-white"
      }`}
    onClick={() => setNumBaths(number)}
  >
    <span className="font-medium text-base">{number}</span>
  </div>
);
