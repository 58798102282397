import React from "react";
import TitleHead from "./title";
import "./admin.css";

export default function AboutUs() {
  return (
    <div>
      <TitleHead title="About Us" />

      <div className="w-[90%] md:w-[85%] lg:w-[85%] mx-auto mt-7">
        <p className="aboutus">
          Yarlo’s mission is to change the way we rent assets and provide tools
          to make renting easier & faster.
        </p>
        <p className="aboutus">
          In 2022, Yarlo entered the rental industry with the goal to redefine
          the way we lease assets by creating a unified online marketplace for
          rental services. To solve these, we&#39;ve pioneered a Consolidated Rental
          Platform (“CRP”) to create an on-demand, e-commerce-like experience
          that makes working with agents and asset owners as easy as buying
          something online.
        </p>
      </div>
    </div>
  );
}
