import React from "react";
import { Layout } from "antd";
import MainHeader from "./Header";
import Footer from "./Footer";

export default function CustomLayout({ children }) {
  const { Content } = Layout;
  return (
    <div>
        <Layout>
          <MainHeader />
          <Content className="w-full min-h-screen max-w-[120rem] px-4 sm:px-6 md:px-8 xl:px-32 2xl:px-36 2xl:mx-auto">
            {children}
          </Content>
          <Footer />
        </Layout>
    </div>
  );
}
