import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../Layout";
import HeaderComponent from "../Layout/HeaderComponent";
import { PinDropIcon } from "../CustomIcons";
import {
  appendImageUrl,
  copyToClipboard,
  formatAmount,
  mapToOptions,
} from "../../helpers";
import { Form, Formik } from "formik";
import * as yup from "yup";
import SelectField from "../Form/SelectField";
import MainButton from "../Buttons/MainButton";
import { useGetDropdownDataQuery } from "../../api/general";
import validationSchema from "../../helpers/validationSchema";
import { MdContentCopy } from "react-icons/md";
import useMessageAlert from "../Message";
import { useGetListingByIDQuery, useAddPaidListingMutation } from "../../api/listing";
import Spinner from "../Spinner";
import { Modal } from "antd";
import { PaystackButton } from 'react-paystack'
import { useSelector } from "react-redux";
import { usePostTransactionMutation } from "../../api/transaction";

export default function PromoteListing() {
  const { pathname } = useLocation();
  const formikRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const { data: dropdownData } = useGetDropdownDataQuery();
  const { data, isLoading, isError } = useGetListingByIDQuery(
    pathname.split("/")[2]
  );
  const listingId = pathname.split("/")[2];
  const { openMessage, contextHolder } = useMessageAlert();
  const proceed = () => {
    formikRef.current.handleSubmit();
  };
  const { profile } = useSelector((state) => state.user);
  const publicKey = process.env.REACT_APP_PAYSTACK_CLIENT_ID;
  const email = profile?.emailAddress;
  const [amount, setAmount] = useState(0);
  const name =  profile?.firstName + " " + profile?.lastName;
  const phone = profile?.phoneNumber;
  const refId =   listingId.split('-')[listingId.split('-').length-1].toUpperCase();
  const [ paymentMethod, setPaymentMethod] = useState("");
  const [ promoType, setPromoType] = useState("");
  const [postTransaction,  addTransaction ] = usePostTransactionMutation();
  const [ callPaidList ] = useAddPaidListingMutation();
  const componentProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Proceed to Payment",
    reference: refId +'_'+ new Date().getTime(),
    onSuccess: () =>
      addTransactionApi(),
  }

  const addTransactionApi= async ()=>  {
    let response = await postTransaction({
      userId: profile?.userId,
      patch: {
        amount: amount/100,
        transactionType: 'Promote_Payment',
        listingId: listingId,
        transactionHandler: 'Paystack',
        transactionDuration: promoType,
        transactionHandlerId: refId,
        refId: refId
      },
    }).unwrap();
    openMessage(
      "success",
      "We are processing your payment, your listing will be promoted in our adverts in less than 2 hours!!");
      callPaidListing();
  }

  const callPaidListing = async () => {
    await callPaidList({
      userId: profile?.userId, listingId: listingId,
    }).unwrap();
  }

  const paymentTypes = mapToOptions(
    dropdownData?.find((item) => item.dropdownKey === "paymentTypes").values ||
      []
  );
  const selectTypes = new Map(
    dropdownData?.find((item) => item.dropdownKey === "ecoPromotionTypes")
      .values.map(ele => [ele.split("|")[0], ele.split("|")[1],]) || []
  );
  const promoTypes = mapToOptions(Array.from(selectTypes.keys()) || []);

  const bankDetails =
    dropdownData?.find((item) => item.dropdownKey === "bankDetails").values ||
    [];


  const handleCopyClicked = (text) => {
    copyToClipboard(text);
    openMessage("success", "copied to clipboard");
  };

  const openPaystack=()=> {
    return (<><div><PaystackButton {...componentProps} /></div></>)
  }

  const clickButton = () => {
    if (paymentMethod === "Paystack") {
      setOpenModal(true);
    } else {
      openPaystack();
    }
    
  }

  const changeAmount = (promotype) => {
    setAmount(selectTypes.get(promotype) * 100);
  }

  useEffect(() => {
    if (isError) {
      openMessage("error", "Failed to retrieve listing!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <Layout>
      <HeaderComponent
        pageTitle={"Promote your listing on Yarlo"}
        pageDescription={""}
      />
      {contextHolder}
      <h1 className="mt-10 mb-5 font-poppins text-[1.375rem] md:text-[1.5rem] lg:text-[1.75rem] xl:text-[2rem] font-semibold">
        Promote Advert
      </h1>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex justify-center w-full max-w-3xl mx-auto py-10">
            <div className="group font-poppins drop-shadow-sm shadow-xl shadow-black/5 rounded-2xl bg-white p-3 flex flex-col md:flex-row md:items-center w-full">
              <img
                src={appendImageUrl(data?.imagesLocation?.[0])}
                alt={data?.listingHeading || "listing image"}
                className="h-[11.5rem] md:h-[12.5rem] xl:h-[13rem] desktop:h-[14rem] rounded-[10px] w-full max-w-[24rem] object-cover"
              />
              <div className="mt-3 md:ml-4 desktop:ml-6 flex flex-col justify-between md:h-[70%] md:mt-0">
                <p
                  className="text-lg lg:text-2xl font-semibold mb-3 text-headingBlack truncate"
                  title={data?.listingHeading}
                >
                  {data?.listingHeading}
                </p>
                <div>
                  <div className="flex items-center mb-2 md:mb-3 lg:mb-5 gap-x-1.5">
                    <PinDropIcon className="w-4 h-4 md:w-5 md:h-5" />
                    <p className="text-base text-textGray mb-0">
                      {data?.localGovernmentArea}
                      {", "}
                      {data?.state}
                    </p>
                  </div>
                  <p className="text-primaryOrange tracking-normal text-base font-semibold mb-[0]">
                    ₦{formatAmount(data?.rentAmount)}
                    <span className="text-[1rem] font-medium text-black">
                      {" "}
                      {data?.rentalPeriod}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="font-poppins drop-shadow-sm shadow-xl shadow-black/5 rounded-2xl bg-white flex justify-center w-full max-w-3xl mx-auto p-4 md:p-8 lg:p-10">
            <Formik
              initialValues={{
                promoType: "",
                paymentMethod: "",
                amount: 0
              }}
              innerRef={formikRef}
              onSubmit={(values) => {
                setPaymentMethod(values.paymentMethod);
                setPromoType(values.promoType);
                setAmount(selectTypes.get(values.promoType));
                if (values.paymentMethod === "Bank Transfer") {
                  setOpenModal(true);
                }  else {
                  openPaystack();
                }
                
              }}
              validationSchema={yup
                .object()
                .shape(validationSchema.promoteListing)}
            >
              {({ values, setFieldValue, errors, handleBlur, touched }) => (
                <Form className="w-full">
                  <div className="flex flex-col gap-2">
                    <SelectField
                      id="promoType"
                      hasLabel
                      label="Promotion Type"
                      onChange={setFieldValue}
                      onChangeComplete={changeAmount(values.promoType)}
                      onBlur={handleBlur}
                      touched={touched.promoType}
                      value={values.promoType}
                      error={errors.promoType}
                      options={promoTypes}
                    />
                    <SelectField
                      id="paymentMethod"
                      hasLabel
                      label="Payment Method"
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      touched={touched.paymentMethod}
                      value={values.paymentMethod}
                      error={errors.paymentMethod}
                      options={paymentTypes}
                    />
                    {values.paymentMethod === "Bank Transfer" &&
                      bankDetails.length > 0 && (
                        <div>
                          <p className="text-headingBlack text-lg mb-1 font-semibold">
                            Bank Details
                          </p>
                          <div className="flex flex-col gap-1">
                            <p className="text-headingBlack text-base mb-0">
                              Name: {bankDetails?.[0]}
                            </p>
                            <p className="text-headingBlack text-base mb-0">
                              Bank: {bankDetails?.[1]}
                            </p>
                            <div className="flex items-center justify-between">
                              <p className="text-headingBlack text-base mb-0">
                                Account: {bankDetails?.[2]}
                              </p>
                              <MdContentCopy
                                className="w-5 h-5 text-headingBlack cursor-pointer"
                                onClick={() =>
                                  handleCopyClicked(bankDetails?.[2] || "")
                                }
                              />
                            </div>
                            <div className="flex items-center justify-between">
                              <p className="text-headingBlack text-base mb-0">
                                REF ID: {refId}
                              </p>
                              <MdContentCopy
                                className="w-5 h-5 text-headingBlack cursor-pointer"
                                onClick={() => handleCopyClicked(refId)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    <div className="flex justify-between py-3 border-y border-[#dcdbdd]">
                      <p className="text-headingBlack text-lg mb-0">Total</p>
                      <p className="text-primaryOrange font-medium text-lg mb-0">
                        ₦
                        {
                        values.promoType !== ""
                          ? formatAmount(
                              parseFloat(selectTypes.get(values.promoType))
                            )
                          : "0.00"}
                      </p>
                    </div>
                  </div>
                  
                  <div className="text-center mt-8">
                  { values.paymentMethod === "Bank Transfer" ?
                    <MainButton
                      title={"Proceed to Payment"}
                      size={"lg"}
                      appendStyles={"font-poppins max-w-full"}
                      onClick={proceed}
                    /> :
                    <PaystackButton 
                      isLoading={addTransaction.isLoading}      
                      className="text-center mt-8 font-semibold inline-flex items-center justify-center bg-primaryOrange text-white hover:bg-primaryOrange/50 focus:bg-primaryOrange/50 font-poppins max-w-full rounded-lg text-base px-20 py-3 h-12" {...componentProps} 
                    />
                  }
                    </div>
                  
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
      <Modal
        open={openModal}
        title={""}
        onCancel={() => setOpenModal(false)}
        wrapClassName="preview-modal"
        footer={null}
      >
        <div className="py-4 text-center">
          <p className="text-lg text-primaryBlack font-semibold">IMPORTANT!</p>
          <p className="text-lg text-primaryBlack">
            For transfers to our bank, always include{" "}
            <span className="font-semibold">REF ID</span> in the narration.
            <br /> You will get a confirmation email once we receive the funds
          </p>
          <MainButton
            title={"Close"}
            variant={"secondary"}
            onClick={() => setOpenModal(false)}
            appendStyles={"text-primaryOrange hover:text-primaryBlack"}
          />
        </div>
      </Modal>
    </Layout>
  );
}
