import React from "react";
import { Link } from "react-router-dom";
import imageUrls from "../../LazyLoadImg/imageUrls";
import LazyLoadImg from "../../LazyLoadImg";

function BrowseSection() {
  const categories = [
    {
      img: imageUrls.realEstate,
      title: "Real Estate",
    },
    {
      img: imageUrls.vehicles,
      title: "Vehicles",
    },
    {
      img: imageUrls.fashion,
      title: "Fashion",
    },
    {
      img: imageUrls.electronics,
      title: "Electronics",
    },
    {
      img: imageUrls.furniture,
      title: "Furniture",
    },
    {
      img: imageUrls.kids,
      title: "Kid Items",
    },
    {
      img: imageUrls.outdoors,
      title: "Outdoors",
    },
    {
      img: imageUrls.tools,
      title: "Tools",
    },
  ];

  const CategoryCard = ({ img, title }) => (
    <>
      <Link to={`/listings?category=${title}`}>
        <div className="category-card rounded-[28px] relative w-full hover:bg-primaryOrange/75 p-2">
          <LazyLoadImg
            src={img}
            alt={title}
            className="rounded-[24px] border border-white w-full object-fit"
          />

          <p className="text-primaryBlack text-center font-poppins font-medium text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl mt-4 mb-0">
            {title}
          </p>
        </div>
      </Link>
    </>
  );

  return (
    <div className="pt-12">
      <div className="flex items-center justify-between">
        <div className="w-4/5">
          <h1 className="font-poppins text-[1.375rem] md:text-[1.5rem] lg:text-[1.75rem] xl:text-[2rem] font-bold">
            All Categories
          </h1>
          <p className="text-base font-sans text-placeholder mb-0">
            Select a category below to view rental services by type.
          </p>
        </div>
        <Link
          to={"/listings?keyword=all"}
          className={
            "text-primaryOrange text-right font-poppins text-lg font-semibold hover:text-primaryOrange/50 w-1/5"
          }
        >
          View all
        </Link>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5 md:gap-6 lg:gap-5 xl:gap-6 2xl:gap-10 mt-12">
        {categories.length &&
          categories.map((item, index) => {
            return (
              <CategoryCard key={index} title={item.title} img={item.img} />
            );
          })}
      </div>
    </div>
  );
}

export default BrowseSection;
