import { createContext, useState } from 'react';

const MyContext = createContext();

const MyProvider = ({ children }) => {
    const [replaceText, setReplaceText] = useState();
    const [isReplacingId, setIsReplacingId] = useState(false);
    const [breadcrumbPaths, setBreadcrumbPaths] = useState([]);

    return (
        <MyContext.Provider value={{ replaceText, setReplaceText, isReplacingId, setIsReplacingId, setBreadcrumbPaths, breadcrumbPaths }}>
            {children}
        </MyContext.Provider>
    )
}

export { MyContext, MyProvider }
