import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";

export default function BottomNav() {
  function getItem(label, key, className, items, type) {
    return {
      key,
      className,
      items,
      label,
      type,
    };
  }
  const items = [
    getItem(
      <Link to={"/listings?category=Real Estate"}>{"Real Estate"}</Link>,
      1,
      "pr-2 md:pr-4 py-6 px-0 lg:pr-0 font-poppins text-base text-placeholder after:hidden before:hidden"
    ),
    getItem(
      <Link to={"/listings?category=Vehicles"}>{"Vehicles"}</Link>,
      2,
      "pr-2 md:pr-4 py-6 px-0 lg:pr-0 font-poppins text-base text-placeholder after:hidden before:hidden"
    ),
    getItem(
      <Link to={"/listings?category=Fashion"}>{"Fashion"}</Link>,
      3,
      "pr-2 md:pr-4 py-6 px-0 lg:pr-0 font-poppins text-base text-placeholder after:hidden before:hidden"
    ),
    getItem(
      <Link to={"/listings?category=Electronics"}>{"Electronics"}</Link>,
      4,
      "pr-2 md:pr-4 py-6 px-0 lg:pr-0 font-poppins text-base text-placeholder after:hidden before:hidden"
    ),
    getItem(
      <Link to={"/listings?category=Furniture"}>{"Furniture"}</Link>,
      5,
      "pr-2 md:pr-4 py-6 px-0 lg:pr-0 font-poppins text-base text-placeholder after:hidden before:hidden"
    ),
    getItem(
      <Link to={"/listings?category=Kid Items"}>{"Kid Items"}</Link>,
      6,
      "pr-2 md:pr-4 py-6 px-0 lg:pr-0 font-poppins text-base text-placeholder after:hidden before:hidden"
    ),
    getItem(
      <Link to={"/listings?category=Books"}>{"Books"}</Link>,
      7,
      "pr-2 md:pr-4 py-6 px-0 lg:pr-0 font-poppins text-base text-placeholder after:hidden before:hidden"
    ),
    getItem(
      <Link to={"/listings?category=Outdoors"}>{"Outdoors"}</Link>,
      8,
      "pr-2 md:pr-4 py-6 px-0 lg:pr-0 font-poppins text-base text-placeholder after:hidden before:hidden"
    ),
    getItem(
      <Link to={"/listings?category=Tools"}>{"Tools"}</Link>,
      9,
      "pr-2 md:pr-4 py-6 px-0 lg:pr-0 font-poppins text-base text-placeholder after:hidden before:hidden md:pr-0"
    ),
  ];

  return (
    <div className="flex flex-col w-full gap-4 justify-start items-start">
      
      <div className="w-full px-4 sm:px-6 md:px-8 xl:px-32 2xl:px-36 2xl:mx-auto bg-[#FFF8F3]">
        <Menu
          mode="horizontal"
          inlineIndent={0}
          className="border-0 flex max-w-[102rem] 2xl:mx-auto justify-between items-center before:hidden after:hidden"
          items={items}
        />
      </div>

      <Breadcrumb location={window.location}/>

    </div>
  );
}
