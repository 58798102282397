import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  listings: [],
  newListing: {},
  totalPages: 1,
  currentPage: 0,
};

const listingSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {
    setListings: (state, { payload }) => {
      state.listings = payload;
      state.totalPages = payload.length;
      state.currentPage = 0;
    },
    updateNewListing: (state, { payload }) => {
      state.newListing = { ...state.newListing, ...payload };
    },
    resetNewListing: (state, { payload }) => {
      state.newListing = payload;
    },
    incrementCurrentPage: (state) => {
      state.currentPage =
        state.currentPage < state.totalPages - 1
          ? state.currentPage + 1
          : state.currentPage;
    },
    decrementCurrentPage: (state) => {
      state.currentPage =
        state.currentPage > 0 ? state.currentPage - 1 : state.currentPage;
    },
    logoutListing: () => initialState,
  },
});

export const {
  setListings,
  updateNewListing,
  resetNewListing,
  logoutListing,
  incrementCurrentPage,
  decrementCurrentPage,
} = listingSlice.actions;

export default listingSlice.reducer;
