// import { isEmpty } from "lodash";
let baseUrl = process.env.REACT_APP_BASE_URL;

export const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/; //eslint-disable-line

export const digitRegExp = /^[0-9]*$/;
//export const phoneNumberRegExp = /^+?[0-9]*$/;
export const appendImageUrl = (location, type = "listing") => {
  return `${baseUrl}/api/${type}/image?${
    type !== "listing" ? `imageName=${location}` : `location=${location}`
  }`;
};

export function formatAmount(amount) {
  let num = "0";
  if (amount) {
    num = amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  return num;
}

export function mapToOptions(arr) {
  return arr.map((val) => ({ label: val, value: val })) || [];
}

export const copyToClipboard = (text) => {
  if ("clipboard" in navigator) {
    return navigator.clipboard.writeText(text);
  }
};
