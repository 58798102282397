import React from "react";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as yup from "yup";
import MainButton from "../Buttons/MainButton";
import InputField from "../Form/InputField";
import TitleHead from "./title";
import validationSchema from "../../helpers/validationSchema";
import { useChangePasswordMutation } from "../../api/auth";
import useMessageAlert from "../Message";

function Privacy() {
  const { profile } = useSelector((state) => state.user);
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const { openMessage, contextHolder } = useMessageAlert();

  const onSubmit = (values) => {
    let patch = {
      userId: profile?.userId,
      currentPassword: values?.password,
      newPassword: values?.newPassword,
    };
    changePassword(patch)
      .unwrap()
      .then(() => {
        openMessage("success", "Password changed successfully!");
      })
      .catch((error) => {
        openMessage(
          "error",
          error?.data?.errorMessage || "Failed to update password"
        );
      });
  };
  return (
    <div>
      {contextHolder}
      <TitleHead title="Change Password" />
      <Formik
        initialValues={{
          password: "",
          newPassword: "",
        }}
        onSubmit={(values) => {
          onSubmit(values);
        }}
        validationSchema={yup.object().shape(validationSchema.changePassword)}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          handleSubmit,
          touched,
        }) => (
          <Form className="w-full">
            <div className="grid md:grid-cols-2 gap-y-2 md:gap-x-4 lg:gap-x-6 xl:gap-x-8 mt-8 mx-5 lg:mx-10 xl:mx-[52px]">
              <InputField
                name={"password"}
                inputType={"password"}
                label={"Current Password"}
                placeholder={"Current Password"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={errors.password}
                touched={touched.password}
                className={"rounded-full"}
              />

              <InputField
                name={"newPassword"}
                inputType={"password"}
                label={"New Password"}
                placeholder={"New Password"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword}
                error={errors.newPassword}
                touched={touched.newPassword}
              />
            </div>
            <div
                className="flex items-center justify-center w-full mt-12">
              <MainButton
                title={"Update Password"}
                size={"md"}
                variant={"primary-rounded"}
                appendStyles={"font-poppins"}
                onClick={handleSubmit}
                isLoading={isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Privacy;
