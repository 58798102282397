import Home from "./component/Home/index";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import PostListing from "./component/PostListing";
import Messages from "./component/Messages";
import PrivacyPolicy from "./component/Privacy";
import Terms from "./component/Terms";
import Admin from "./component/Profile";
import Listing from "./component/Listing";
import Listings from "./component/Listings";
import BookListing from "./component/BookListing";
import ScrollToTop from "./ScrollToTop";
import AboutUs from "./component/AboutUs";
import ContactForm from "./component/Contact";

import CookiePolicy from "./component/Cookie"
import Promote from "./component/Promote";
import ReactGA from 'react-ga';
import { GoogleOAuthProvider } from '@react-oauth/google';
ReactGA.initialize('G-EQT21JPBW9');

function App() {    
  return (
    <GoogleOAuthProvider clientId="86946734773-jdv686va0ug4r4a9jgq5s2g04347scjr.apps.googleusercontent.com">
    
    <BrowserRouter>
      <HelmetProvider>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/listing/:id" element={<Listing />} />
            <Route path="/listings" element={<Listings />} />
            <Route path="/book-listing" element={<BookListing />} />
            <Route path="/post" element={<PostListing />} />
            <Route path="/promote/:id" element={<Promote />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/messages/:id/:listingId?" element={<Messages />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms_of_service" element={<Terms />} />
            <Route path="/cookies_policy" element={<CookiePolicy />} />
            <Route path="/contact_us" element={<ContactForm />} />
            <Route
              exact
              path="/admin/*"
              element={<Admin />}
              component={Admin}
            />
            <Route path="/about-us" element={<AboutUs />} />
          </Routes>
        </ScrollToTop>
      </HelmetProvider>
    </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
