import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { MailIcon, PhoneIconAlt, SendIconAlt } from "../CustomIcons";
import useMessageAlert from "../Message";
import { useDispatch } from "react-redux";
import { setNewsletter } from "../../slice/userSlice";
import LazyLoadImg from "../LazyLoadImg";
import imageUrls from "../LazyLoadImg/imageUrls";

function Footer() {
  const [email, setEmail] = useState("");
  const { openMessage, contextHolder } = useMessageAlert();
  const dispatch = useDispatch();

  async function sendNewsLetterInterest() {
    var raw = JSON.stringify({
      emailAddress: email,
    });
    var requestOptions = {
      method: "POST",
      body: raw,
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
      }),
      redirect: "follow",
    };

    fetch("https://api-web.yarlo.net/api/interest/connect", requestOptions)
      .then(() => {
        openMessage(
          "success",
          "Thank you! A support personnel will reach to you soon to answer any question you might have"
        );
        dispatch(setNewsletter(true));
        setEmail("");
      })
      .catch(() => setEmail(""));
  }

  const handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  return (
    <footer className="w-full font-sans">
      {contextHolder}
      <div className="max-w-[120rem] px-4 sm:px-6 md:px-8 xl:px-32 2xl:px-36 2xl:mx-auto py-20 lg:py-24 xl:py-28 desktop:py-[10rem] grid grid-cols-2 md:grid-cols-6 gap-6 md:gap-3 lg:gap-5 xl:gap-6 desktop:gap-8">
        <div className="col-span-2 mb-8 md:mb-0 text-center md:text-left">
          <Link
            to="/"
            className="inline-block md:mr-6 lg:mr-8 focus:outline focus:outline-2 focus:outline-primaryOrange/50"
          >
            <LazyLoadImg
              src={imageUrls.logo}
              className="h-9 w-[5.25rem]"
              alt={"yarlo logo"}
            />
          </Link>
          <div className="col-span-1 pt-3.5">
            <h2 className="mb-6 text-xl font-bold text-headingBlack">Popular Categories</h2>
            <ul className="text-gray-600 dark:text-gray-400">
            <li className="mb-4">
              <a
                className="text-headingBlack font-poppins text-base hover:text-primaryOrange focus:text-primaryOrange"
                href="/listings?category=Real Estate&propertyType=Shortlets"
              >
                Shortlets
              </a>
            </li>
            <li className="mb-4">
              <a
                className="text-headingBlack font-poppins text-base hover:text-primaryOrange focus:text-primaryOrange"
                href="/listings?category=Vehicles&subcategory=Cars @ SUVs"
              >
                Car rentals
              </a>
            </li>
            <li className="mb-4">
              <a
                className="text-headingBlack font-poppins text-base hover:text-primaryOrange focus:text-primaryOrange"
                href="/listings?category=Electronics&subcategory=Games"
              >
                Game rentals
              </a>
            </li>
            <li className="mb-4">
              <a
                className="text-headingBlack font-poppins text-base hover:text-primaryOrange focus:text-primaryOrange"
                href="/listings?category=Tools&subcategory=Power Tools"
              >
                Power Tools rentals
              </a>
            </li>
          </ul>
          </div>
          {/*
          <p className="font-poppins text-base text-placeholder mt-7 mb-0">
            Yarlo’s mission is to change the way we rent assets and provide
            tools to make it easier and faster.
          </p>
          <a
            href="tel:+2348022529205"
            className="flex justify-center md:justify-start items-center text-headingBlack hover:text-primaryOrange focus:outline focus:outline-2 focus:outline-primaryOrange/50 mt-6"
          >
            <PhoneIconAlt className="w-5 h-5" />
            <span className="ml-4 text-base">+234 802 252 9205</span>
          </a>
          <a
            href="mailto:support@yarlo.co"
            className="flex justify-center md:justify-start items-center text-headingBlack hover:text-primaryOrange focus:outline focus:outline-2 focus:outline-primaryOrange/50 mt-4"
          >
            <MailIcon className="w-5 h-5" />
            <span className="ml-4 text-base">support@yarlo.co</span>
          </a>*/}
        </div>
        <div className="col-span-1 pt-12">
          <h2 className="mb-6 text-xl font-bold text-headingBlack">Legal</h2>
          <ul className="text-gray-600 dark:text-gray-400">
            <li className="mb-4">
              <a
                className="text-headingBlack font-poppins text-base hover:text-primaryOrange focus:text-primaryOrange"
                href="/terms_of_service"
              >
                Terms of Service
              </a>
            </li>
            <li className="mb-4">
              <a
                className="text-headingBlack font-poppins text-base hover:text-primaryOrange focus:text-primaryOrange"
                href="/privacy-policy"
              >
                Privacy Policy
              </a>
            </li>
            <li className="mb-4">
              <a
                className="text-headingBlack font-poppins text-base hover:text-primaryOrange focus:text-primaryOrange"
                href="/cookies_policy"
              >
                Cookie Policy
              </a>
            </li>
          </ul>
        </div>
        <div className="col-span-1 pt-12">
          <h2 className="mb-6 text-xl font-bold text-headingBlack">Company</h2>
          <ul className="text-gray-600 dark:text-gray-400">
            <li className="mb-4">
              <a
                className="text-headingBlack font-poppins text-base hover:text-primaryOrange focus:text-primaryOrange"
                href="/about-us"
              >
                About
              </a>
            </li>
            <li className="mb-4">
              <a
                className="text-headingBlack font-poppins text-base hover:text-primaryOrange focus:text-primaryOrange"
                href="/about-us#how-it-works"
              >
                How it Works
              </a>
            </li>
            <li className="mb-4">
              <a
                className="text-headingBlack font-poppins text-base hover:text-primaryOrange focus:text-primaryOrange"
                href="/about-us#faq"
              >
                FAQ
              </a>
            </li>
            <li className="mb-4">
              <a
                className="text-headingBlack font-poppins text-base hover:text-primaryOrange focus:text-primaryOrange"
                href="/contact_us"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="col-span-2 pt-12">
          <h2 className="mb-6 text-xl text-center md:text-left font-bold text-headingBlack">
            Get in Touch
          </h2>
          <form
            className="flex items-center search-bar w-full"
            onSubmit={(e) => {
              e.preventDefault();
              sendNewsLetterInterest();
            }}
          >
            <label htmlFor="get-in-touch" className="sr-only">
              Connect with us
            </label>
            <div className="relative w-full">
              <input
                type="text"
                name="get-in-touch"
                className="border border-[#D4CBCB]/50 placeholder:text-[#1F1A16] text-sm font-poppins rounded-full focus:outline focus:outline-2 focus:outline-primaryOrange/50 block w-full px-6 py-5"
                placeholder="Email Address"
                value={email}
                required
                onChange={handleChange}
              />
              <button
                type="submit"
                aria-label="get in touch"
                className="absolute right-3 bottom-2 text-white focus:ring-4 focus:outline-none rounded-full bg-primaryOrange text-sm px-4 py-3 border border-primaryOrange"
              >
                <SendIconAlt className="w-3 h-3 text-white" />
              </button>
            </div>
          </form>
          <h2 className="my-6 text-xl text-center md:text-left font-bold text-headingBlack">
            Social Media
          </h2>
          <div className="flex items-center justify-center md:justify-start mt-6">
            <a
              href="https://www.facebook.com/profile.php?id=100082862089014"
              target={"_blank"}
              rel="noreferrer"
              aria-label="Yarlo on Facebook"
              className="inline-block rounded-full p-3 bg-primaryOrange/[0.1] mr-2 lg:mr-4 xl:mr-5"
            >
              <FaFacebookF className="text-primaryOrange text-lg" />
            </a>
            <a
              href="https://instagram.com/yarlo.co?igshid=YmMyMTA2M2Y="
              target={"_blank"}
              rel="noreferrer"
              aria-label="Yarlo on Instagram"
              className="inline-block rounded-full p-3 bg-primaryOrange/[0.1] mr-2 lg:mr-4 xl:mr-5"
            >
              <FaInstagram className="text-primaryOrange text-lg" />
            </a>
            <a
              href="https://twitter.com/yarlo_co"
              target={"_blank"}
              rel="noreferrer"
              aria-label="Yarlo on Twitter"
              className="inline-block rounded-full p-3 bg-primaryOrange/[0.1] mr-2 lg:mr-4 xl:mr-5"
            >
              <FaTwitter className="text-primaryOrange text-lg" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCSGmNZof5XxlDVZgGi8BFfA"
              target={"_blank"}
              rel="noreferrer"
              aria-label="Yarlo on Twitter"
              className="inline-block rounded-full p-3 bg-primaryOrange/[0.1] mr-2 lg:mr-4 xl:mr-5"
            >
              <FaYoutube className="text-primaryOrange text-lg" />
            </a>
          </div>
        </div>
      </div>
      <div className="text-center bg-[#D9D9D9]/[0.15] py-6">
        <p className="text-sm text-headingBlack mb-0">
          © 2023 - All rights reserved
        </p>
      </div>
    </footer>
  );
}

export default Footer;
