import React from "react";
import imageUrls from "../LazyLoadImg/imageUrls";
import LazyLoadImg from "../LazyLoadImg";

function DownloadApp() {
  return (
    <div
      className="py-14 lg:py-16 xl:py-20 -mx-4 sm:-mx-6 md:-mx-8 xl:-mx-32 2xl:-mx-36 2xl:mx-auto px-4 sm:px-6 md:px-8 xl:px-32 2xl:px-36"
      style={{
        background: `url(${imageUrls.appMockupBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <h1 className="text-white text-center font-sans font-bold text-[1.875rem] xl:text-[2.125rem] mb-4 lg:mb-6">
        Available on iOS & Android
      </h1>
      <p className="text-white text-center font-sans text-lg lg:text-xl xl:text-2xl max-w-[49rem] mb-0 mx-auto">
        Open a new Yarlo account from your mobile and follow the steps to
        register for an account
      </p>
      <div className="flex flex-col md:flex-row items-center justify-center mt-6">
        <a
          href="https://play.google.com/store/apps/details?id=com.yarlo.mobile"
          target="_blank"
          rel="noreferrer"
        >
          <LazyLoadImg
            src={imageUrls.playStore}
            alt="get yarlo on google play store"
            className="w-[11.75rem] h-[3.25rem] lg:w-[13.125rem] lg:h-[3.625rem]"
          />
        </a>
        <a
          href="https://apps.apple.com/us/app/yarlo/id1642060553"
          target="_blank"
          rel="noreferrer"
          className="mt-6 md:mt-0 md:ml-6"
        >
          <LazyLoadImg
            src={imageUrls.appStore}
            alt="get yarlo on apple app store"
            className="w-[11.75rem] h-[3.25rem] lg:w-[13.125rem] lg:h-[3.625rem]"
          />
        </a>
      </div>
    </div>
  );
}

export default DownloadApp;
