import React from "react";
import { Steps } from "antd";
import "./steps.css";
import AboutPost from "./AboutPost";
import ItemDetails from "./ItemDetails";
import Pricing from "./Pricing";
import { useSelector } from "react-redux";

export default function Stepper() {
  const { count } = useSelector((state) => state.counter);
  const { Step } = Steps;

  const steps = [
    {
      title: "About Post",
      content: <AboutPost />,
    },
    {
      title: "Item Details",
      content: <ItemDetails />,
    },
    {
      title: "Pricing",
      content: <Pricing />,
    },
  ];
  return (
    <div className="bg-white mt-16 mb-24 rounded-2xl max-w-[56.5rem] mx-auto">
      <div className="py-4 border-b border-black/[0.1]">
        <Steps current={count} type="navigation" className="listing-nav-steps">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </div>
      <div className="steps-content py-5 px-5 md:px-8 lg:px-10">
        {steps[count].content}
      </div>
    </div>
  );
}
