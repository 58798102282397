import React from 'react';
import FeatureTag from './FeatTag';
import { IoMdSnow, IoIosWater, IoIosWifi } from "react-icons/io";
import { GiCarSeat, GiLayeredArmor, GiOfficeChair, GiSteeringWheel } from "react-icons/gi";
import { BsAndroid2, BsApple, BsFillAlarmFill, BsFillFuelPumpFill } from "react-icons/bs";
import { CgGym, CgScreen, CgSmartHomeRefrigerator } from "react-icons/cg";
import { BiSolidCctv, BiSolidCoffeeAlt } from 'react-icons/bi';
import { MdOutlineCheckroom, MdOutlineGpsFixed, MdBolt, MdShield, MdLocalLaundryService, MdMicrowave } from "react-icons/md";
import { FaBluetooth, FaFireExtinguisher, FaFirstAid, FaGamepad, FaParking, FaPaw, FaSwimmer, FaUsb, FaTrain } from "react-icons/fa";

const RenderTag = ({ feature }) => {
  const iconMap = {
    Cooling: IoMdSnow,
    Power: MdBolt,
    "Power Supply": MdBolt,
    Security: MdShield,
    "Swimming Pool": FaSwimmer,
    Pool: FaSwimmer,
    Water: IoIosWater,
    Games: FaGamepad,
    "Pets Allowed": FaPaw,
    "Internet & WiFi": IoIosWifi,
    Internet: IoIosWifi,
    WiFi: IoIosWifi,
    GPS: MdOutlineGpsFixed,
    Parking: FaParking,
    "Parking Space": FaParking,
    "Changing Room": MdOutlineCheckroom,
    Breakfast: MdLocalLaundryService,
    Chairs: GiOfficeChair,
    Armored: GiLayeredArmor,
    Fueling: BsFillFuelPumpFill,
    Bluetooth: FaBluetooth,
    Driver: GiSteeringWheel,
    "Gym & Spa": CgGym,
    "USB Port(s)": FaUsb,
    DSTV: CgScreen,
    "Projector & Screens": CgScreen,
    "Fire Extinguisher": FaFireExtinguisher,
    "First Aid Kit": FaFirstAid,
    Microwave: MdMicrowave,
    "Laundry Services": MdLocalLaundryService,
    Refrigerator: CgSmartHomeRefrigerator,
    "Android Auto": BsAndroid2,
    "Leather Seats": GiCarSeat,
    Alarm: BsFillAlarmFill,
    CCTV: BiSolidCctv,
    Coffee: BiSolidCoffeeAlt,
    CarPlay: BsApple,
    Transport: FaTrain,
  };

  const Icon = iconMap[feature] || null;

  return <FeatureTag Icon={Icon} name={feature} />;
};

export default RenderTag;
