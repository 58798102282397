import React, { useState, useEffect, useContext } from "react";
import {
  Route,
  Link,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Layout, Menu } from "antd";
import "./admin.css";
import Advert from "./Advert.jsx";
import AboutUs from "./Aboutus";
import Profile from "./Profile.jsx";
import Notification from "./Notification";
import Privacy from "./Privacy";
import { AdvertIcon, Bell2Icon, Lock2Icon, Profile2Icon, Logout2Icon, InfoCircleIcon } from "../CustomIcons";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import useMessageAlert from "../Message";
import useLogoutUser from "../../helpers/useLogoutUser";
import { CgMenu, CgMenuRightAlt } from "react-icons/cg";
import { MyContext } from "../../context/Context";

const { Sider } = Layout;

function getItem(label, key, icon, items, type) {
  return {
    key,
    icon,
    items,
    label,
    type,
  };
}

export default function Admin() {
  const { setBreadcrumbPaths } = useContext(MyContext);
  const { pathname } = useLocation();
  const currentPath = pathname?.split("/")[2];
  const { width } = useWindowDimensions();
  const { openMessage, contextHolder } = useMessageAlert();
  const { handleLogoutUser } = useLogoutUser();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const items = [
    getItem(
      <Link to="/admin/profile">Account</Link>,
      "profile",
      <Profile2Icon className="w-5 h-5" />
    ),

    getItem(
      <Link to="/admin/advert">My Advert</Link>,
      "advert",
      <AdvertIcon className="w-5 h-5" />
    ),

    getItem(
      <Link to="/admin/notification">Notification</Link>,
      "notification",
      <Bell2Icon className="w-5 h-5" />
    ),

    getItem(
      <Link to="/admin/changepassword">Privacy Settings</Link>,
      "changepassword",
      <Lock2Icon className="w-5 h-5" />
    ),

    getItem(
      <Link to="/admin/about">About Us</Link>,
      "about",
      <InfoCircleIcon className="w-5 h-5" />
    ),

    getItem(
      <Link to={"/"} onClick={(e) => handleLogout(e)}>Logout</Link>,
      "logout",
      <Logout2Icon className="w-5 h-5" />
    ),
  ];

  const handleLogout = (e) => {
    e?.preventDefault();
    handleLogoutUser();
    openMessage("success", "You have successfully logged out!");
    navigate("/");
  };

  useEffect(() => {
    if (width) {
      setCollapsed(width < 769 ? true : false);
    }
    const breadcrumbMapping = {
      profile: "Profile",
      advert: "Adverts",
      notification: "Notifications",
      changepassword: "Privacy",
      about: "About",
    };
  
    setBreadcrumbPaths([
      { disabled: true, title: "Account Settings" },
      { disabled: true, title: breadcrumbMapping[currentPath], path: `/${currentPath}` },
    ]);
  }, [setBreadcrumbPaths, width, currentPath]);

  return (
    <Layout className="min-h-[46.5rem] w-full mt-[3rem] mx-auto">
      {contextHolder}
      <Sider
        collapsible
        collapsed={collapsed}
        trigger={null}
        breakpoint="md"
        collapsedWidth="3.75rem"
        className="admin-sider"
      >
        <div className="trigger cursor-pointer" onClick={toggleCollapsed}>
          {!collapsed ? (
            <CgMenuRightAlt className="trigger-icon" />
          ) : (
            <CgMenu className="trigger-icon" />
          )}
        </div>
        <Menu
          defaultSelectedKeys={[currentPath]}
          mode="inline"
          theme="dark"
          items={items}
          className="menuitemss"
        />
      </Sider>

      <Layout className="bg-white border-b border-[#E5DEDE]">
        <Routes>
          <Route path="profile" element={<Profile />} />
          <Route path="advert" element={<Advert />} />
          <Route path="notification" element={<Notification />} />
          <Route path="changepassword" element={<Privacy />} />
          <Route path="about" element={<AboutUs />} />
        </Routes>
      </Layout>
    </Layout>
  );
}
