import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyGetChatListQuery,
  useLazyGetChatListVendorsQuery,
} from "../../api/chat";
import { useLazyGetUserQuery } from "../../api/auth";
import { setReceiverData } from "../../slice/chatSlice";
import { useNavigate } from "react-router-dom";
import { appendImageUrl } from "../../helpers";
import Spinner from "../Spinner";
import useMessageAlert from "../Message";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import imageUrls from "../LazyLoadImg/imageUrls";

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const ChatListItem = ({ handleShowChat, data, isActive }) => (
  <div
    onClick={handleShowChat}
    className={`flex flex-col w-full border-b border-[#E5DEDE] py-4 pr-3 cursor-pointer ${isActive ? "bg-[#FFF8F3]" : ""
      }`}
  >
    <div className='flex items-start justify-between w-full'>
      <div className='flex items-start gap-x-2'>
        <img
          src={
            !isEmpty(data?.imageLink)
              ? appendImageUrl(data?.imageLink, "user")
              : imageUrls.defaultDp
          }
          alt={"profile"}
          className='rounded-full relative w-10 h-10 bg-orange-400 object-cover'
        />
        <p className='text-xs font-semibold text-primaryBlack leading-4 capitalize mb-[0]'>
          {data?.preferredName || `${data?.firstName} ${data?.lastName}`}
        </p>
      </div>

      <span className='text-xs font-normal text-[#878380]'>
        {dayjs.utc(data?.lastMessageDate, "D/M/YYYY HH:mm:ss").fromNow()}
      </span>
    </div>

    <div className='-mt-5 leading-6 flex flex-col gap-1 ml-12'>
      <p className='text-sm text-primaryBlack font-bold truncate block mb-[0]'>
        {data?.title}
      </p>
      <span className='text-xs text-[#878380] font-normal truncate block'>
        {data?.lastMessage}
      </span>
    </div>
  </div>
);

export default function ChatList({ openChatDrawer }) {
  const { profile, isLoggedIn } = useSelector((state) => state.user);
  const { receiverData } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getChatList, { data: chatListData, isLoading }] =
    useLazyGetChatListQuery();
  const [getChatVendorsList, { isLoading: vendorInfoLoading, isFetching }] =
    useLazyGetChatListVendorsQuery();
  const [getReceiver] = useLazyGetUserQuery();
  const [chatList, setChatList] = useState([]);
  const { openMessage, contextHolder } = useMessageAlert();

  const handleShowChat = async (item) => {
    const receiverId =
        item?.userId !== null ? item?.userId : item?.vendorId;
    const receiverInfo = await getReceiver(receiverId).unwrap();
    dispatch(
      setReceiverData({
        ...receiverInfo,
        chatId: item?.chatId,
        title: item?.title,
      })
    );
    navigate(`/messages/${item?.vendorId}`);
    openChatDrawer();
  };

  const handleGetChatList = useCallback(async () => {
    if (isLoggedIn && !isEmpty(profile?.userId)) {
      setChatList([]);
      let listData = await getChatList(profile?.userId).unwrap();

      let vendorIds = listData?.map((vendor) => {
        if (vendor?.userId && vendor?.userId !== null) {
          return vendor.userId;
        } else {
          return vendor.vendorId;
        }
      });

      getChatVendorsList({ userId: profile.userId, vendorIds })
        .unwrap()
        .then((res) => {
          let vendorsInfo = res;
          const jsonListData = JSON.parse(JSON.stringify(listData)).map(
            (chatItem) => {    
              let item;
              let decodedUserId = decodeURIComponent(chatItem.userId);
              if (!isEmpty(vendorsInfo[decodedUserId])) {
                item = vendorsInfo[decodedUserId];
              } else {
                let decodeduserId2 = decodeURIComponent(chatItem.vendorId);
                item = vendorsInfo[decodeduserId2];
              }
              if (!isEmpty(item)) {
                chatItem["firstName"] = item.firstName;
                chatItem["lastName"] = item.lastName;
                chatItem["imageLink"] = item.imageLink;
                chatItem["fcmToken"] = item.fcmToken;
                chatItem["emailAddress"] = item.emailAddress;
              }
              return chatItem;
            }
          );
          setChatList(jsonListData);
        })
        .catch((error) => {
          openMessage(
            "error",
            error?.data?.errorMessage || "Failed to fetch messages"
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChatList, getChatVendorsList, isLoggedIn, profile.userId]);

  useEffect(() => {
    handleGetChatList();
  }, [handleGetChatList]);

  return (
    <div className="w-full h-full md:w-1/3 border-r border-b border-borderGray">
      {contextHolder}
      <div className="flex flex-col gap-4 items-center justify-center w-full px-3 pt-5 pb-3 border-b border-[#E5DEDE]">

        <p className="-mt-8 text-2xl font-bold leading-6 mb-[0] w-full">
          Messages
        </p>

        <div className="flex items-center justify-between w-full">
          <span className="text-sm font-medium text-primaryBlack capitalize">
            {`${chatListData?.length || 0} Messages`}
          </span>
          {/*<span className="text-primaryOrange font-semibold capitalize">
            Newest first
          </span>*/}
        </div>

      </div>
      <div className="flex flex-col overflow-y-auto h-full scrollbar-thin scroll-smooth scrollbar-thumb-orange-100 scrollbar-rounded-full">
        {isLoading || vendorInfoLoading || isFetching ? (
          <Spinner />
        ) : (
          <div>
            {!isEmpty(chatList) &&
              chatList?.map((chatItem, i) => (
                <ChatListItem
                  key={i}
                  handleShowChat={() => handleShowChat(chatItem)}
                  data={chatItem}
                  isActive={
                    receiverData?.chatId === chatItem?.chatId ? true : false
                  }
                />
              ))}
          </div>
        )}

      </div>
    </div>
  );
}
