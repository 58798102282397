import React from "react";
import Layout from "../Layout";
import HeaderComponent from "../Layout/HeaderComponent";

export default function Index() {
  return (
    <Layout>
      <HeaderComponent
        pageTitle={"Cookie Policy"}
        pageDescription={"View Cookie Policy"}
      />
      <div className="container font-poppins">
        <div className="my-10">
          <h2 className="header-text text-2xl">Cookie Policy</h2>
        </div>

        <div className=" mb-10" id="what-are-cookies">
          <h4 className="header-text">1. WHAT ARE COOKIES?</h4>A cookie is a
          small file of letters and numbers that we store on your browser or the
          hard drive of your computer, mobile phone or other device - if you
          agree. There are other similar technologies such as pixel tags, web
          bugs and webs storage which are used in desktop software or mobile
          devices. If you have any questions about our use of cookies, please
          contact us at <a href="mailto:support@yarlo.co">support@yarlo.co</a>
        </div>
        <div class="mb-10" id="information">
          <h4 class="header-text">2. INFORMATION ABOUT OUR USE OF COOKIES</h4>
          <p>
            Our website <a href="https://www.yarlo.co">www.yarlo.co</a> and the
            mobile application known as 'Yarlo' ("Platform") uses cookies and
            similar technologies to distinguish you from other users of our
            Platform. This helps us to provide you with a good experience when
            you browse our Platform, and also allows us to improve our Platform.
            By continuing to use our Platform, you are agreeing to our use of
            cookies. We use the following cookies:
          </p>
          <ul>
            <li>
              <strong>Strictly necessary cookies.</strong> These are cookies
              that are required for the operation of our Platform. They include,
              for example, cookies that enable you to log into secure areas of
              our Platform or our services.
            </li>
            <li>
              <strong>Analytical/performance cookies.</strong> They allow us to
              recognise and count the number of visitors and to see how visitors
              move around our Platform when they are using it. This helps us to
              improve the way our Platform works, for exampl.e, by ensuring that
              users are finding what they are looking for easily.
            </li>
            <li>
              <strong>Functionality cookies.</strong> These are used to
              recognise you when you return to our Platform. This enables us to
              personalise our content for you and remember your preferences.
            </li>
          </ul>
          <br></br>
          <p>
            We also use the other technologies identified above for the purposes
            articulated in this Cookies Policy.
          </p>
          <p>
            Please note that third parties (including, for example, advertising
            networks and providers of external services like web traffic
            analysis services) may also use cookies, over which we may have no
            control. These cookies are likely to be analytical/performance
            cookies or targeting cookies.
          </p>
        </div>
        <div class="mb-10" id="manage">
          <h4 class="header-text">3. MANAGING COOKIES</h4>
          <p>
            You block cookies by activating the settings on your web browser
            that allows you to refuse the setting of all or some cookies. You
            may be able to manage other technologies in the same way. Please
            note that if you use your browser settings to block all cookies
            (including essential cookies) and other technologies you may not be
            able to access all or parts of our Platform.
          </p>
        </div>
        <div class="mb-10" id="expiry">
          <h4 class="header-text">
            4. EXPIRY OF COOKIES AND OTHER TECHNOLOGIES
          </h4>
          <p>
            Except for essential cookies and the equivalent other technologies,
            all session cookies will expire when the browser is closed, or the
            user clears their cookies.
          </p>
        </div>
      </div>
    </Layout>
  );
}
