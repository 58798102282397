import React from "react";
import Layout from "../Layout";
import HeaderComponent from "../Layout/HeaderComponent";
import Contact from "./ContactForm";

export default function ContactForm() {
    return (
        <Layout>
          <HeaderComponent
            pageTitle={"Contact Us - Yarlo"}
            pageDescription={"Leave Feedback Yarlo Rental MarketPlace"}
          />
          <Contact></Contact>
        </Layout>
      );
}