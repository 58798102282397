import React, { useState } from "react";
import MainButton from "../Buttons/MainButton";
import { Modal } from "antd";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import {
  useForgotPasswordMutation,
  useLazyGetUserQuery,
  useSendOTPMutation,
  useSignupMutation,
  useVerifyOTPMutation,
} from "../../api/auth";
import {
  getUserSuccess,
  loginUser,
  setSignupInfo,
} from "../../slice/userSlice";
import useMessageAlert from "../Message";
import { v4 as uuidv4 } from "uuid";

function VerifyOTPModal({ visible, onCancel, showResetPasswordModal }) {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const dispatch = useDispatch();
  const [verifyOtp, { isLoading: verifyLoading }] = useVerifyOTPMutation();
  const [signup, { isLoading: signupLoading }] = useSignupMutation();
  const [sendOTP, { isLoading: resendLoading }] = useSendOTPMutation();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const [getUser] = useLazyGetUserQuery();
  const { signupInfo } = useSelector((state) => state.user);
  const { openMessage, contextHolder } = useMessageAlert();
  const clientId = uuidv4();

  const handleChange = (e) => {
    setOtp(e);
    setOtpError(false);
  };

  const handleSubmit = async () => {
    if (otp.length < 6) {
      return setOtpError(true);
    } else {
      setOtpError(false);
      let verifyBody = {
        emailAddress: signupInfo?.emailAddress,
        otpValue: otp,
      };
      if (signupInfo.isForgotPassword === true) {
        verifyOtp(verifyBody)
          .unwrap()
          .then(() => {
            dispatch(setSignupInfo({ ...signupInfo, otpValue: otp }));
            showResetPasswordModal();
          })
          .catch((error) =>
            openMessage(
              "error",
              error?.data?.errorMessage || "an unexpected error occured"
            )
          );
      } else {
        verifyOtp(verifyBody)
          .unwrap()
          .then(() => {
            signup(signupInfo)
              .unwrap()
              .then((res) => {
                openMessage(
                  "success",
                  "You have successfully created an account"
                );
                dispatch(loginUser({ token: res.token, clientId }));
                getUser(res?.userId)
                  .unwrap()
                  .then((resp) => {
                    dispatch(getUserSuccess(resp));
                  });
              });
          })
          .catch((error) =>
            openMessage(
              "error",
              error?.data?.errorMessage || "an unexpected error occured"
            )
          );
      }
    }
  };

  const handleResendCode = () => {
    let reqBody = {
      emailAddress: signupInfo?.emailAddress,
      reset: signupInfo?.isForgotPassword || false,
    };
    setOtp("");
    if (signupInfo.isForgotPassword === true) {
      forgotPassword(reqBody)
        .unwrap()
        .then(
          openMessage(
            "success",
            "The verification has been sent to your email address"
          )
        )
        .catch((error) =>
          openMessage(
            "error",
            error?.data?.errorMessage || "an unexpected error occured"
          )
        );
    } else {
      sendOTP(reqBody)
        .unwrap()
        .then(
          openMessage(
            "success",
            "The verification has been sent to your email address"
          )
        )
        .catch((error) =>
          openMessage(
            "error",
            error?.data?.errorMessage || "an unexpected error occured"
          )
        );
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      className={"generic-modal"}
      footer={() => null}
    >
      {contextHolder}
      <div className="py-4">
        <h1 className="text-[2rem] font-medium text-[#202020] mb-3">
          Verification
        </h1>
        <p className="text-primaryBlack font-medium font-poppins text-base mb-0">
          A verification code has been sent to your mobile & email provided.
        </p>
        <div className="mt-10">
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            containerStyle={"flex justify-around"}
            inputStyle={
              "h-[44px] w-[44px] md:h-[64px] md:w-[64px] rounded-xl border border-[#DCDCDC] text-textGray text-sm md:text-lg"
            }
            focusStyle={"outline outline-1 outline-primaryOrange/50"}
            errorStyle={"outline outline-1 outline-red-400"}
            hasErrored={otpError}
            shouldAutoFocus
            isInputNum
          />
        </div>
        <div className="my-8 text-center">
          <p className="text-primaryBlack font-medium font-poppins text-base mb-0">
            Didn't receive the code?
          </p>
          <p
            onClick={handleResendCode}
            className="text-primaryOrange hover:text-primaryOrange/75 cursor-pointer font-medium font-poppins text-base mb-0"
          >
            RESEND CODE
          </p>
        </div>
        <MainButton
          title={"Continue"}
          size={"md"}
          appendStyles={"font-poppins font-medium w-full"}
          onClick={handleSubmit}
          isLoading={
            verifyLoading || resendLoading || signupLoading || isLoading
          }
        />
      </div>
    </Modal>
  );
}

export default VerifyOTPModal;
