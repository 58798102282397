import React from "react";
import { Tabs } from "antd";
import {
  ExchangeIcon,
  Step1Circle,
  Step2Circle,
  Step3Circle,
} from "../CustomIcons";
import LazyLoadImg from "../LazyLoadImg";
import imageUrls from "../LazyLoadImg/imageUrls";

const OwnerContent = () => (
  <div className="text-center my-3">
    <LazyLoadImg
      src={imageUrls.ownerAvatar}
      alt="owner on yarlo"
      className="w-[6.5rem] h-[6.5rem] mb-5"
    />
    <div>
      <Step1Circle className="w-[3rem] h-[3rem] md:w-[3.5rem] md:h-[3.5rem]" />
      <h4 className="text-[#000] leading-normal font-sans font-medium text-base md:text-lg my-3">
        Sign up & get verified
      </h4>
      <div className="bg-placeholder/[0.3] w-[1.5px] mx-auto rounded h-[3rem]"></div>
      <Step2Circle className="w-[3rem] h-[3rem] md:w-[3.5rem] md:h-[3.5rem] mt-3 md:mt-4" />
      <h4 className="text-[#000] leading-normal font-sans font-medium text-base md:text-lg my-3">
        List Items & Set Price
      </h4>
      <div className="bg-placeholder/[0.3] w-[1.5px] mx-auto rounded h-[3rem]"></div>
      <Step3Circle className="w-[3rem] h-[3rem] md:w-[3.5rem] md:h-[3.5rem] mt-3 md:mt-4" />
      <h4 className="text-[#000] leading-normal font-sans font-medium text-base md:text-lg my-3">
        Earn From your Assets
      </h4>
    </div>
  </div>
);

const RenterContent = () => (
  <div className="text-center my-3">
    <LazyLoadImg
      src={imageUrls.renterAvatar}
      alt="renter on yarlo"
      className="w-[6.5rem] h-[6.5rem] mb-5"
    />
    <div>
      <Step1Circle className="w-[3rem] h-[3rem] md:w-[3.5rem] md:h-[3.5rem]" />
      <h4 className="text-[#000] leading-normal font-sans font-medium text-base md:text-lg my-3">
        Search For Items
      </h4>
      <div className="bg-placeholder/[0.3] w-[1.5px] mx-auto rounded h-[3rem]"></div>
      <Step2Circle className="w-[3rem] h-[3rem] md:w-[3.5rem] md:h-[3.5rem] mt-3 md:mt-4" />
      <h4 className="text-[#000] leading-normal font-sans font-medium text-base md:text-lg my-3">
        Chat Owners Directly
      </h4>
      <div className="bg-placeholder/[0.3] w-[1.5px] mx-auto rounded h-[3rem]"></div>
      <Step3Circle className="w-[3rem] h-[3rem] md:w-[3.5rem] md:h-[3.5rem] mt-3 md:mt-4" />
      <h4 className="text-[#000] leading-normal font-sans font-medium text-base md:text-lg my-3">
        Save More Renting
      </h4>
    </div>
  </div>
);

function HowItWorks() {
  const mobileTabs = [
    {
      label: "Owner Sign up",
      key: 1,
      children: <OwnerContent />,
    },
    {
      label: "Renter Sign up",
      key: 2,
      children: <RenterContent />,
    },
  ];

  return (
    <div className="py-14 lg:py-20 xl:py-24" id="how-it-works">
      <h2 className="text-primaryOrange leading-normal text-center font-sans font-bold text-[2rem] lg:text-[2.125rem] xl:text-[2.5rem] mb-8 lg:mb-10 desktop:mb-12">
        How It Works
      </h2>
      <p className="text-placeholder text-center font-sans text-sm md:text-base lg:text-lg mb-0">
        3 easy steps to start using our platform
      </p>
      <Tabs
        defaultActiveKey="1"
        className="how-it-works-tabs font-sans my-6 lg:hidden"
        items={mobileTabs}
      />

      <div className="hidden lg:grid lg:grid-cols-12 lg:gap-[1.5rem] xl:gap-[2.5rem] desktop:gap-[3.5rem] mt-16">
        <div className="col-span-2 lg:col-span-5 text-right">
          <LazyLoadImg
            src={imageUrls.ownerAvatar}
            alt="owner on yarlo"
            className="w-[7.125rem] h-[7.125rem]"
          />
          <h3 className="text-primaryOrange leading-normal font-sans font-semibold text-[1.5rem] lg:text-[1.625rem] xl:text-[1.75rem] mt-6 lg:mt-8 xl:mt-9">
            Owner Sign up
          </h3>
          <div className="mb-10 lg:mb-14 xl:mb-[4.5rem] mt-14 lg:mt-16 xl:mt-[5.5rem]">
            <h4 className="text-[#000] leading-normal font-sans font-medium text-[1.125rem] lg:text-[1.25rem] xl:text-[1.5rem] mb-4 lg:mb-5 xl:mb-6">
              Sign up & get verified
            </h4>
            <p className="text-placeholder font-sans text-xs xl:text-sm desktop:text-base mb-0">
              Quickly signup for an account now, Username - Password - First Name - Last Name - Email - Phone Number.
            </p>
          </div>
          <div className="mb-10 lg:mb-14 xl:mb-[4.5rem] mt-14 lg:mt-16 xl:mt-[5.5rem]">
            <h4 className="text-[#000] leading-normal font-sans font-medium text-[1.125rem] lg:text-[1.25rem] xl:text-[1.5rem] mb-4 lg:mb-5 xl:mb-6">
              List Items & Set Price
            </h4>
            <p className="text-placeholder font-sans text-xs xl:text-sm desktop:text-base mb-0">
              Go to Post and type the Description - Select your category -
              Select State - Upload images at the bottom
            </p>
          </div>
          <div className="mb-10 lg:mb-14 xl:mb-[4.5rem] mt-14 lg:mt-16 xl:mt-[5.5rem]">
            <h4 className="text-[#000] leading-normal font-sans font-medium text-[1.125rem] lg:text-[1.25rem] xl:text-[1.5rem] mb-4 lg:mb-5 xl:mb-6">
              Earn From your Assets
            </h4>
            <p className="text-placeholder font-sans text-xs xl:text-sm desktop:text-base mb-0">
              Make money by leveraging assets you already have (passive income),
              not by working more hours
            </p>
          </div>
        </div>
        <div className="col-span-1 lg:col-span-2 text-center relative">
          <ExchangeIcon className="w-6 h-5 absolute top-[46px] lg:left-[58px] xl:left-[56px] desktop:left-[64px] 3xl:left-[80px] 4xl:left-[104px]" />
          <div className="absolute bg-placeholder/[0.3] w-[1.5px] rounded lg:top-[28%] lg:left-[70px] xl:top-[25%] xl:left-[68px] desktop:left-[75px] lg:h-[28rem] xl:h-[35.5rem] 3xl:left-[88px] 4xl:left-[110px]"></div>
          <div className="flex flex-col relative justify-center items-center h-full lg:mt-[4.5rem] xl:mt-[4.5rem] z-10">
            <Step1Circle className="lg:mb-[74px] xl:mb-[104px] w-[4.75rem] h-[4.75rem] xl:w-[5.375rem] xl:h-[5.375rem] " />
            <Step2Circle className="lg:mb-[74px] xl:mb-[104px] w-[4.75rem] h-[4.75rem] xl:w-[5.375rem] xl:h-[5.375rem] " />
            <Step3Circle className="w-[4.75rem] h-[4.75rem] xl:w-[5.375rem] xl:h-[5.375rem] " />
          </div>
        </div>
        <div className="col-span-2 lg:col-span-5">
          <LazyLoadImg
            src={imageUrls.renterAvatar}
            alt="renter on yarlo"
            className="w-[7.125rem] h-[7.125rem]"
          />
          <h3 className="text-[#1F1A16] leading-normal font-sans font-semibold text-[1.5rem] lg:text-[1.625rem] xl:text-[1.75rem] mt-6 lg:mt-8 xl:mt-9">
            Renter Sign up
          </h3>
          <div className="mb-10 lg:mb-14 xl:mb-[4.5rem] mt-14 lg:mt-16 xl:mt-[5.5rem]">
            <h4 className="text-[#000] leading-normal font-sans font-medium text-[1.125rem] lg:text-[1.25rem] xl:text-[1.5rem] mb-4 lg:mb-5 xl:mb-6">
              Search For Items
            </h4>
            <p className="text-placeholder font-sans text-xs xl:text-sm desktop:text-base mb-0">
              Find items across 9 categories you can rent at low prices and
              excellent deals.
            </p>
          </div>
          <div className="mb-10 lg:mb-14 xl:mb-[4.5rem] mt-14 lg:mt-16 xl:mt-[5.5rem]">
            <h4 className="text-[#000] leading-normal font-sans font-medium text-[1.125rem] lg:text-[1.25rem] xl:text-[1.5rem] mb-4 lg:mb-5 xl:mb-6">
              Chat Owners Directly
            </h4>
            <p className="text-placeholder font-sans text-xs xl:text-sm desktop:text-base mb-0">
              Reach out to asset owners directly, negotiate terms and pricing,
              close deals quicker
            </p>
          </div>
          <div className="mb-10 lg:mb-14 xl:mb-[4.5rem] mt-14 lg:mt-16 xl:mt-[5.5rem]">
            <h4 className="text-[#000] leading-normal font-sans font-medium text-[1.125rem] lg:text-[1.25rem] xl:text-[1.5rem] mb-4 lg:mb-5 xl:mb-6">
              Save More Renting
            </h4>
            <p className="text-placeholder font-sans text-xs xl:text-sm desktop:text-base mb-0">
              More flexibility - Zero maintenance cost
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
