import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import TitleHead from "./title";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import MainButton from "../Buttons/MainButton";
import InputField from "../Form/InputField";
import CustomDatePicker from "../Form/DatePicker";
import validationSchema from "../../helpers/validationSchema";
import { CameraIcon, UploadIcon } from "../CustomIcons";
import { Avatar, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { useSelector, useDispatch } from "react-redux";
import { appendImageUrl } from "../../helpers";
import {
  useUpdatePreferredNameMutation,
  useUpdateUserIdMutation,
  useUpdateUserImageMutation,
  useLazyGetUserQuery,
} from "../../api/auth";
import useMessageAlert from "../Message";
import { getUserSuccess } from "../../slice/userSlice";
import imageUrls from "../LazyLoadImg/imageUrls";

function Profile() {
  const { profile } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState("");
  const [idFileList, setIdFileList] = useState([]);
  const [updateUserImg] = useUpdateUserImageMutation();
  const [updateUserID] = useUpdateUserIdMutation();
  const [updatePreferredName] = useUpdatePreferredNameMutation();
  const [getUser] = useLazyGetUserQuery();
  const { openMessage, contextHolder } = useMessageAlert();

  const updateProfileImage = ({ fileList }) => {
    const file = new FormData();
    file.append("file", fileList[0]?.originFileObj);
    updateUserImg({ userId: profile?.userId, file })
      .unwrap()
      .then(() => {
        openMessage("success", "Profile image updated successfully!");
        getUser(profile?.userId)
          .unwrap()
          .then((res) => dispatch(getUserSuccess(res)));
      })
      .catch((error) => {
        openMessage(
          "error",
          error?.data?.errorMessage || "Failed to update profile image"
        );
      });
  };

  const onIdUpload = ({ fileList: newFileList }) => {
    const file = new FormData();
    setSelectedFile(newFileList.length > 0 ? newFileList[0] : null);
    setIdFileList(newFileList);
    file.append("file", newFileList[0]?.originFileObj);
    updateUserID({ userId: profile?.userId, file })
      .unwrap()
      .then(() => {
        openMessage(
          "success",
          "ID uploaded successfully, admin will review and approve or decline"
        );
        getUser(profile?.userId)
          .unwrap()
          .then((res) => dispatch(getUserSuccess(res)));
      })
      .catch((error) => {
        openMessage(
          "error",
          error?.data?.errorMessage || "Failed to upload ID"
        );
      });
  };

  const handleUpdate = (values) => {
    updatePreferredName({
      userId: profile?.userId,
      preferredName: values?.preferredName,
    })
      .unwrap()
      .then(() => {
        openMessage("success", "Account information updated successfully!");
        getUser(profile?.userId)
          .unwrap()
          .then((res) => dispatch(getUserSuccess(res)));
      })
      .catch((error) => {
        openMessage(
          "error",
          error?.data?.errorMessage || "Failed to update Account Information"
        );
      });
  };

  useEffect(() => {
    if (!isEmpty(profile?.imageLink)) {
      setImageUrl(appendImageUrl(profile.imageLink, "user"));
    }
    if (!isEmpty(profile?.userId)) {
      getUser(profile?.userId)
        .unwrap()
        .then((res) => dispatch(getUserSuccess(res)));
    }
  }, [profile, getUser, dispatch]);

  const [selectedFile, setSelectedFile] = useState(null);

  const truncateFileName = (fileName, maxLength) =>
    fileName.length <= maxLength ? fileName : fileName.slice(0, maxLength) + '...';

  return (
    <div className="">
      {contextHolder}
      <TitleHead title="Account Information" />
      <div className="flex flex-col items-center px-3 md:px-8 lg:px-12 xl:px-[3.25rem]">
        <div className="relative">
          <Avatar
            style={{
              width: "112px",
              height: "112px",
              marginTop: "40px",
            }}
            alt={"user profile"}
            src={!isEmpty(imageUrl) ? imageUrl : imageUrls.user}
          />
          <div className="absolute bottom-0 right-0 cursor-pointer rounded-full p-2 bg-white">
            <ImgCrop rotate modalClassName="preview-modal">
              <Upload
                onChange={updateProfileImage}
                accept="image/jpg,image/png,image/gif"
                showUploadList={false}
              >
                <CameraIcon className="w-6 h-6" />
              </Upload>
            </ImgCrop>
          </div>
        </div>
        <Formik
          initialValues={{
            firstName: profile?.firstName || "",
            lastName: profile?.lastName || "",
            preferredName: profile?.preferredName || "",
            dateOfBirth: profile?.dateOfBirth || null,
            emailAddress: profile?.emailAddress || "",
            phoneNumber: profile?.phoneNumber || "",
          }}
          onSubmit={(values) => {
            handleUpdate(values);
          }}
          validationSchema={yup.object().shape(validationSchema.updateProfile)}
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            handleSubmit,
            touched,
          }) => (
            <Form className="flex flex-col gap-10 w-full mt-6 font-poppins">
              <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-y-2 gap-x-5">
                <InputField
                  name={"firstName"}
                  inputType={"text"}
                  label={"First Name"}
                  placeholder={"First Name"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={errors.firstName}
                  touched={touched.firstName}
                  isDisabled
                />
                <InputField
                  name={"lastName"}
                  inputType={"text"}
                  label={"Last Name"}
                  placeholder={"Last Name"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={errors.lastName}
                  touched={touched.lastName}
                  isDisabled
                />
                <InputField
                  name={"preferredName"}
                  inputType={"text"}
                  label={"Preferred Name"}
                  placeholder={"Preferred Name"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.preferredName}
                  error={errors.preferredName}
                  touched={touched.preferredName}
                  isDisabled={!isEmpty(profile?.preferredName) ? true : false}
                />
                <InputField
                  name={"phoneNumber"}
                  inputType={"text"}
                  label={"Phone Number"}
                  placeholder={"Phone Number"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                  error={errors.phoneNumber}
                  touched={touched.phoneNumber}
                  isDisabled
                />
                <InputField
                  name={"emailAddress"}
                  inputType={"email"}
                  label={"Email Address"}
                  placeholder={"Email Address"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.emailAddress}
                  error={errors.emailAddress}
                  touched={touched.emailAddress}
                  isDisabled
                />
                <Field
                  id="dateOfBirth"
                  type="date"
                  component={CustomDatePicker}
                  hasLabel
                  label="Date of Birth"
                  value={values.dateOfBirth}
                  format={"DD/MM/YYYY"}
                  error={errors.dateOfBirth}
                  touched={touched.dateOfBirth}
                  isDisabled
                />

                {!profile?.idUploaded ? (
                  <div className="flex w-full md:col-span-2">
                    <Upload
                      fileList={idFileList}
                      onChange={onIdUpload}
                      accept="image/*,.pdf"
                      maxCount={1}
                    >

                      <div className="relative w-full">
                        <div
                          className="flex w-full bg-white rounded-full border border-gray-300 px-4 py-3 text-xs transition duration-300 ease-in-out hover:border-blue-300 focus:border-primaryOrange"
                        >
                          {selectedFile ? truncateFileName(selectedFile.name, 26) : 'Select ID (We Accept: Jpeg, png, pdf)'}
                        </div>
                        <div className="absolute top-0.5 bottom-0 right-0 pr-1">
                          <div
                            className="cursor-pointer text-white bg-primaryOrange w-9 h-9 flex items-center justify-center rounded-full"
                            onClick={() => {
                              document.getElementById('upload-id').click();
                            }}
                          >
                            <UploadIcon className="w-4 h-4 -ml-0.5 text-center" />
                          </div>
                        </div>
                      </div>

                      <span className="text-[0.65rem] mt-1 pl-1 font-poppins">
                        Accepted IDs: NIMC, Drivers License, International Passport and Voters Card
                      </span>

                    </Upload>
                  </div>
                ) : profile?.idVerified ? (
                  <MainButton
                    title={"ID Verified"}
                    size={"md"}
                    variant={"outline-gray"}
                    appendStyles={"rounded-full font-poppins mt-2"}
                    disabled
                  />
                ) : (
                  <MainButton
                    title={"Verification Pending"}
                    size={"md"}
                    variant={"outline-gray"}
                    appendStyles={"rounded-full font-poppins mt-2"}
                    disabled
                  />
                )}
              </div>

              <div
                className="flex items-center justify-center w-full mt-4"
                hidden={!isEmpty(profile?.preferredName)}
              >
                <MainButton
                  title={"Update Account"}
                  size={"md"}
                  variant={"primary-rounded"}
                  appendStyles={"rounded-full font-poppins"}
                  onClick={handleSubmit}
                  disabled={!isEmpty(profile?.preferredName) ? true : false}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Profile;
