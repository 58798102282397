import React from "react";
import { Input } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const { TextArea } = Input;

function SelectField({
  options,
  error,
  onChange,
  hasLabel,
  label,
  value,
  name,
  isDisabled,
  placeholder,
  onBlur,
  touched,
  rows,
  maxLength
}) {
  return (
    <div className="my-3 textarea-input relative">
      {hasLabel && (
        <label className="label text-base font-Poppins" htmlFor={name}>
          {label}
        </label>
      )}
      <TextArea
        name={name}
        rows={rows || 5}
        placeholder={placeholder || " "}
        className="w-full"
        onChange={onChange}
        options={options}
        value={value}
        disabled={isDisabled}
        onBlur={onBlur}
        maxLength={maxLength ? maxLength: 256}
      />
      {error && touched && (
        <div className={"flex items-center mt-1"}>
          <InfoCircleOutlined className="text-[10px] text-red-500" />
          <p className="text-left text-[10px] text-red-500 ml-1.5 mb-0">
            {error}
          </p>
        </div>
      )}
    </div>
  );
}

export default SelectField;
