import { apiInstance } from "./";

export const authenticationApi = apiInstance.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (payload) => ({
        url: "/api/user/authenticate",
        method: "POST",
        body: payload,
      }),
      transformResponse(data, meta) {
        return { ...data, token: meta.response.headers.get("token") };
      },
    }),
    getUser: builder.query({
      query: (userId) => `/api/user/userId/`+ encodeURI(userId),
      providesTags: ["userInfo"],
    }),
    updateUserImage: builder.mutation({
      query: ({ userId, file }) => ({
        url: `/api/user/image/`+ encodeURI(userId),
        method: "PUT",
        body: file,
        redirect: "follow",
      }),
      invalidatesTags: ["userInfo"],
    }),
    updateUserId: builder.mutation({
      query: ({ userId, file }) => ({
        url: `/api/user/identification/`+ encodeURI(userId),
        method: "POST",
        body: file,
        redirect: "follow",
      }),
      invalidatesTags: ["userInfo"],
    }),
    updatePreferredName: builder.mutation({
      query: ({ userId, preferredName }) => ({
        url: `/api/user/preferredName?userId=${userId}&preferredName=${preferredName}`,
        method: "PUT",
      }),
      invalidatesTags: ["userInfo"],
    }),
    sendOTP: builder.mutation({
      query: (payload) => ({
        url: "/api/user/send-otp",
        method: "POST",
        body: payload,
        redirect: "follow",
      }),
    }),
    verifyOTP: builder.mutation({
      query: (payload) => ({
        url: "/api/user/otp",
        method: "PUT",
        body: payload,
        redirect: "follow",
      }),
    }),
    signup: builder.mutation({
      query: (payload) => ({
        url: "/api/user/register",
        method: "POST",
        body: payload,
        redirect: "follow",
      }),
      transformResponse(data, meta) {
        return { ...data, token: meta.response.headers.get("token") };
      },
    }),
    googleSignin: builder.mutation({
      query: (payload) => ({
        url: "/api/user/external/oauth/google",
        method: "POST",
        body: payload,
        redirect: "follow",
      }),
      transformResponse(data, meta) {
        return { ...data, token: meta.response.headers.get("token") };
      },
    }),
    facebookSignin: builder.mutation({
      query: (payload) => ({
        url: "/api/user/external/oauth/facebook",
        method: "POST",
        body: payload,
        redirect: "follow",
      }),
      transformResponse(data, meta) {
        return { ...data, token: meta.response.headers.get("token") };
      },
    }),
    changePassword: builder.mutation({
      query: (payload) => ({
        url: "/api/user/password",
        method: "PUT",
        body: payload,
        redirect: "follow",
      }),
    }),
    forgotPassword: builder.mutation({
      query: (payload) => ({
        url: "/api/user/forgot-password/send",
        method: "PUT",
        body: payload,
        redirect: "follow",
      }),
    }),
    resetPassword: builder.mutation({
      query: (payload) => ({
        url: "/api/user/reset-password",
        method: "PUT",
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useSendOTPMutation,
  useSignupMutation,
  useGoogleSigninMutation,
  useFacebookSigninMutation,
  useVerifyOTPMutation,
  useUpdateUserImageMutation,
  useUpdateUserIdMutation,
  useUpdatePreferredNameMutation,
  useChangePasswordMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authenticationApi;
