import { apiInstance } from "./";

export const chatApi = apiInstance.injectEndpoints({
  endpoints: (builder) => ({
    sendChat: builder.mutation({
      query: ({ chatId, body, listingId }) => ({
        url: `/api/chat/send${chatId !== undefined ? `?chatId=${chatId}` : `?listingId=${listingId}` }`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["chat","chatList"],
    }),
    getChatList: builder.query({
      query: (userId) => ({
        url: `/api/chat/list/`+ encodeURI(userId),
        method: "GET",
      }),
    }),
    getChatListVendors: builder.query({
      query: ({ userId, vendorIds }) => ({
        url: `/api/user/list/`+ encodeURI(userId) + `?userIds=${vendorIds}`,
        method: "GET",
      }),
    }),
    getDirectMessages: builder.query({
      query: ({ userId, chatId }) => ({
        url: `/api/chat/userId/`+ encodeURI(userId) + `/chatId/${chatId}`,
        method: "GET",
      }),
      transformResponse(data) {
        let reversedResponse = [];
        reversedResponse = Object.assign([], data);
        reversedResponse.reverse();
        return reversedResponse;
      },
      providesTags: ["chat"],
    }),
    uploadImages: builder.mutation({
      query: ({ chatMessageId, patch }) => ({
        url: `/api/chat/image/${chatMessageId}`,
        method: "PUT",
        body: patch,
        redirect: "follow",
      }),
      invalidatesTags: ["chat","chatList"],
    }),
    deleteChatData: builder.mutation({
      query: (userId) => ({
        url: `/api/chat/delete/userData/`+ encodeURI(userId),
        method: "DELETE",
      }),
    }),
    deleteChatListItem: builder.mutation({
      query: ({chatId, userId}) => ({
        url: `/api/chat/delete/list?userId=${userId}&chatId=${chatId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["chatList"],
    }),
  }),
});

export const {
  useDeleteChatDataMutation,
  useGetChatListQuery,
  useGetDirectMessagesQuery,
  useLazyGetChatListQuery,
  useLazyGetDirectMessagesQuery,
  useSendChatMutation,
  useUploadImagesMutation,
  useLazyGetChatListVendorsQuery,
  useDeleteChatListItemMutation,
} = chatApi;
