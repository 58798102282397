import React, { useRef, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { decrement, increment } from "../../slice/stepscounter";
import { Formik, Form } from "formik";
import * as yup from "yup";
import InputField from "../Form/InputField";
import SelectField from "../Form/SelectField";
import TextArea from "../Form/TextArea";
import { mapToOptions } from "../../helpers";
import MainButton from "../Buttons/MainButton";
import validationSchema from "../../helpers/validationSchema";
import useMessageAlert from "../Message";
import { useGetDropdownDataQuery } from "../../api/general";
import { isEmpty } from "lodash";
import { updateNewListing } from "../../slice/listingSlice";

export default function ItemDetails() {
  const dispatch = useDispatch();
  const { newListing } = useSelector((state) => state.listings);
  const formikRef = useRef(null);
  const { data: dropdownData } = useGetDropdownDataQuery();
  const [itemtypes, setItemTypes] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [furnishTypes, setFurnishTypes] = useState([]);
  const { openMessage, contextHolder } = useMessageAlert();

  let numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const noBedsBaths = mapToOptions(numbers);

  const next = (values) => {
    dispatch(increment());
    dispatch(updateNewListing(values));
  };

  const goBack = () => {
    handleReset();
    dispatch(decrement());
  };

  const handleReset = () => {
    formikRef?.current?.resetForm();
    openMessage("warning", "form reset");
  };

  const handleSetDropdowns = useCallback(() => {
    let conditions;
    let furnishTypes;
    let rsFacilities;
    let eFacilities;
    let vFacilities;
    if (!isEmpty(dropdownData)) {
      conditions = dropdownData?.find(
        (types) => types.dropdownKey === "conditionsOthers"
      );
      setConditions(mapToOptions(conditions?.values));
      furnishTypes = dropdownData?.find(
        (types) => types.dropdownKey === "furnishedTypes"
      );
      setFurnishTypes(mapToOptions(furnishTypes?.values));
      rsFacilities = dropdownData?.find(
        (types) => types.dropdownKey === "realEstateFeatures"
      );
      eFacilities = dropdownData?.find(
        (types) => types.dropdownKey === "eventFacilities"
      );
      vFacilities = dropdownData?.find(
        (types) => types.dropdownKey === "vehicleFeatures"
      );
      if (newListing?.subCategory === "Residential") {
        setFacilities(mapToOptions([...rsFacilities?.values]));
      } else if (newListing?.subCategory === "Commercial Event Centers") {
        setFacilities(mapToOptions([...eFacilities?.values]));
      } else if (newListing?.category === "Vehicles") {
        setFacilities(mapToOptions([...vFacilities?.values]));
      }
    }
  }, [dropdownData, newListing?.category, newListing?.subCategory]);

  const handleSetItemTypes = useCallback(() => {
    let itypes;
    if (!isEmpty(dropdownData)) {
      if (
        newListing?.category === "Real Estate" ||
        (newListing?.category === "Vehicles" &&
          (newListing?.subCategory === "Cars & SUVs" || newListing?.subCategory === "Motorcycles" || 
          newListing?.subCategory === "Trucks & Trailers" || newListing?.subCategory === "Buses"))
      ) {
        switch (newListing?.subCategory) {
          case "Residential":
            itypes = dropdownData?.find(
              (types) => types.dropdownKey === "residentialTypes"
            );
            setItemTypes(mapToOptions(itypes?.values));
            break;
          case "Commercial":
            itypes = dropdownData?.find(
              (types) => types.dropdownKey === "commercialTypes"
            );
            setItemTypes(mapToOptions(itypes?.values));
            break;
          case "Commercial Industrial":
            itypes = dropdownData?.find(
              (types) => types.dropdownKey === "industrialTypes"
            );
            setItemTypes(mapToOptions(itypes?.values));
            break;
          case "Commercial Land":
            itypes = dropdownData?.find(
              (types) => types.dropdownKey === "landTypes"
            );
            setItemTypes(mapToOptions(itypes?.values));
            break;
          case "Commercial Event Centers":
            itypes = dropdownData?.find(
              (types) => types.dropdownKey === "eventTypes"
            );
            setItemTypes(mapToOptions(itypes?.values));
            break;
          case "Cars & SUVs":
            itypes = dropdownData?.find(
              (types) => types.dropdownKey === "cars"
            );
            setItemTypes(mapToOptions(itypes?.values));
            break;
          case "Motorcycles":
            itypes = dropdownData?.find(
              (types) => types.dropdownKey === "motorcycles"
            );
            setItemTypes(mapToOptions(itypes?.values));
            break;
          case "Buses":
            itypes = dropdownData?.find(
              (types) => types.dropdownKey === "buses"
            );
            setItemTypes(mapToOptions(itypes?.values));
            break;
          case "Trucks & Trailers":
            itypes = dropdownData?.find(
              (types) => types.dropdownKey === "trucks"
            );
            setItemTypes(mapToOptions(itypes?.values));
            break;
          default:
            break;
        }
      }
    }
  }, [dropdownData, newListing]);
  const testfunction = () => {}
  useEffect(() => {
    handleSetItemTypes();
    handleSetDropdowns();
  }, [handleSetDropdowns, handleSetItemTypes]);

  return (
    <div>
      {contextHolder}
      <h2 className="font-semibold text-2xl text-headingBlack mb-2">
        Item Details
      </h2>
      <p className="text-sm text-headingBlack mb-0">
        Details about the item properties and description
      </p>
      <Formik
        initialValues={{
          propertyType: newListing?.propertyType || undefined,
          brand: newListing?.brand || undefined,
          model: newListing?.model || undefined,
          condition: newListing?.condition || "",
          features: newListing?.features || [],
          furnished: newListing?.furnished || undefined,
          numBedrooms: newListing?.numBedrooms || undefined,
          numBathrooms: newListing?.numBathrooms || undefined,
          listingDescription: newListing?.listingDescription || "",
        }}
        onSubmit={(values) => {
          next(values);
        }}
        validationSchema={yup.object().shape(validationSchema.itemDetails)}
        innerRef={formikRef}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          setFieldValue,
          touched,
        }) => (
          <Form>
            <div className="flex mt-6 flex-col w-full">
              <div className="grid sm:grid-cols-2 sm:gap-4 lg:gap-y-0">
                {newListing?.category !== "Real Estate" ? (
                  <>
                    {newListing?.category !== "Books" ? (
                      newListing?.category === "Vehicles" &&
                      (newListing?.subCategory === "Cars & SUVs" ||
                      newListing?.subCategory === "Motorcycles" ||
                      newListing?.subCategory === "Trucks & Trailers" || newListing?.subCategory === "Buses") ? (
                        <SelectField
                          id={"brand"}
                          hasLabel
                          label={"Brand"}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          options={itemtypes}
                          value={values.brand}
                          error={errors.brand}
                          touched={touched.brand}
                        />
                      ) : (
                        <InputField
                          name={"brand"}
                          inputType={"text"}
                          label={"Brand"}
                          placeholder={"Enter Brand"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.brand}
                          error={errors.brand}
                          touched={touched.brand}
                          maxLength={64}
                        />
                      )
                    ) : (
                      <InputField
                        name={"author"}
                        inputType={"text"}
                        label={"Author"}
                        placeholder={"Enter Author"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.brand}
                        error={errors.brand}
                        touched={touched.brand}
                        maxLength={64}
                      />
                    )}
                  </>
                ) : (
                  <SelectField
                    id={"propertyType"}
                    hasLabel
                    label={"Item Type"}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    options={itemtypes}
                    value={values.propertyType}
                    error={errors.propertyType}
                    touched={touched.propertyType}
                  />
                )}
                {newListing?.category === "Vehicles" && (
                  <>
                    <InputField
                      name={"model"}
                      inputType={"text"}
                      label={"Model"}
                      placeholder={"Enter Model(Optional)"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.model}
                      error={errors.model}
                      touched={touched.model}
                      maxLength={64}
                    />
                    <SelectField
                      id={"features"}
                      hasLabel
                      label={"Facilities & Amenities"}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      options={facilities}
                      value={values.features}
                      error={errors.features}
                      touched={touched.features}
                      isMulti
                    />
                  </>
                )}
                <SelectField
                  id={"condition"}
                  hasLabel
                  label={"Condition"}
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  options={conditions}
                  value={values.condition}
                  error={errors.condition}
                  touched={touched.condition}
                />
                {(newListing?.subCategory === "Residential" ||
                  newListing?.subCategory === "Commercial Event Centers") && (
                  <>
                    <SelectField
                      id={"features"}
                      hasLabel
                      label={"Facilities & Amenities"}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      options={facilities}
                      value={values.features}
                      error={errors.features}
                      touched={touched.features}
                      isMulti
                    />
                    <SelectField
                      id={"furnished"}
                      hasLabel
                      label={"Furnish Type"}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      options={furnishTypes}
                      value={values.furnished}
                      error={errors.furnished}
                      touched={touched.furnished}
                    />
                  </>
                )}
                {newListing?.subCategory === "Residential" && (
                  <>
                    <SelectField
                      id={"numBedrooms"}
                      hasLabel
                      label={"Number of Bedrooms"}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      options={noBedsBaths}
                      value={values.numBedrooms}
                      error={errors.numBedrooms}
                      touched={touched.numBedrooms}
                    />

                    <SelectField
                      id={"numBathrooms"}
                      hasLabel
                      label={"Number of Bathrooms"}
                      onChange={setFieldValue}
                      onBlur={handleBlur}
                      options={noBedsBaths}
                      value={values.numBathrooms}
                      error={errors.numBathrooms}
                      touched={touched.numBathrooms}
                    />
                  </>
                )}
              </div>
              <TextArea
                name={"listingDescription"}
                hasLabel
                label={"Item Description"}
                placeholder={"                                 (Maximum of 1000 characters)"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.listingDescription}
                error={errors.listingDescription}
                touched={touched.listingDescription}
                maxLength={1000}
              />
            </div>
            <div className="flex flex-col-reverse sm:flex-row justify-between pb-1 pt-6 mt-6 -mx-5 md:-mx-8 lg:-mx-10 px-5 md:px-8 lg:px-10 border-t border-black/[0.1]">
              <MainButton
                title={"Back"}
                variant={"outline-gray"}
                appendStyles={
                  "font-poppins mt-3 sm:mt-0 hover:bg-primaryOrange/50"
                }
                onClick={goBack}
              />
              <MainButton
                title={"Proceed to Pricing"}
                appendStyles={"font-poppins"}
                onClick={() => formikRef?.current?.handleSubmit()}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
