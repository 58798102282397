import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "../Layout";
import { ArrowDownIcon, PinDropIcon } from "../CustomIcons";
import FilterHeader from "./FilterHeader";
import {
  useLazyGetBrowsingHistoryQuery,
  useLazyGetFavoriteQuery,
  useLazyGetListingByCategoryQuery,
  useLazyGetRecommendedQuery,
  useLazyGetSpecialDealsQuery,
  useLazyGetTrendingQuery,
  useLazySearchListingQuery,
} from "../../api/listing";
import { chunk, isEmpty } from "lodash";
import { appendImageUrl, formatAmount } from "../../helpers";
import Spinner from "../Spinner";
import {
  FileSearchOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementCurrentPage,
  incrementCurrentPage,
  setListings,
} from "../../slice/listingSlice";
import useMessageAlert from "../Message";
import HeaderComponent from "../Layout/HeaderComponent";
import { Dropdown } from "antd";
import dayjs from "dayjs";
import { MyContext } from "../../context/Context";
import { IoIosBed } from "react-icons/io";
import { BiSolidBath } from 'react-icons/bi';
import FeatureTag from "../Layout/FeatTag";
import RenderTag from "../Layout/Features";

const ListingCard = ({ id, image, title, lga, state, amount, features, numBedrooms, numBathrooms, isAd}) => (
  <Link
    to={`/listing/${id}`}
    className={"col-span-4"}
  >
    <div className="group rounded-3xl bg-white drop-shadow-sm shadow-xl shadow-black/5 pt-4 pb-5 px-5 flex flex-col gap-4 font-poppins">

      <div className="relative overflow-hidden rounded-2xl">
        <img
          src={image}
          alt={title}
          className="h-[180px] md:h-[188px] xl:h-[200px] desktop:h-[240px] 2xl:h-[280px] rounded-2xl w-full object-cover group-hover:scale-105 group-focus:scale-105 group-hover:transition-all group-hover:ease-in-out"
        />
        {/* <div className="flex items-center absolute top-2 right-2 bg-[#231515]/75 rounded-md py-1.5 px-2.5">
          <StarIcon className="w-[14px] h-[14px]" />
          <span className="ml-1 text-white text-sm font-poppins font-medium">
            4.5
          </span>
        </div> */}
      </div>

      <div className="flex flex-col gap-2">

        <p
          className="text-lg font-semibold text-headingBlack truncate mb-[0]"
          title={title}
        >
          {title}
        </p>

        <div className="flex items-center gap-x-1.5">
          <PinDropIcon className="w-4 h-4 md:w-5 md:h-5" />
          <p className="text-base text-textGray mb-[0]">
            {lga}
            {state && `, ${state}`}
          </p>
        </div>

        <div className='flex items-center justify-between w-full gap-4 text-sm text-textGray'>
          {numBedrooms > 0 && <FeatureTag Icon={IoIosBed} name={`${numBedrooms} ${numBedrooms > 1 ? "beds" : "bed"}`} />}
          {numBathrooms > 0 && <FeatureTag Icon={BiSolidBath} name={`${numBathrooms} ${numBathrooms > 1 ? "baths" : "bath"}`} />}
          {numBedrooms && numBathrooms
            ? features.slice(0, 1).map((feature, index) => <RenderTag feature={feature} key={index} />)
            : features.slice(0, 3).map((feature, index) => <RenderTag feature={feature} key={index} />)
          }
        </div>

        <p className="block text-primaryOrange tracking-normal text-base font-medium mb-[0]">
          ₦ {formatAmount(amount)}
          { isAd ? <span className="float-right">Sponsored</span> : <></>}
        </p>
      </div>
    </div>
  </Link>
);

function Listings() {
  const { setBreadcrumbPaths } = useContext(MyContext)
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { listings, totalPages, currentPage } = useSelector(
    (state) => state.listings
  );
  const { location } = useSelector((state) => state.user);
  const { profile } = useSelector((state) => state.user);
  const { openMessage, contextHolder } = useMessageAlert();
  const url = new URLSearchParams(search);
  const categoryName = url.get("category")?.replace(/%20/g, " ") || undefined;
  const subcategoryName = url.get("subcategory")?.replace(/%20/g, " ").replace("@", "&") || undefined;
  const propertyTypeName = url.get("propertyType")?.replace(/%20/g, " ") || undefined;
  const states = url.get("state")?.replace(/%20/g, " ") || undefined;
  const keyword = url.get("keyword")?.replace(/%20/g, " ") || undefined;
  const [sortValue, setSortValue] = useState(0);
  const [data, setData] = useState([]);
  const [getListingsByCategory, { isFetching: categoryFetching }] =
    useLazyGetListingByCategoryQuery();
  const [getTrending, { isFetching: trendingFetching }] =
    useLazyGetTrendingQuery();
  const [getRecommended, { isFetching: recommendedFetching }] =
    useLazyGetRecommendedQuery();
  const [getFavorites, { isFetching: favoritesFetching }] =
    useLazyGetFavoriteQuery();
  const [getBrowsingHistory, { isFetching: historyFetching }] =
    useLazyGetBrowsingHistoryQuery();
  const [getSpecialDeals, { isFetching: dealsFetching }] =
    useLazyGetSpecialDealsQuery();
  const [searchRequest, { isFetching: freeSearchFetching }] =
    useLazySearchListingQuery();
  const [titleValue, setTitleValue] = useState("View");
  const [stateValue, setStateValue] = useState(location.split(",")[0]);
  const [changingCategory, setChangingCategory] =  useState(categoryName);

  const paginateListings = (listings) => {
    let pages = [listings];
    if (listings && listings.length > 24) {
      pages = chunk(listings, 24);
      dispatch(setListings(pages));
    }
    dispatch(setListings(pages));
    setData(listings);
  };

  const fetchListings = useCallback(
    (filters) => {
      let params = filters || { category: categoryName, subCategory: subcategoryName, propertyType: propertyTypeName, state: states != null ? states: stateValue };
      let keys = { keyword: keyword };
      if (!isEmpty(params.category)) {
        switch (params.category) {
          case "Trending":
            getTrending({})
              .unwrap()
              .then((res) => paginateListings(res))
              .catch((error) => {
                openMessage(
                  "error",
                  error?.status === 401
                    ? `You need to be logged in to see ${categoryName}`
                    : "an unexpected error occured"
                );
                dispatch(setListings([]));
              });
            break;
          case "Recommended":
            getRecommended(params)
              .unwrap()
              .then((res) => paginateListings(res))
              .catch((error) => {
                openMessage(
                  "error",
                  error?.status === 401
                    ? `You need to be logged in to see ${categoryName}`
                    : "an unexpected error occured"
                );
                dispatch(setListings([]));
              });
            break;
          case "Special Deals":
            params.category = undefined;
            getSpecialDeals(params)
              .unwrap()
              .then((res) => paginateListings(res))
              .catch((error) => {
                openMessage(
                  "error",
                  error?.status === 401
                    ? `You need to be logged in to see ${categoryName}`
                    : "an unexpected error occured"
                );
                dispatch(setListings([]));
              });
            break;
          case "Favorites":
            getFavorites(profile?.userId)
              .unwrap()
              .then((res) => paginateListings(res))
              .catch((error) => {
                openMessage(
                  "error",
                  error?.status === 401
                    ? `You need to be logged in to see ${categoryName}`
                    : "an unexpected error occured"
                );
                dispatch(setListings([]));
              });
            break;
          case "Keep Browsing":
            getBrowsingHistory(profile?.userId)
              .unwrap()
              .then((res) => paginateListings(res))
              .catch((error) => {
                openMessage(
                  "error",
                  error?.status === 401
                    ? `You need to be logged in to see Browsing History`
                    : "an unexpected error occured"
                );
                dispatch(setListings([]));
              });
            break;
          // free keyword search
          case "none":
            searchRequest(keys)
              .unwrap()
              .then((res) => paginateListings(res))
              .catch((error) => {
                console.error("No Listings Found");
                dispatch(setListings([]));
              });
            break;
          default:
            getListingsByCategory(params)
              .unwrap()
              .then((res) => {
                paginateListings(res);
              });
            break;
        }
      } else if (!isEmpty(keyword) && (keyword !== 'rent' || !isEmpty(states) || !isEmpty(subcategoryName) || !isEmpty(propertyTypeName))){
        getListingsByCategory(params)
          .unwrap()
          .then((res) => {
            paginateListings(res);
          });
      }
      //setChangingCategory(params.category);
      //console.log('method called', changingCategory);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      categoryName,
      //subcategoryName,
      keyword,
      getTrending,
      getSpecialDeals,
      getRecommended,
      getBrowsingHistory,
      getFavorites,
      profile?.userId,
      getListingsByCategory,
      searchRequest,
      dispatch,
      stateValue,
    ]
  );

  const onNext = () => {
    if (currentPage < totalPages - 1) {
      dispatch(incrementCurrentPage());
      window.scrollTo(0, 0);
    }
  };

  const onPrevious = () => {
    if (currentPage > 0) {
      dispatch(decrementCurrentPage());
      window.scrollTo(0, 0);
    }
  };

  const sortListing = (e) => {
    let tempListing = [...data];
    if (e === "1") {
      tempListing.sort((a, b) => a.rentAmount - b.rentAmount);
    } else if (e === "2") {
      tempListing.sort((a, b) => b.rentAmount - a.rentAmount);
    } else if (e === "3") {
      tempListing.sort(
        (a, b) =>
          dayjs(b.dateCreated, "D/M/YYYY hh:mm:ss").toDate() -
          dayjs(a.dateCreated, "D/M/YYYY hh:mm:ss").toDate()
      );
    }
    setData(tempListing);
    paginateListings(tempListing);
  };

  const changeSort = (e) => {
    setSortValue(e.key);
  };

  const items = [
    {
      key: "1",
      label: "Price Low to High",
    },
    {
      key: "2",
      label: "Price High to Low",
    },
    {
      key: "3",
      label: "Most Recently Added",
    },
  ];

  useEffect(() => {
    sortListing(sortValue);
  }, [sortValue]);

  useEffect(() => {
    fetchListings();
  }, [fetchListings]);

  useEffect(() => {
    setBreadcrumbPaths([{ disabled: true, title: categoryName }])
    if (propertyTypeName != null) {
      setTitleValue(propertyTypeName);
    } else if (subcategoryName !=  null){
      setTitleValue(subcategoryName);
    } else if (categoryName != null) {
      setTitleValue(categoryName);
    } 
    if (states != null) {
      setStateValue(states);
    } else {
      setStateValue(location.split(",")[0]);
    }
  }, [setBreadcrumbPaths, categoryName, location]);

  return (
    <Layout>
      <HeaderComponent
        pageTitle={ titleValue + " Rentals - Yarlo in " + stateValue + " Nigeria"}
        pageDescription={"Browse and Discover rental services for "+ titleValue  +" from Yarlo’s marketplace and select the best pricing from multiple options"}
      />
      {contextHolder}
      <div className="flex items-center justify-between w-full gap-4">

        <div className="flex md:hidden my-8">
          <Dropdown
            menu={{
              onClick: changeSort,
              items,
              style: { fontFamily: "Poppins" },
            }}
          >
            <div className="group flex gap-2 items-center cursor-pointer text-base font-medium">
              Sort By:
              {/* <span className="text-primaryOrange">{items.find((item) => item.key === sortValue)?.label}</span> */}
              <ArrowDownIcon className="w-3 h-3" />
            </div>
          </Dropdown>
        </div>

        <FilterHeader
          categoryName={ categoryName}
          subCategoryName={subcategoryName}
          applyFilters={fetchListings}
          sort={sortListing}
        />

      </div>

      {categoryFetching ||
        trendingFetching ||
        recommendedFetching ||
        favoritesFetching ||
        historyFetching ||
        freeSearchFetching ||
        dealsFetching ? (
        <Spinner />
      ) : (
        <>
          {!isEmpty(listings[currentPage]) ? (
            <>
              <div className="my-10 grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-8">
                {listings[currentPage].map((listing, index) => (
                  <ListingCard
                    key={index}
                    title={listing.listingHeading}
                    image={appendImageUrl(listing.imagesLocation?.[0])}
                    id={listing.listingId}
                    lga={listing.localGovernmentArea}
                    state={listing.state}
                    amount={listing.rentAmount}
                    numBedrooms={listing.numBedrooms}
                    numBathrooms={listing.numBathrooms}
                    features={listing.features}
                    subcategory={listing.subCategory}
                    bedrooms={listing.numBedrooms}
                    bathrooms={listing.numBathrooms}
                    isAd={listing.paidListing}
                  />
                ))}
              </div>
              {totalPages > 1 && (
                <div className="flex justify-between items-center px-3 lg:px-4 xl:px-6 py-2 xl:py-3">
                  <div
                    className={`flex items-center ${currentPage === 0
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                      }`}
                    onClick={onPrevious}
                  >
                    <ArrowLeftOutlined />
                    <p
                      className={`mb-0 text-base xl:text-lg font-sans font-semibold ml-2 ${currentPage === 0
                        ? "text-copyGray"
                        : "text-primaryOrange"
                        }`}
                    >
                      Previous
                    </p>
                  </div>
                  <div
                    className={`flex items-center ${currentPage === totalPages - 1
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                      }`}
                    onClick={onNext}
                  >
                    <p
                      className={`mb-0 text-base xl:text-lg font-sans font-semibold mr-2 ${currentPage === totalPages - 1
                        ? "text-copyGray"
                        : "text-primaryOrange"
                        }`}
                    >
                      Next
                    </p>

                    <ArrowRightOutlined />
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="flex flex-col items-center justify-center w-full min-h-[12rem]">
              <FileSearchOutlined className="text-[3.5rem]" />
              <p className="text-base font-medium mt-4">No listing found</p>
            </div>
          )}
        </>
      )}
    </Layout>
  );
}

export default Listings;
