import React from "react";
import { Spin } from "antd";

function Spinner() {
  return (
    <div className="flex items-center justify-center w-full h-full min-h-[17.5rem]">
      <Spin className="spinner" />
    </div>
  );
}

export default Spinner;
