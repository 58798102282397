import React from "react";
import { Select } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

function SelectField({
  options,
  error,
  onChange,
  hasLabel,
  label,
  showSearch,
  value,
  id,
  clearable,
  isDisabled,
  placeholder,
  onChangeComplete,
  onBlur,
  touched,
  isMulti,
  bordered
}) {
  const handleChange = (selectedValue) => {
    onChange(id, selectedValue);
    onChangeComplete && onChangeComplete(selectedValue);
  };
  return (
    <div className="my-3 select-input relative">
      {hasLabel && (
        <label className="label text-base" htmlFor={id}>
          {label}
        </label>
      )}
      <Select
        id={id}
        placeholder={placeholder || " "}
        className="w-full text-black placeholder-black::placeholder"
        onChange={handleChange}
        options={options}
        value={value}
        showSearch={showSearch}
        allowClear={clearable}
        disabled={isDisabled}
        onBlur={onBlur}
        bordered={bordered}
        mode={isMulti ? "multiple" : undefined}
      />
      {error && touched && (
        <div className={"flex items-center mt-1"}>
          <InfoCircleOutlined className="text-[10px] text-red-500" />
          <p className="text-left text-[10px] text-red-500 ml-1.5 mb-0">
            {error}
          </p>
        </div>
      )}
    </div>
  );
}

export default SelectField;
