import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  token: null,
  clientId: null,
  profile: {},
  isLoggedIn: false,
  showLoginModal: false,
  showSignupModal: false,
  showOTPModal: false,
  showForgotPasswordModal: false,
  showResetPasswordModal: false,
  location: "Lagos, Nigeria",
  signupInfo: {},
  newsletter: false,
  connect: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser: (state, { payload }) => {
      state.token = payload.token;
      state.clientId = payload.clientId;
      state.isLoggedIn = true;
      state.showLoginModal = false;
      state.showSignupModal = false;
      state.showOTPModal = false;
    },
    getUserSuccess: (state, { payload }) => {
      state.profile = payload;
    },
    logoutUser: () => initialState,
    setShowLoginModal: (state, { payload }) => {
      state.showLoginModal = payload;
    },
    setShowSignupModal: (state, { payload }) => {
      state.showSignupModal = payload;
    },
    setShowOTPModal: (state, { payload }) => {
      state.showOTPModal = payload;
    },
    setShowForgotPasswordModal: (state, { payload }) => {
      state.showForgotPasswordModal = payload;
    },
    setShowResetPasswordModal: (state, { payload }) => {
      state.showResetPasswordModal = payload;
    },
    setLocation: (state, { payload }) => {
      state.location = payload;
    },
    setSignupInfo: (state, { payload }) => {
      state.signupInfo = payload;
    },
    setNewsletter: (state, { payload }) => {
      state.newsletter = payload;
    },
    setConnect: (state, { payload }) => {
      state.connect = payload;
    },
  },
});

// export actions generated from the slice
export const {
  loginUser,
  getUserSuccess,
  logoutUser,
  setShowForgotPasswordModal,
  setShowLoginModal,
  setShowOTPModal,
  setShowSignupModal,
  setLocation,
  setSignupInfo,
  setNewsletter,
  setConnect,
  setShowResetPasswordModal,
} = userSlice.actions;

// export reducer
export default userSlice.reducer;
